.foui-list {
    background-color: var(--select-back);
    border: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    position: relative;
    width: 100%;
    /*overflow: hidden;*/
}
.foui-list.bordered {
    box-shadow: var(--select-border);
    border-radius: var(--select-radius);
}
/*.foui-list.bordered:hover {
    box-shadow: var(--hover-border);
}*/
.foui-design-mode .foui-list.bordered:hover {
    box-shadow: none;
}
.foui-list.bordered:focus {
    box-shadow: var(--focus-border);
}
.foui-list.bordered.inset-focus:focus {
    box-shadow: var(--focus-inset-border);
}
.foui-list.bordered.no-focus:focus {
    box-shadow: none;
}
.foui-design-mode .foui-list.bordered:focus {
    box-shadow: none;
}

/*.foui-list-filter-box {
    display: flex;
    width: 100%;
}
.foui-list-input {
    display: flex;
    flex-grow: 1;
}
.foui-list-filter-ico-box {
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 30px;
    inset-inline-end: 0;
    inset-block-start: 8px;
}
.foui-list-filter-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(0.75);
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 100%;
    !* margin-left: -4px; *!
    margin-top: -3px
}
.foui-list-filter-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    height: 8px;
    background: currentColor;
    transform: rotate(-45deg);
    top: 10px;
    left: 12px
}
.foui-list-filter-sep {
    background-color: var(--select-back-filter-sep);
    min-height: 1px;
    width: 100%;
}*/
.foui-list-list {
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
}
.foui-list-item {
    color: var(--select-color-item);
    cursor: default;
    min-height: 16px;
    padding: var(--select-pad-row) var(--select-pad-side);
    position: relative;
    width: 100%;
}
.foui-list .foui-list-item:hover {
    background-color: hsla(255, 0%, 80%, 53%);
    color: #000;
}
.foui-list-item-selected {
    background-color: var(--text-main);
    color: var(--back-main);
}
.foui-list:focus .foui-list-item:hover {
    background-color: hsla(var(--primary-h), 53%, 30%, 50%);
    color: var(--primary-text);
}
.foui-list:focus .foui-list-item-selected,
.foui-list:focus .foui-list-item-selected:hover {
    background-color: var(--primary);
    color: var(--primary-text);
}
.foui-list-item-action {
    /*display: none;*/
    padding: 4px;
    position: absolute;
    right: -50px;
    top: 0;
    bottom: 0;
    width: 50px;
}

/*
.load-more {
	position: absolute;
	display: block;
	cursor: pointer;
  width: 40px; height: 40px;
  top: 50%; left: 50%;
  margin: -20px 0 0 -20px;
}
	.load-more:before,
	.load-more:after {
		position: absolute;
		display: block;
		content: '';
		width: 30px; height: 30px;
		left: 50%; top: 50%;
		margin-top: -15px;
		border-width: 2px;
		border-style: solid;
		border-color: transparent;
		border-bottom-color: #fff;
		border-right-color: #fff;
		border-radius: 0 0 5px 0;

		transform: translate(-50%, -50%) rotate(45deg);

		transition: all .4s ease-in-out;
	}
	.load-more.active:before {
		border-radius: 50%;
		animation:
			.8s rotate .4s linear forwards infinite;
	}
	.load-more.active:after {
		width: 38px; height: 38px;
		border-radius: 50%;
		animation:
			.8s rotate2 .4s linear forwards infinite;
	}
		@keyframes rotate {
			to {
				transform: translate(-50%, -50%) rotate(405deg);
			}
		}
		@keyframes rotate2 {
			to {
				transform: translate(-50%, -50%) rotate(-315deg);
			}
		}
	.load-more.disabled:before,
	.load-more.disabled:after {
    margin-top: 0;
		transform: translate(-50%, -50%) rotate(135deg) rotateY(180deg);
	}
*/
