.foui-calendar {
    cursor: default;
    display: flex;
    flex-direction: column;
}
.foui-calendar-title-box {
    display: flex;
}
.foui-calendar-prev {
    display: flex;
    flex-basis: 20px;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
}
.foui-calendar-next {
    display: flex;
    flex-basis: 20px;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
}
.foui-calendar-value-box {
    font-size: larger;
    display: flex;
    flex-basis: 1fr;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}
.foui-calendar-value-weekday {
    padding: 5px;
}
.foui-calendar-value-day {
    padding: 5px;
}
.foui-calendar-value-month {
    padding: 5px;
}
.foui-calendar-value-year {
    padding: 5px;
}
.foui-calendar-weekday-box {
    font-weight: bold;
    display: flex;
    margin-top: 10px;
}
.foui-calendar-weekday {
    flex-grow: 1;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}
.foui-calendar-days-box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.foui-calendar-week-box {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
}
.foui-calendar-days-box.foui-bordered .foui-calendar-week-box {
    border-right: 1px solid #555;
}
.foui-calendar-days-box.foui-bordered .foui-calendar-week-box:last-child {
    border-bottom: 1px solid #555;
}
.foui-calendar-day {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 14.285714286%;
    display: flex;
}
.foui-calendar-days-box.foui-bordered .foui-calendar-day {
    border: 1px solid #555;
    border-right: 0;
    border-bottom: 0;
}
.foui-calendar-off {
    color: #555;
}
