@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.c85c477b.woff2") format("woff2"), url("Inter-Thin.467fb948.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.0e96e46b.woff2") format("woff2"), url("Inter-ThinItalic.b165d62d.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.4e39f470.woff2") format("woff2"), url("Inter-ExtraLight.e85f218f.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.0dc0845c.woff2") format("woff2"), url("Inter-ExtraLightItalic.bc88fafc.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.f5f0d76d.woff2") format("woff2"), url("Inter-Light.66dd9ea0.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.44962206.woff2") format("woff2"), url("Inter-LightItalic.a10547b1.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.9d2ba33a.woff2") format("woff2"), url("Inter-Regular.80c3f368.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.420060cb.woff2") format("woff2"), url("Inter-Italic.7093eca8.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.4ad0c0d5.woff2") format("woff2"), url("Inter-Medium.b0915175.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.7ceaa389.woff2") format("woff2"), url("Inter-MediumItalic.8acd5141.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.ff11aa87.woff2") format("woff2"), url("Inter-SemiBold.94b6a731.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.1cb5a0f2.woff2") format("woff2"), url("Inter-SemiBoldItalic.5d2fad75.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.bd1ff9c3.woff2") format("woff2"), url("Inter-Bold.a41c0cf5.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.b5cece50.woff2") format("woff2"), url("Inter-BoldItalic.0b2d650e.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.2289336f.woff2") format("woff2"), url("Inter-ExtraBold.53d0d214.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.c5a9bbad.woff2") format("woff2"), url("Inter-ExtraBoldItalic.332ba885.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.3c9759cf.woff2") format("woff2"), url("Inter-Black.a2b692a2.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.dc9ca3f0.woff2") format("woff2"), url("Inter-BlackItalic.e40a120e.woff") format("woff");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("Inter-roman.var.9e307660.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("Inter-italic.var.494397ed.woff2") format("woff2");
}

@font-face {
  font-family: Inter var experimental;
  font-weight: 100 900;
  font-display: swap;
  src: url("Inter.var.226e4c4d.woff2") format("woff2");
}

:root {
  --opa-full: 100%;
  --primary-h: 174;
  --primary-s: 64%;
  --primary-l: 40%;
  --primary: hsla(var(--primary-h), var(--primary-s), var(--primary-l), var(--opa-full) );
  --primary-light: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l)  + 10%), var(--opa-full) );
  --primary-heavy: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l)  - 10%), var(--opa-full) );
  --primary-nofocus-h: 0;
  --primary-nofocus: hsla(var(--primary-nofocus-h), 0%, calc(var(--primary-l)  + 15%), var(--opa-full) );
  --primary2-h: 165;
  --primary2-s: 91%;
  --primary2-l: 49%;
  --primary2: hsla(var(--primary2-h), var(--primary2-s), var(--primary2-l), var(--opa-full) );
  --primary2-light: hsla(var(--primary2-h), var(--primary2-s), calc(var(--primary2-l)  + 10%), var(--opa-full) );
  --primary2-heavy: hsla(var(--primary2-h), var(--primary2-s), calc(var(--primary2-l)  - 10%), var(--opa-full) );
  --secondary-h: 0;
  --secondary-s: 0%;
  --secondary-l: 13%;
  --secondary: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), var(--opa-full) );
  --secondary-light: hsla(var(--secondary-h), var(--secondary-s), 20%, var(--opa-full) );
  --secondary-heavy: hsla(var(--secondary-h), var(--secondary-s), 7%, var(--opa-full) );
  --accent-h: 212;
  --accent-s: 66%;
  --accent-l: 72%;
  --accent: hsla(var(--accent-h), var(--accent-s), var(--accent-l), var(--opa-full) );
  --accent-light: hsla(var(--accent-h), var(--accent-s), calc(var(--accent-l)  + 10%), var(--opa-full) );
  --accent-heavy: hsla(var(--accent-h), var(--accent-s), calc(var(--accent-l)  - 10%), var(--opa-full) );
  --accent2-h: 212;
  --accent2-s: 66%;
  --accent2-l: 72%;
  --accent2: hsla(var(--accent2-h), var(--accent2-s), var(--accent2-l), 15%);
  --accent2-light: hsla(var(--accent2-h), var(--accent2-s), calc(var(--accent2-l)  + 10%), 15%);
  --accent2-heavy: hsla(var(--accent2-h), var(--accent2-s), calc(var(--accent2-l)  - 10%), 15%);
  --dull-h: 0;
  --dull-s: 0%;
  --dull-l: 27%;
  --dull: hsla(var(--dull-h), var(--dull-s), var(--dull-l), var(--opa-full) );
  --dull-feather: hsla(var(--dull-h), var(--dull-s), 52%, var(--opa-full) );
  --dull-light: hsla(var(--dull-h), var(--dull-s), 32%, var(--opa-full) );
  --dull-heavy: hsla(var(--dull-h), var(--dull-s), 25%, var(--opa-full) );
  --success-h: 138;
  --success-s: 79%;
  --success-l: 30%;
  --success: hsla(var(--success-h), var(--success-s), var(--success-l), var(--opa-full) );
  --success-light: hsla(var(--success-h), var(--success-s), calc(var(--success-l)  + 10%), var(--opa-full) );
  --success-heavy: hsla(var(--success-h), var(--success-s), calc(var(--success-l)  - 10%), var(--opa-full) );
  --warning-h: 29;
  --warning-s: 100%;
  --warning-l: 45%;
  --warning: hsla(var(--warning-h), var(--warning-s), var(--warning-l), var(--opa-full) );
  --warning-light: hsla(var(--warning-h), var(--warning-s), calc(var(--warning-l)  + 10%), var(--opa-full) );
  --warning-heavy: hsla(var(--warning-h), var(--warning-s), 27%, var(--opa-full) );
  --danger-h: 0;
  --danger-s: 66%;
  --danger-l: 43%;
  --danger: hsla(var(--danger-h), var(--danger-s), var(--danger-l), var(--opa-full) );
  --danger-light: hsla(var(--danger-h), var(--danger-s), 55%, var(--opa-full) );
  --danger-heavy: hsla(var(--danger-h), var(--danger-s), 30%, var(--opa-full) );
  --info-h: 207;
  --info-s: 76%;
  --info-l: 38%;
  --info: hsla(var(--info-h), var(--info-s), var(--info-l), var(--opa-full) );
  --info-light: hsla(var(--info-h), var(--danger-s), 50%, var(--opa-full) );
  --info-heavy: hsla(var(--info-h), var(--info-s), calc(var(--info-l)  - 10%), var(--opa-full) );
  --control-h: 240;
  --control-s: 1%;
  --control-l: 30%;
  --control: hsla(var(--control-h), var(--control-s), var(--control-l), var(--opa-full) );
  --control-light: hsla(var(--control-h), var(--control-s), 32%, var(--opa-full) );
  --control-heavy: hsla(var(--control-h), var(--control-s), 20%, var(--opa-full) );
  --back-h: 0;
  --front-h: 0;
  --primary-text: #fff;
  --primary2-text: #ffffffe6;
  --secondary-text: #fffc;
  --accent-text: #fff;
  --accent2-text: #fff;
  --dull-text: #fff9;
  --success-text: #fffc;
  --warning-text: #fffc;
  --danger-text: #fffc;
  --info-text: #fffc;
  --primary-back: var(--primary);
  --secondary-back: var(--secondary);
  --accent-back: var(--accent);
  --accent2-back: var(--accent2);
  --dull-back: var(--dull);
  --success-back: var(--success);
  --warning-back: var(--warning);
  --danger-back: var(--danger);
  --info-back: var(--info);
  --text-main: hsl(var(--back-h), 0%, 77%);
  --text-opposite: #222;
  --text-disabled: #aaa;
  --text-dull: #aaa;
  --text-dull-opposite: #777;
  --text-input: #fff;
  --cico1: var(--dull);
  --cico2: var(--text-main);
  --cico3: var(--primary);
  --cico4: var(--secondary);
  --theme-input: #404040;
  --theme-window: #424242;
  --back-main: hsl(var(--back-h), 0%, 23%);
  --back-opposite: #222;
  --back-disabled: #555;
  --back-input: #3f3f3f;
  --back-actionbar: #353535;
  --back-titlebar: #303030;
  --back-hover-faint: hsla(var(--primary-col), 53%, 30%, 50%);
  --back-cover: #2323231a;
  --back-cover-text: #fff;
  --back-tinted: #3f3f3f;
  --back-gen: var(--back-main);
  --text-gen: var(--text-main);
  --border: #555;
  --border2: #4c4c4c;
  --border-hover: #666;
  --control-back: var(--control);
  --standard-bordered: 0 0 0 1px var(--border2), 0 0 0 2px var(--border);
  --primary-bordered: 0 0 0 1px var(--primary), 0 0 0 2px var(--primary-light);
  --secondary-bordered: 0 0 0 1px var(--secondary), 0 0 0 2px var(--secondary-light);
  --accent-bordered: 0 0 0 1px var(--accent), 0 0 0 2px var(--accent-light);
  --dull-bordered: 0 0 0 1px var(--dull), 0 0 0 2px var(--dull-light);
  --success-bordered: 0 0 0 1px var(--success), 0 0 0 2px var(--primary-light);
  --warning-bordered: 0 0 0 1px var(--warning), 0 0 0 2px var(--warning-light);
  --danger-bordered: 0 0 0 1px var(--danger), 0 0 0 2px var(--danger-light);
  --info-bordered: 0 0 0 1px var(--info), 0 0 0 2px var(--info-light);
  --font-stack: "Inter var", "Source Sans Pro", Roboto, sans-serif;
  --font-size: 9pt;
  --font-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-gen: var(--font-size) var(--font-stack);
  --box-shadow-duration: .1s;
  --global-tiny-box-shadow: 0 1px 3px #00000080;
  --global-small-box-shadow: 0 2px 8px #00000080;
  --global-medium-box-shadow: 0 5px 15px #00000080;
  --global-large-box-shadow: 0 14px 25px #00000080;
  --global-xlarge-box-shadow: 0 28px 50px #00000080;
  --shadow-0: 0 0 0 transparent;
  --shadow-1: 0 2px 3px #0003;
  --shadow-2: 0 3px 5px #0003;
  --shadow-3: 0 5px 8px #0003;
  --shadow-4: 0 8px 11px #0003;
  --shadow-5: 0 11px 16px #0003;
  --grid-box-padding: 0px;
  --focus-border: 0 0 0 1px #323232, 0 0 0 3px var(--primary);
  --focus-inset-border: 0 0 0 1px var(--primary) inset;
  --hover-border: 0 0 0 1px #323232, 0 0 0 3px var(--border);
  --alert-action-height: 44px;
  --alert-action-padding: 8px;
  --alert-back: var(--back-gen);
  --alert-back-actions: var(--back-actionbar);
  --alert-back-title: var(--back-titlebar);
  --alert-border: 1px solid var(--border2);
  --alert-color: var(--text-input);
  --alert-font-size-title: 120%;
  --alert-radius: 4px;
  --alert-title-height: 40px;
  --alert-title-padding: 6px 12px;
  --button-back: var(--control-back);
  --button-border: 0;
  --button-color: var(--text-main);
  --button-radius: 2px;
  --button-padding: 2px 6px;
  --button-focus-filter: brightness(130%);
  --check-box-size: 17px;
  --check-back: var(--back-input);
  --check-border: 0 0 0 1px var(--border);
  --check-color: var(--text-input);
  --check-color-check: var(--text-input);
  --check-radius: 0;
  --check-padding: 0 8px;
  --check-mark-left: 3px;
  --check-mark-top: -1px;
  --check-mark-width: 6px;
  --check-mark-height: 10px;
  --check-mark-thick: 2px;
  --dialog-font-size-title: 120%;
  --dialog-title-height: 40px;
  --dialog-title-start-padding: 14px;
  --dialog-title-padding: 8px;
  --grid-back: var(--dull);
  --header-line-title-bar-color: var(--dull-feather);
  --option-box-size: 16px;
  --option-back: var(--back-input);
  --option-border: 0 0 0 1px var(--border);
  --option-color: var(--text-input);
  --option-color-check: var(--text-input);
  --option-radius: 50%;
  --option-padding: 0 8px;
  --option-pos: 25%;
  --option-size: 50%;
  --progress-bar-radius: 2px;
  --progress-bar-track: var(--dull-light);
  --progress-bar-track-height: 8px;
  --panel-back: var(--back-tinted);
  --panel-radius: 2px;
  --popup-back: var(--back-input);
  --popup-back-filter: var(--back-input);
  --popup-back-filter-sep: var(--border2);
  --popup-back-header: var(--dull);
  --popup-border: 1px solid var(--border);
  --popup-radius: 2px;
  --popup-color: var(--text-input);
  --popup-color-header: var(--text-dull);
  --popup-color-item: var(--text-input);
  --popup-font-size-header: inherit;
  --popup-pad-header: 6px;
  --popup-pad-row: 4px;
  --popup-pad-side: 5px;
  --popup-border-shadow: var(--global-medium-box-shadow);
  --property-grid-header-padding: 3px 5px;
  --property-grid-prop-cell: #ffffff04;
  --property-grid-note-back: var(--border);
  --select-back: var(--back-input);
  --select-back-filter: var(--back-input);
  --select-back-filter-sep: var(--border);
  --select-border: 0 0 0 1px var(--border);
  --select-border-hover: 0 0 0 1px var(--border-hover);
  --select-button-color: transparent;
  --select-ico-color: var(--text-input);
  --select-color: var(--text-input);
  --select-color-item: var(--text-input);
  --select-radius: 1px;
  --select-pad-row: 4px;
  --select-pad-side: 5px;
  --select-list-border-shadow: var(--global-medium-box-shadow);
  --scroller-width: 12px;
  --scroller-tag: #777;
  --scroller-track: transparent;
  --slider-dot-size: 4px;
  --slider-radius: 10px;
  --slider-tag-size: 10px;
  --slider-tag-size-focus: 16px;
  --slider-tag-radius: 10px;
  --slider-track: var(--dull);
  --slider-track-height: 2px;
  --splitter-back: var(--dull-heavy);
  --splitter-border-size: 0;
  --splitter-padding: 3px;
  --splitter-thickness: 1px;
  --switch-tag-back: var(--text-disabled);
  --switch-tag-back-on: var(--back-input);
  --switch-tag-height: 18px;
  --switch-tag-offset: 1px;
  --switch-tag-radius: 10px;
  --switch-tag-transition: all cubic-bezier(.075, .82, .165, 1) .2s;
  --switch-tag-width: 18px;
  --switch-track-back: var(--back-input);
  --switch-track-back-on: var(--text-main);
  --switch-track-border: var(--text-border);
  --switch-track-height: 20px;
  --switch-track-radius: 10px;
  --switch-track-width: 36px;
  --text-back: var(--back-input);
  --text-border: 0 0 0 1px var(--border);
  --text-border-hover: 0 0 0 1px var(--border-hover);
  --text-color: var(--text-input);
  --text-radius: 1px;
  --text-pad-side: 5px;
  --table-ico-check-off: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20style%3D%22stroke-linejoin%3A%20miter%3B%20fill%3A%20none%3B%20stroke%3A%20%23ccc%22%20d%3D%22M%200.5%200.5%20L%2015.5%200.5%20L%2015.5%2015.5%20L%200.5%2015.5%20Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  --table-ico-check-on: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20style%3D%22stroke-linejoin%3A%20miter%3B%20fill%3A%20none%3B%20stroke%3A%20%23ccc%22%20d%3D%22M%200.5%200.5%20L%2015.5%200.5%20L%2015.5%2015.5%20L%200.5%2015.5%20Z%22%3E%3C%2Fpath%3E%3Cpath%20style%3D%22stroke-linejoin%3A%20miter%3B%20fill%3A%20none%3B%20stroke-width%3A%202px%3B%20stroke%3A%20%23fff%22%20d%3D%22M%203.5%207.5%20L%206.5%2010.5%20L%2012.5%204.5%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  --table-ico-edit: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2010%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20style%3D%22stroke-linejoin%3A%20round%3B%20fill%3A%20none%3B%20stroke%3A%20%23ccc%22%20d%3D%22M%201%209%20L%203%205%20L%207%201.005%20L%209%203%20L%205%207%20L%201%209%20Z%22%3E%3C%2Fpath%3E%3Cpath%20style%3D%22stroke%3A%20%23ccc%3B%20fill%3A%20none%22%20d%3D%22M%204%204%20C%204%204%206%204%206%206%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  --table-ico-filter: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20viewBox%3D%220%200%2010%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Abx%3D%22https%3A%2F%2Fboxy-svg.com%22%3E%0A%20%20%3Cg%20id%3D%22Air-Dark%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22matrix%281%2C%200%2C%200%2C%201%2C%202%2C%20-1%29%22%20bx%3Aorigin%3D%22-0.125%20-0.142857%22%3E%0A%20%20%20%20%3Cg%20id%3D%22Filter-Mini2%22%20transform%3D%22translate%281.000000%2C%202.000000%29%22%20fill%3D%22%23FFFFFF%22%3E%0A%20%20%20%20%20%20%3Crect%20id%3D%22rect-1%22%20width%3D%229%22%20height%3D%221%22%20style%3D%22fill%3A%20%23cbcbcb%22%20x%3D%22-3%22%3E%3C%2Frect%3E%0A%20%20%20%20%20%20%3Crect%20id%3D%22rect-1%22%20y%3D%222%22%20width%3D%227%22%20height%3D%221%22%20style%3D%22fill%3A%20%23cbcbcb%22%20x%3D%22-2%22%3E%3C%2Frect%3E%0A%20%20%20%20%20%20%3Crect%20id%3D%22rect-1%22%20y%3D%224%22%20width%3D%225%22%20height%3D%221%22%20style%3D%22fill%3A%20%23cbcbcb%22%20x%3D%22-1%22%3E%3C%2Frect%3E%0A%20%20%20%20%20%20%3Crect%20id%3D%22rect-1%22%20y%3D%226%22%20width%3D%223%22%20height%3D%221%22%20style%3D%22fill%3A%20%23cbcbcb%22%3E%3C%2Frect%3E%0A%20%20%20%20%3C%2Fg%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
  --table-ico-select: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2010%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20style%3D%22stroke-linejoin%3A%20miter%3B%20fill%3A%20none%3B%20stroke-width%3A%202px%3B%20stroke%3A%20%23ccc%22%20d%3D%22M%200.5%202.5%20L%205%206.5%20L%209.5%202.5%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  --table-zebra: #ffffff06;
  --toolbar-border: inherit;
  --toolbar-gap: 20px;
  --toolbar-separator: 2px dotted var(--border);
  --toolbar-button-hover: brightness(115%);
  --toolbar-button-active: brightness(110%);
  --toolbar-button-selected: var(--dull-heavy);
  --coder-lineno-font-sz: 9pt;
  --coder-lineno-col: var(--text-dull);
  --coder-arrow: polygon(100% 0, 0 100%, 100% 100%);
  --coder-arrow-small-w: 4px;
  --coder-arrow-small-h: 4px;
  --coder-arrow-col: var(--primary-heavy);
}

html * {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  background-color: var(--back-gen);
  color: var(--text-gen);
  font: var(--font-gen);
  font-variant-numeric: slashed-zero;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

.unselectable {
  -khtml-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.unclickable {
  pointer-events: none;
  touch-action: none;
}

input, button, textarea, select {
  font: inherit;
  font-variant-numeric: slashed-zero;
}

.foui-ctrl {
  display: flex;
  position: relative;
}

.foui-ctrl-design-cover {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
}

.foui-design-mode .foui-ctrl:hover {
  outline: 1px dashed var(--primary-nofocus);
  outline-offset: -1px;
}

.foui-design-mode .foui-ctrl.foui-design-select {
  outline: 1px solid var(--primary);
  outline-offset: -1px;
}

.foui-design-mode .foui-ctrl.foui-selected:hover {
  outline: 1px dashed var(--primary);
}

* {
  scrollbar-width: var(--scroller-width);
  scrollbar-color: var(--scroller-tag) var(--scroller-track);
}

::-webkit-scrollbar {
  width: var(--scroller-width);
  height: var(--scroller-width);
}

::-webkit-scrollbar-track {
  background: var(--scroller-track);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroller-tag);
  border-radius: var(--scroller-width);
}

.foui-app {
  position: fixed;
  inset: 0;
  overflow: auto;
}

.foui-alert {
  background: var(--alert-back);
  border: var(--alert-border);
  border-radius: var(--alert-radius);
  color: var(--alert-color);
  cursor: default;
  transform-origin: center;
  opacity: 0;
  z-index: 999;
  display: inline-block;
  position: fixed;
  overflow: hidden;
  transform: scale(.5);
}

.foui-alert-anim {
  transition: transform .2s, opacity .2s, top .2s;
}

.foui-alert-overlay {
  backdrop-filter: blur();
  opacity: 0;
  z-index: 950;
  transition: all .3s;
  position: fixed;
  inset: 0;
}

.foui-alert-title {
  background: var(--alert-back-title);
  font-size: var(--dialog-font-size-title);
  height: var(--alert-title-height);
  padding: var(--alert-title-padding);
  font-weight: normal;
  display: flex;
}

.foui-alert-title-text {
  flex-grow: 1;
  align-self: center;
}

.foui-alert-holder {
  display: flex;
}

.foui-alert-icon {
  width: 50px;
  padding: 10px;
}

.foui-alert-message {
  padding: 12px;
}

.foui-alert-actionbar {
  background: var(--alert-back-actions);
  min-height: var(--alert-action-height);
  padding: var(--alert-action-padding);
  justify-content: flex-end;
  display: flex;
}

.foui-alert-ico-back {
  fill: var(--text-back);
  fill-opacity: 1;
  stroke: none;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.foui-alert-ico-ico {
  fill: var(--text-input);
  fill-opacity: 1;
  stroke: none;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.foui-alert-icon.primary .foui-alert-ico-back {
  fill: var(--primary);
}

.foui-alert-icon.primary .foui-alert-ico-ico {
  fill: var(--primary-text);
}

.foui-alert-icon.secondary .foui-alert-ico-back {
  fill: var(--secondary);
}

.foui-alert-icon.secondary .foui-alert-ico-ico {
  fill: var(--secondary-text);
}

.foui-alert-icon.accent .foui-alert-ico-back {
  fill: var(--accent);
}

.foui-alert-icon.accent .foui-alert-ico-ico {
  fill: var(--accent-text);
}

.foui-alert-icon.dull .foui-alert-ico-back {
  fill: var(--dull);
}

.foui-alert-icon.dull .foui-alert-ico-ico {
  fill: var(--dull-text);
}

.foui-alert-icon.success .foui-alert-ico-back {
  fill: var(--success);
}

.foui-alert-icon.success .foui-alert-ico-ico {
  fill: var(--success-text);
}

.foui-alert-icon.warning .foui-alert-ico-back {
  fill: var(--warning);
}

.foui-alert-icon.warning .foui-alert-ico-ico {
  fill: var(--warning-text);
}

.foui-alert-icon.error .foui-alert-ico-back {
  fill: var(--danger);
}

.foui-alert-icon.error .foui-alert-ico-ico {
  fill: var(--danger-text);
}

.foui-alert-icon.info .foui-alert-ico-back {
  fill: var(--info);
}

.foui-alert-icon.info .foui-alert-ico-ico {
  fill: var(--info-text);
}

.foui-alert-icon.help .foui-alert-ico-back {
  fill: var(--info);
}

.foui-alert-icon.help .foui-alert-ico-ico {
  fill: var(--info-text);
}

.foui-button {
  background: var(--button-back);
  box-shadow: var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  cursor: default;
  padding: var(--button-padding);
  text-align: center;
  border: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.foui-button:hover:enabled {
  box-shadow: var(--hover-border);
  filter: brightness(105%);
}

.foui-design-mode .foui-button:hover:enabled {
  box-shadow: none;
  filter: none;
}

.foui-button:focus:enabled {
  box-shadow: var(--focus-border);
  filter: brightness(105%);
}

.foui-button.inset-focus:focus:enabled {
  box-shadow: var(--focus-inset-border);
}

.foui-button.no-focus:focus:enabled {
  box-shadow: none;
}

.foui-design-mode .foui-button:focus:enabled {
  box-shadow: none;
  filter: none;
}

.foui-button.bordered {
  background: none;
}

.foui-button:disabled {
  opacity: .6;
}

.foui-button.primary {
  color: var(--primary-text);
  background: var(--primary-back);
}

.foui-button.secondary {
  color: var(--secondary-text);
  background: var(--secondary-back);
}

.foui-button.accent {
  color: var(--accent-text);
  background: var(--accent-back);
}

.foui-button.dull {
  color: var(--dull-text);
  background: var(--dull-back);
}

.foui-button.success {
  color: var(--success-text);
  background: var(--success-back);
}

.foui-button.warning {
  color: var(--warning-text);
  background: var(--warning-back);
}

.foui-button.danger {
  color: var(--danger-text);
  background: var(--danger-back);
}

.foui-button.info {
  color: var(--info-text);
  background: var(--info-back);
}

.foui-button.transparent {
  color: var(--info-text);
  background: none;
}

.foui-button.primary.bordered {
  color: var(--primary-light);
  box-shadow: var(--primary-bordered);
  background: none;
}

.foui-button.secondary.bordered {
  color: var(--secondary-light);
  box-shadow: var(--secondary-bordered);
  background: none;
}

.foui-button.accent.bordered {
  color: var(--accent-light);
  box-shadow: var(--accent-bordered);
  background: none;
}

.foui-button.dull.bordered {
  color: var(--dull-light);
  box-shadow: var(--dull-bordered);
  background: none;
}

.foui-button.success.bordered {
  color: var(--success-light);
  box-shadow: var(--success-bordered);
  background: none;
}

.foui-button.warning.bordered {
  color: var(--warning-light);
  box-shadow: var(--warning-bordered);
  background: none;
}

.foui-button.danger.bordered {
  color: var(--danger-light);
  box-shadow: var(--danger-bordered);
  background: none;
}

.foui-button.info.bordered {
  color: var(--info-light);
  box-shadow: var(--info-bordered);
  background: none;
}

.foui-button.primary:focus:enabled, .foui-button.secondary:focus:enabled, .foui-button.accent:focus:enabled, .foui-button.dull:focus:enabled, .foui-button.success:focus:enabled, .foui-button.warning:focus:enabled, .foui-button.danger:focus:enabled, .foui-button.info:focus:enabled {
  filter: var(--button-focus-filter);
}

.foui-button:active:enabled, .foui-button.primary:active:enabled, .foui-button.secondary:active:enabled, .foui-button.accent:active:enabled, .foui-button.dull:active:enabled, .foui-button.success:active:enabled, .foui-button.warning:active:enabled, .foui-button.danger:active:enabled, .foui-button.info:active:enabled {
  filter: contrast(115%) brightness(103%);
}

.foui-ctrl.foui-selected .foui-button, .foui-ctrl.selected .foui-button {
  filter: contrast(115%) brightness(96%);
}

.foui-calendar {
  cursor: default;
  flex-direction: column;
  display: flex;
}

.foui-calendar-title-box {
  display: flex;
}

.foui-calendar-prev, .foui-calendar-next {
  flex-shrink: 1;
  flex-basis: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.foui-calendar-value-box {
  flex-basis: 1fr;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: larger;
  display: flex;
}

.foui-calendar-value-weekday, .foui-calendar-value-day, .foui-calendar-value-month, .foui-calendar-value-year {
  padding: 5px;
}

.foui-calendar-weekday-box {
  margin-top: 10px;
  font-weight: bold;
  display: flex;
}

.foui-calendar-weekday {
  text-align: center;
  text-transform: uppercase;
  flex-grow: 1;
  padding: 5px;
}

.foui-calendar-days-box {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.foui-calendar-week-box {
  flex-grow: 1;
  align-items: stretch;
  display: flex;
}

.foui-calendar-days-box.foui-bordered .foui-calendar-week-box {
  border-right: 1px solid #555;
}

.foui-calendar-days-box.foui-bordered .foui-calendar-week-box:last-child {
  border-bottom: 1px solid #555;
}

.foui-calendar-day {
  width: 14.2857%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.foui-calendar-days-box.foui-bordered .foui-calendar-day {
  border: 0;
  border-top: 1px solid #555;
  border-left: 1px solid #555;
}

.foui-calendar-off {
  color: #555;
}

.foui-check {
  cursor: default;
  align-items: center;
  display: flex;
}

.foui-check-noview {
  opacity: .001;
  display: inline-block;
  position: absolute;
}

.foui-check:hover .foui-check-label-ico {
  box-shadow: var(--hover-border);
}

.foui-check-noview:focus ~ .foui-check-label-ico {
  box-shadow: var(--focus-border);
}

.foui-check-label {
  color: var(--check-color);
  cursor: default;
  padding: var(--check-padding);
  flex-direction: row;
  align-self: center;
  display: flex;
}

.foui-check-label-ico {
  background-color: var(--text-back);
  box-shadow: var(--check-border);
  border-radius: var(--check-radius);
  height: var(--check-box-size);
  width: var(--check-box-size);
  flex-direction: row;
  align-self: center;
  display: flex;
}

.foui-check-ico {
  fill: none;
  stroke-width: 2px;
  stroke: var(--text-input);
  transform-origin: center;
  opacity: 0;
  transition: all .2s cubic-bezier(.075, .82, .165, 1);
}

.foui-check-noview:checked ~ label svg g .foui-check-ico {
  opacity: 1;
}

.foui-dialog {
  background: var(--alert-back);
  border: var(--alert-border);
  border-radius: var(--alert-radius);
  color: var(--alert-color);
  cursor: default;
  transform-origin: center;
  opacity: 0;
  z-index: 999;
  display: inline-block;
  position: fixed;
  overflow: hidden;
  transform: scale(.5);
}

.foui-dialog-anim {
  transition: transform .2s, opacity .2s, top .2s;
}

.foui-dialog-overlay {
  background-color: var(--back-cover);
  backdrop-filter: blur();
  color: var(--back-cover-text);
  opacity: 0;
  z-index: 950;
  transition: all .3s;
  position: fixed;
  inset: 0;
}

.foui-dialog-title {
  background: var(--alert-back-title);
  font-size: var(--dialog-font-size-title);
  height: var(--dialog-title-height);
  padding-inline-start: var(--dialog-title-start-padding);
  font-weight: normal;
  display: flex;
}

.foui-dialog-title-icon {
  background-color: #0000;
  justify-content: center;
  align-items: center;
  margin-inline-end: var(--dialog-title-padding);
  display: flex;
}

.foui-dialog-title-text {
  flex-grow: 1;
  align-self: center;
}

.foui-dialog-title-close {
  flex-basis: var(--dialog-title-height);
  background-color: #0000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.foui-dialog-title-close .foui-cico-normal {
  fill: var(--text-main);
}

.foui-dialog-title-close:hover {
  background-color: #dc143c;
}

.foui-dialog-title-close:hover .foui-cico-normal {
  fill: #fff !important;
}

.foui-dialog-holder {
  display: flex;
}

.foui-dialog-step {
  flex-grow: 1;
  display: flex;
}

.foui-dialog-spacer {
  flex-grow: 1;
}

.foui-dialog-actionbar {
  background: var(--alert-back-actions);
  min-height: var(--alert-action-height);
  padding: var(--alert-action-padding);
  display: flex;
}

.foui-dialog-actionbar button {
  margin-inline-end: 5px;
}

.foui-dialog-actionbar button:last-child {
  margin-inline-end: 0;
}

.foui-dialog-busy {
  background: var(--alert-back-actions);
  height: var(--alert-action-height);
  left: var(--alert-action-padding);
  right: var(--alert-action-padding);
  z-index: 1;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

.foui-file-drop {
  border: 3px dashed var(--dull);
  cursor: default;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .2s linear;
  display: flex;
}

.foui-file-drop-over {
  border: 3px dashed var(--primary);
}

.foui-file-drop-noview {
  display: none;
}

.foui-file-drop-ico {
  margin-bottom: 10px;
}

.foui-file-drop-ico > svg {
  width: 40px;
  height: 40px;
}

.foui-file-drop-or {
  opacity: .6;
  margin: 10px 0;
}

.foui-file-drop-browse {
  padding: 8px 20px;
}

.foui-file-drop-accept, .foui-file-drop-limit {
  opacity: .6;
  margin-top: 5px;
}

.foui-file-drop-name {
  color: var(--primary);
  margin-bottom: 10px;
  font-size: 14pt;
}

.foui-file-drop-error {
  background-color: var(--danger-light);
  color: var(--warning-text);
  border-radius: 2px;
  margin-top: 10px;
  padding: 2px 6px;
}

.foui-file-drop-progress {
  background-color: var(--dull-light);
  max-width: 200px;
  height: 5px;
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  position: relative;
}

.foui-file-drop-progress div {
  height: 100%;
}

.foui-header-line-title {
  background-color: var(--back-main);
  text-align: start;
  flex-direction: row;
  align-self: center;
}

.foui-header-line-title-label {
  color: var(--text-dull);
  flex-shrink: 1;
  align-self: center;
  margin-inline: 5px;
  display: flex;
}

.foui-header-line-title-pattern {
  height: 8px;
  flex-grow: 1;
  align-self: center;
}

.foui-header-line-title-actions {
  height: 100%;
  flex-shrink: 1;
  align-self: center;
  display: flex;
}

.foui-header-line-title-pattern {
  background: radial-gradient(var(--header-line-title-bar-color) 15%, transparent 16%), radial-gradient(var(--header-line-title-bar-color) 15%, transparent 16%), radial-gradient(var(--header-line-title-bar-color) 15%, transparent 16%), transparent;
  background-position: 0 0, 2px 2px, 0 4px;
  background-repeat: repeat-x;
  background-size: 4px 4px;
}

.foui-info-block {
  cursor: default;
  display: flex;
}

.foui-info-block-hover:hover {
  background-color: var(--dull-back);
  filter: brightness(1.1);
}

.foui-info-block-side {
  align-items: flex-start;
  display: flex;
}

.foui-info-block-ico {
  width: 100%;
}

.foui-info-block-ico svg {
  width: 100%;
  height: 100%;
}

.foui-info-block-main {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.foui-info-block-title {
  margin: 2px 0;
  font-weight: 500;
}

.foui-info-block-text {
  margin: 2px 0;
}

.foui-info-block-tags {
  display: flex;
}

.foui-info-block-tag-item {
  background-color: var(--dull-back);
  color: var(--dull-text);
  border-radius: 2px;
  margin: 6px 6px 0 0;
  padding: 3px 8px;
  display: inline-flex;
}

.foui-info-block.foui-primary-text .foui-cico-normal {
  fill: var(--primary-text) !important;
}

.foui-info-block.foui-secondary-text .foui-cico-normal {
  fill: var(--secondary-text) !important;
}

.foui-info-block.foui-accent-text .foui-cico-normal {
  fill: var(--accent-text) !important;
}

.foui-info-block.foui-accent2-text .foui-cico-normal {
  fill: var(--accent2-text) !important;
}

.foui-info-block.foui-dull-text .foui-cico-normal {
  fill: var(--dull-text) !important;
}

.foui-info-block.foui-success-text .foui-cico-normal {
  fill: var(--success-text) !important;
}

.foui-info-block.foui-warning-text .foui-cico-normal {
  fill: var(--warning-text) !important;
}

.foui-info-block.foui-danger-text .foui-cico-normal {
  fill: var(--danger-text) !important;
}

.foui-info-block.foui-info-text .foui-cico-normal {
  fill: var(--info-text) !important;
}

.foui-info-block.foui-primary-text .foui-cico-dull {
  opacity: .6;
  fill: var(--primary-text) !important;
}

.foui-info-block.foui-secondary-text .foui-cico-dull {
  opacity: .6;
  fill: var(--secondary-text) !important;
}

.foui-info-block.foui-accent-text .foui-cico-dull {
  opacity: .6;
  fill: var(--accent-text) !important;
}

.foui-info-block.foui-accent2-text .foui-cico-dull {
  opacity: .6;
  fill: var(--accent2-text) !important;
}

.foui-info-block.foui-dull-text .foui-cico-dull {
  opacity: .6;
  fill: var(--dull-text) !important;
}

.foui-info-block.foui-success-text .foui-cico-dull {
  opacity: .6;
  fill: var(--success-text) !important;
}

.foui-info-block.foui-warning-text .foui-cico-dull {
  opacity: .6;
  fill: var(--warning-text) !important;
}

.foui-info-block.foui-danger-text .foui-cico-dull {
  opacity: .6;
  fill: var(--danger-text) !important;
}

.foui-info-block.foui-info-text .foui-cico-dull {
  opacity: .6;
  fill: var(--info-text) !important;
}

.foui-label {
  cursor: default;
  text-align: start;
  flex-direction: row;
  align-self: center;
  display: flex;
}

.foui-label.vertical {
  flex-direction: column;
}

.foui-label-ico, .foui-label-text {
  align-self: center;
}

.foui-layout-grid {
  display: grid;
  position: relative;
}

.foui-layout-grid-cell {
  min-width: 100%;
  min-height: 100%;
  padding: var(--grid-box-padding);
  display: flex;
  position: relative;
}

.show-cells .foui-layout-grid-cell {
  background-color: #ffffff06;
}

.foui-designer .foui-layout-grid-cell.foui-empty-cell {
  min-height: 20px;
}

.foui-dragover {
  outline: 1px dashed var(--primary);
}

.foui-grid-sz {
  z-index: 10;
  position: absolute;
}

.foui-grid-sz-col-line {
  background-color: var(--primary);
  background-image: linear-gradient(var(--primary) 100%, #fff0 0%);
  background-position: center;
  background-repeat: repeat-y;
  background-size: 2px 100%;
  background-color: hsla(var(--primary-h), 53%, 40%, 30%);
  width: 100%;
  height: 100%;
}

.foui-grid-sz-col-tag {
  background-color: var(--primary);
  color: var(--primary-text);
  cursor: ew-resize;
  white-space: nowrap;
  border-radius: 2px;
  padding: 2px 4px 1px;
  font-size: 8pt;
  position: absolute;
  top: 100%;
  transform: rotate(90deg);
  text-align: center !important;
}

.foui-grid-sz-col-tag:after {
  border-right: 8px solid var(--primary);
  content: " ";
  height: 0;
  width: 0;
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  position: absolute;
  top: 49%;
  left: -7px;
  transform: translateY(-50%);
}

.foui-grid-sz-row-line {
  background-image: linear-gradient(to right, var(--primary) 100%, #fff0 0%);
  background-position: center;
  background-repeat: repeat-x;
  background-size: 100% 2px;
  background-color: hsla(var(--primary-h), 53%, 40%, 30%);
  width: 100%;
  height: 100%;
}

.foui-grid-sz-row-tag {
  background-color: var(--primary);
  color: var(--primary-text);
  cursor: ns-resize;
  white-space: nowrap;
  border-radius: 2px;
  margin-left: 0;
  padding: 2px 4px 1px;
  font-size: 8pt;
  position: absolute;
  left: 100%;
  text-align: center !important;
}

.foui-grid-sz-row-tag:after {
  border-right: 8px solid var(--primary);
  content: " ";
  height: 0;
  width: 0;
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  position: absolute;
  top: 49%;
  left: -7px;
  transform: translateY(-50%);
}

.foui-line-horz {
  display: flex;
}

.foui-line-horz-bar {
  background-color: var(--border);
  width: 100%;
  align-self: center;
  display: flex;
  position: relative;
}

.foui-list {
  background-color: var(--select-back);
  width: 100%;
  border: 0;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  display: flex;
  position: relative;
}

.foui-list.bordered {
  box-shadow: var(--select-border);
  border-radius: var(--select-radius);
}

.foui-design-mode .foui-list.bordered:hover {
  box-shadow: none;
}

.foui-list.bordered:focus {
  box-shadow: var(--focus-border);
}

.foui-list.bordered.inset-focus:focus {
  box-shadow: var(--focus-inset-border);
}

.foui-list.bordered.no-focus:focus, .foui-design-mode .foui-list.bordered:focus {
  box-shadow: none;
}

.foui-list-list {
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
}

.foui-list-item {
  color: var(--select-color-item);
  cursor: default;
  min-height: 16px;
  padding: var(--select-pad-row) var(--select-pad-side);
  width: 100%;
  position: relative;
}

.foui-list .foui-list-item:hover {
  color: #000;
  background-color: #cccccc87;
}

.foui-list-item-selected {
  background-color: var(--text-main);
  color: var(--back-main);
}

.foui-list:focus .foui-list-item:hover {
  background-color: hsla(var(--primary-h), 53%, 30%, 50%);
  color: var(--primary-text);
}

.foui-list:focus .foui-list-item-selected, .foui-list:focus .foui-list-item-selected:hover {
  background-color: var(--primary);
  color: var(--primary-text);
}

.foui-list-item-action {
  width: 50px;
  padding: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -50px;
}

.foui-option {
  cursor: default;
  align-items: center;
  display: flex;
}

.foui-option-noview {
  opacity: .001;
  display: inline-block;
  position: absolute;
}

.foui-option:hover .foui-option-label-ico {
  box-shadow: var(--hover-border);
}

.foui-option-noview:focus ~ .foui-option-label-ico {
  box-shadow: var(--focus-border);
}

.foui-option-label {
  color: var(--option-color);
  cursor: default;
  padding: var(--check-padding);
  flex-direction: row;
  align-self: center;
  display: flex;
}

.foui-option-label-ico {
  background-color: var(--text-back);
  box-shadow: var(--option-border);
  border-radius: var(--option-radius);
  height: var(--option-box-size);
  width: var(--option-box-size);
  flex-direction: row;
  align-self: center;
  display: flex;
}

.foui-option-ico {
  fill: var(--text-input);
  stroke: none;
  transform-origin: center;
  opacity: 0;
  transition: all .2s;
}

.foui-option-noview:checked ~ label svg g .foui-option-ico {
  opacity: 1;
}

.foui-panel {
  border: 0;
  overflow: auto;
}

.foui-panel.bordered {
  box-shadow: var(--text-border);
  border-radius: var(--text-radius);
}

.foui-panel.tinted-background {
  background-color: var(--back-tinted);
}

.foui-progress-bar {
  cursor: default;
  align-items: center;
  display: flex;
  position: relative;
}

.foui-progress-bar-track {
  background: var(--progress-bar-track);
  border-radius: var(--progress-bar-radius);
  height: var(--progress-bar-track-height);
  align-items: center;
  display: flex;
}

.foui-progress-bar-value {
  background: var(--primary);
  border-radius: var(--progress-bar-radius);
  height: var(--progress-bar-track-height);
  z-index: 2;
  align-items: center;
  display: flex;
  position: absolute;
}

.foui-indeterminate {
  animation: loadbar 2s linear infinite;
}

@keyframes loadbar {
  0%, to {
    left: 0;
    right: 100%;
  }

  25% {
    left: 0;
    right: 50%;
  }

  50% {
    left: 50%;
    right: 0;
  }

  75% {
    left: 100%;
    right: 0;
  }

  100% {
    left: 100%;
    right: 0;
  }
}

.foui-popup {
  background-color: var(--popup-back);
  border-radius: var(--popup-radius);
  box-shadow: var(--popup-border-shadow);
  border: var(--popup-border);
  opacity: 0;
  max-height: 600px;
  min-width: 30px;
  width: auto;
  z-index: 10;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  transition: opacity .1s linear;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.foui-popup-menu {
  background-color: var(--popup-back);
  border-radius: var(--popup-radius);
  box-shadow: var(--popup-border-shadow);
  border: var(--popup-border);
  opacity: 0;
  max-height: 600px;
  min-width: 30px;
  width: auto;
  z-index: 10;
  flex-flow: column;
  justify-content: start;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.foui-popup-menu-filter-box {
  width: 100%;
  display: flex;
}

.foui-popup-menu-input {
  flex-grow: 1;
  display: flex;
}

.foui-popup-menu-filter-ico-box {
  min-height: 100%;
  width: 30px;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  inset-block-start: 8px;
  inset-inline-end: 0;
}

.foui-popup-header {
  background: var(--popup-back-header);
  border-top: 1px solid var(--popup-back-filter-sep);
  color: var(--popup-color-header);
  cursor: default;
  font-size: var(--popup-font-size-header);
  width: 100%;
  padding: var(--popup-pad-header);
}

.foui-popup-header:first-child {
  border-top: 0;
}

.foui-popup-separator, .foui-popup-menu-filter-sep {
  background-color: var(--popup-back-filter-sep);
  min-height: 1px;
  width: 100%;
}

.foui-popup-menu-list {
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
}

.foui-popup-item {
  color: var(--popup-color-item);
  cursor: default;
  min-height: 16px;
  padding: var(--popup-pad-row) var(--popup-pad-side);
  width: 100%;
  display: flex;
}

.foui-popup-item:hover {
  background-color: var(--back-hover-faint);
}

.foui-popup-item-selected {
  background-color: var(--primary);
  color: var(--primary-text);
}

.foui-popup-item-label {
  width: 100%;
  align-self: center;
}

.foui-popup-item-icon {
  height: 18px;
  width: 36px;
  justify-content: center;
  padding-right: 6px;
  display: flex;
}

.foui-progress {
  width: 100%;
  z-index: 10;
  overflow-x: hidden;
}

.foui-progress-line {
  opacity: .4;
  background: var(--primary);
  width: 150%;
  height: 4px;
  position: absolute;
}

.foui-progress-solidline {
  background: var(--primary);
  width: 100%;
  height: 4px;
  transition: width .25s;
  position: absolute;
}

.foui-progress-subline {
  background: var(--primary);
  height: 4px;
  position: absolute;
}

.foui-progress-inc {
  animation: anim-increase 2s infinite;
}

.foui-progress-dec {
  animation: anim-decrease 2s .5s infinite;
}

@keyframes anim-increase {
  from {
    width: 5%;
    left: -5%;
  }

  to {
    width: 100%;
    left: 130%;
  }
}

@keyframes anim-decrease {
  from {
    width: 80%;
    left: -80%;
  }

  to {
    width: 10%;
    left: 110%;
  }
}

.foui-progress-over-top, .foui-progress-over-bottom {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.foui-progress-over-bottom {
  bottom: 0;
}

.foui-spinner, .foui-spinner:after, .foui-spinner:before {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
}

.foui-spinner:after, .foui-spinner:before {
  content: "";
  border-radius: 100px;
  position: absolute;
}

.foui-spinner:before {
  border: 3px solid #0000;
  border-top-color: hsl(var(--primary-h), 100%, 85%);
  z-index: 1;
  animation: spinner 1s cubic-bezier(.6, 0, .4, 1) infinite;
}

.foui-spinner:after {
  border: 3px solid;
  border-color: var(--primary);
}

.foui-spinner.blender {
  filter: drop-shadow(0px 0px 1px var(--primary) );
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.foui-spinner-back {
  background: repeating-linear-gradient( -45deg, hsla(var(--primary-h), 91%, 49%, .8), hsla(var(--primary-h), 91%, 49%, .8) 10px, hsla(var(--primary-h), 91%, 49%, .5) 10px, hsla(var(--primary-h), 91%, 49%, .5) 20px );
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.foui-property-form {
  background-color: var(--back-tinted);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
}

.foui-property-form.bordered {
  box-shadow: var(--text-border);
  border-radius: var(--text-radius);
}

.foui-property-value-form {
  width: 100%;
  flex-direction: column;
  padding: 16px;
  display: flex;
}

.foui-property-form-row {
  flex-direction: row;
  display: flex;
}

.foui-property-form-row.pf-label {
  height: 20px;
  align-items: center;
}

.foui-property-form-row.pf-input {
  height: 28px;
  align-items: center;
  margin-bottom: 10px;
}

.foui-property-grid {
  background-color: var(--back-tinted);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
}

.foui-property-grid.bordered {
  box-shadow: var(--text-border);
  border-radius: var(--text-radius);
}

.foui-property-holder {
  flex-grow: 1;
  position: relative;
  overflow: auto;
}

.foui-property-table {
  border-collapse: collapse;
  width: 100%;
}

.foui-property-grid tr {
  border-bottom: 1px solid var(--border2);
}

.foui-property-grid tr:hover {
  background-color: var(--back-hover-faint);
}

.foui-property-grid th {
  background: var(--button-back);
  border-left: 1px solid var(--border2);
  color: var(--button-color);
  cursor: default;
  padding: var(--property-grid-header-padding);
  height: 22px;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 1;
  font-weight: bold;
  position: sticky;
  top: 0;
}

.foui-property-grid.auto-height th {
  height: auto;
  vertical-align: top;
  white-space: inherit;
}

.foui-property-table td {
  background-color: var(--property-grid-prop-cell);
  border-left: 1px solid var(--border2);
  font: var(--font-gen);
  max-width: 0;
  height: 25px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.foui-property-table.auto-height td {
  height: auto;
  text-overflow: inherit;
  vertical-align: top;
  white-space: inherit;
  position: relative;
}

.foui-property-grid th:nth-child(1) {
  border-left: 0;
}

.foui-property-grid td:nth-child(1) {
  cursor: default;
  border-left: 0;
  padding-inline-start: 5px;
}

.foui-property-grid tr.foui-selected td:nth-child(1) {
  background-color: var(--primary);
  color: var(--primary-text) !important;
}

.foui-property-grid .foui-changed {
  font-style: italic;
  font-weight: bold;
}

.foui-property-note {
  background-color: var(--property-grid-note-back);
  max-height: 70px;
  flex-shrink: 1;
  flex-basis: 70px;
  padding: 4px 5px;
  overflow: hidden;
}

.foui-property-note-title {
  font-weight: bold;
}

.foui-text-property {
  background-color: #0000;
  border: 0;
  padding: 0 3px;
}

.foui-select-property {
  width: 100%;
  padding: 0 3px;
  background-color: #0000 !important;
  border: 0 !important;
  outline: none !important;
}

.foui-repeater {
  display: flex;
  position: relative;
}

.foui-scrollbar {
  background: var(--scroller-track);
  cursor: default;
  display: flex;
}

.foui-scrollbar-tag {
  background-color: var(--scroller-tag);
  border-radius: var(--scroller-width);
  display: inline-block;
  position: relative;
}

.foui-scrollbar-anim {
  transition: top .2s, left .2s;
}

.foui-slider {
  cursor: default;
  align-items: center;
  display: flex;
  position: relative;
}

.foui-slider-track {
  background: var(--slider-track);
  border-radius: var(--slider-radius);
  height: var(--slider-track-height);
  align-items: center;
  display: flex;
}

.foui-slider-value {
  background: var(--primary);
  border-radius: var(--slider-radius);
  height: var(--slider-track-height);
  z-index: 2;
  align-items: center;
  display: flex;
  position: absolute;
}

.foui-slider-dot {
  background-color: var(--dull-dark);
  border-radius: var(--slider-tag-radius);
  width: var(--slider-dot-size);
  height: var(--slider-dot-size);
  margin-left: calc((var(--slider-dot-size) / 2) * -1);
  z-index: 1;
  display: inline-block;
  position: absolute;
}

.foui-slider-dot.foui-slider-dot-label:before {
  content: attr(data-tip);
  z-index: 1;
  position: absolute;
  top: 9px;
  transform: translateX(-50%);
}

.foui-slider:hover .foui-slider-dot {
  background-color: var(--primary);
}

.foui-slider-tag, .foui-slider-tag-start {
  background-color: var(--dull-dark);
  border: 2px solid var(--primary-back);
  border-radius: var(--slider-tag-radius);
  width: var(--slider-tag-size);
  height: var(--slider-tag-size);
  margin-left: calc((var(--slider-tag-size) / 2) * -1);
  z-index: 2;
  transition: all .2s;
  display: inline-block;
  position: absolute;
}

.foui-slider-tag:hover, .foui-slider-tag-start:hover {
  width: var(--slider-tag-size-focus);
  height: var(--slider-tag-size-focus);
  margin-left: calc((var(--slider-tag-size-focus) / 2) * -1);
}

.foui-select {
  background-color: var(--select-back);
  min-height: 10px;
  width: 100%;
  border: 0;
  flex-flow: row;
  justify-content: start;
  align-items: center;
  display: flex;
  position: relative;
}

.foui-select.bordered {
  box-shadow: var(--select-border);
  border-radius: var(--select-radius);
}

.foui-select.bordered:hover {
  box-shadow: var(--hover-border);
}

.foui-design-mode .foui-select.bordered:hover {
  box-shadow: none;
}

.foui-select.bordered:focus {
  box-shadow: var(--focus-border);
}

.foui-select.bordered.inset-focus:focus {
  box-shadow: var(--focus-inset-border);
}

.foui-select.bordered.no-focus:focus, .foui-design-mode .foui-select.bordered:focus {
  box-shadow: none;
}

.foui-select-label {
  border-radius: var(--select-radius);
  font: var(--font-gen);
  padding-left: var(--select-pad-side);
  padding-right: var(--select-pad-side);
  border: 0;
  color: var(--select-color) !important;
}

.foui-select-filter-box {
  width: 100%;
  margin: 4px 0;
  display: flex;
}

.foui-select-input {
  flex-grow: 1;
  display: flex;
}

.foui-select-filter-ico-box {
  min-height: 100%;
  width: 30px;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  inset-block-start: 8px;
  inset-inline-end: 0;
}

.foui-select-filter-ico {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  margin-top: -3px;
  display: block;
  position: relative;
  transform: scale(.75);
}

.foui-select-filter-ico:after {
  content: "";
  box-sizing: border-box;
  width: 2px;
  height: 8px;
  background: currentColor;
  border-radius: 3px;
  display: block;
  position: absolute;
  top: 10px;
  left: 12px;
  transform: rotate(-45deg);
}

.foui-select-filter-sep {
  background-color: var(--select-back-filter-sep);
  min-height: 1px;
  width: 100%;
}

.foui-select-button {
  background: var(--select-button-color);
  color: var(--button-color);
  cursor: default;
  height: 100%;
  width: 30px;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset-inline-end: 0;
}

.foui-select-button-ico {
  width: 8px;
  height: 8px;
  border-top: 2px solid var(--select-ico-color);
  border-right: 2px solid var(--select-ico-color);
  margin-top: -4px;
  display: inline-block;
  transform: rotate(135deg);
}

.foui-spacer {
  display: inline-block;
  position: relative;
}

.foui-vertical {
  width: 1px;
  height: 100%;
}

.foui-horizontal {
  width: 100%;
  height: 1px;
}

.foui-splitter {
  background: var(--splitter-back);
  display: inline-block;
  position: relative;
}

.foui-vertical {
  width: var(--splitter-thickness);
  height: 100%;
}

.foui-horizontal {
  width: 100%;
  height: var(--splitter-thickness);
}

.foui-splitter:hover:enabled {
  box-shadow: var(--hover-border);
  filter: brightness(105%);
}

.foui-splitter:focus:enabled {
  box-shadow: var(--focus-border);
  filter: brightness(105%);
}

.foui-splitter:disabled {
  opacity: .6;
}

.foui-splitter:active:enabled {
  filter: contrast(115%) brightness(103%);
}

.foui-switch {
  cursor: default;
  align-items: center;
  display: flex;
}

.foui-switch-noview {
  opacity: .001;
  display: inline-block;
  position: absolute;
}

.foui-switch:hover .foui-switch-label-ico {
  box-shadow: var(--hover-border);
}

.foui-switch-track {
  background-color: var(--switch-track-back);
  border-radius: var(--switch-track-radius);
  width: var(--switch-track-width);
  height: var(--switch-track-height);
  box-shadow: var(--switch-track-border);
  border: 0;
  flex-direction: row;
  align-self: center;
  display: flex;
  position: relative;
}

.foui-switch-noview:focus ~ .foui-switch-track {
  box-shadow: var(--focus-border);
}

.foui-switch-tag {
  background-color: var(--switch-tag-back);
  border-radius: var(--switch-tag-radius);
  width: var(--switch-tag-width);
  height: var(--switch-tag-height);
  transition: var(--switch-tag-transition);
  border: 0;
  flex-direction: row;
  align-self: center;
  margin: 0 1px;
  display: flex;
  position: absolute;
  left: 0;
}

.foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--switch-track-back-on);
}

.foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--switch-tag-back-on);
  left: calc(100% - var(--switch-tag-width)  - (2 * var(--switch-tag-offset) ) );
}

.foui-switch.primary .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--primary-back);
}

.foui-switch.secondary .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--secondary-back);
}

.foui-switch.accent .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--accent-back);
}

.foui-switch.dull .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--dull-back);
}

.foui-switch.success .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--success-back);
}

.foui-switch.warning .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--warning-back);
}

.foui-switch.danger .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--danger-back);
}

.foui-switch.info .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: var(--info-back);
}

.foui-switch.transparent .foui-switch-noview:checked ~ .foui-switch-track {
  background-color: #0000;
}

.foui-switch.primary .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--primary-text);
}

.foui-switch.secondary .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--secondary-text);
}

.foui-switch.accent .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--accent-text);
}

.foui-switch.dull .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--dull-text);
}

.foui-switch.success .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--success-text);
}

.foui-switch.warning .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--warning-text);
}

.foui-switch.danger .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--danger-text);
}

.foui-switch.info .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag, .foui-switch.transparent .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
  background-color: var(--info-text);
}

.foui-table {
  font: var(--font-gen);
  font-variant-numeric: slashed-zero;
  border-collapse: collapse;
  background-color: #0000;
  border: 0;
  position: relative;
  overflow: auto;
  color: var(--text-color) !important;
}

.foui-table-table {
  border-spacing: 0;
  width: 100%;
}

.foui-table.foui-table-borders-all {
  border: 1px solid var(--border);
}

.foui-table.foui-table-borders-vert {
  border-left: 1px solid var(--border);
}

.foui-table-zebra tr:nth-child(2n+1) {
  background-color: var(--table-zebra);
}

.foui-table tr:hover {
  background-color: var(--back-hover-faint);
}

.foui-table.foui-table-sticky-asis thead {
  z-index: 2;
  position: sticky;
  top: 0;
}

.foui-table.foui-table-sticky-asis thead tr, .foui-table.foui-table-sticky-top thead tr {
  position: sticky;
}

.foui-table.foui-table-sticky-top thead tr {
  top: 0;
}

.foui-table-header, .foui-table th {
  background-color: var(--control-light);
  text-align: left;
  padding: 6px 5px;
  font-weight: normal;
}

.foui-table.foui-table-head-borders .foui-table-header, .foui-table.foui-table-head-borders th {
  border-right: 1px solid var(--dull);
  border-top: 1px solid var(--dull);
}

.foui-table.foui-table-head-borders tr:last-child th {
  border-bottom: 1px solid var(--dull);
}

.foui-table td {
  max-width: 0;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  padding: 6px 5px;
  overflow: hidden;
}

.foui-table th[contenteditable="true"] {
  content: "Edit Me!";
  white-space: nowrap;
}

.foui-table-counter {
  min-width: 32px;
  width: 1%;
  z-index: 1;
  position: sticky;
  left: 0;
  max-width: fit-content !important;
}

.foui-table.foui-table-borders-all td {
  border-right: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}

.foui-table.foui-table-borders-horz td {
  border-bottom: 1px solid var(--border);
}

.foui-table.foui-table-borders-vert td {
  border-right: 1px solid var(--border);
}

.foui-table-progress {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.foui-table-filter {
  background: var(--table-ico-filter);
  background-position: right 4px center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: var(--back-input) !important;
  padding-inline-end: 16px !important;
}

.foui-table-filter:empty:before {
  content: "Filter";
  color: var(--text-dull);
}

.foui-table-filter-cell.nofilter {
  background-color: var(--back-disabled) !important;
}

.foui-table-sort-ico {
  width: 12px;
  height: 12px;
  display: inline-table;
}

.foui-table-accent-edit {
  flex-grow: 1;
  padding: 6px 5px;
}

.foui-table-edit-pencil {
  background: var(--table-ico-edit);
  white-space: nowrap;
  background-position: right 4px center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: var(--back-input) !important;
  padding-inline-end: 16px !important;
}

.foui-table-edit-select {
  background: var(--table-ico-select);
  white-space: nowrap;
  background-position: right 4px center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: var(--back-input) !important;
  padding-inline-end: 16px !important;
}

.foui-table-check-on, .foui-table-check-off {
  padding-inline-start: 25px !important;
}

.foui-table-check-on:before, .foui-table-check-off:before {
  content: "";
  background: var(--table-ico-check-off);
  cursor: pointer;
  width: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-inline-start: -25px !important;
}

.foui-table-check-on:before {
  background: var(--table-ico-check-on);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}

.foui-tabs {
  font: var(--font-gen);
  padding-left: var(--text-pad-side);
  padding-right: var(--text-pad-side);
  width: 100%;
  background-color: #0000;
  border: 0;
  flex-direction: column;
  display: flex;
  color: var(--text-color) !important;
}

.foui-tabs.bordered {
  box-shadow: var(--text-border);
  border-radius: var(--text-radius);
}

.foui-tabs.bordered:enabled:hover {
  box-shadow: var(--hover-border);
}

.foui-tabs.bordered:enabled:focus {
  box-shadow: var(--focus-border);
}

.foui-tabs-tab-box {
  flex-direction: row;
  display: flex;
  position: relative;
}

.foui-tabs-tab-box:before {
  content: "";
  background-color: var(--border);
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.foui-tabs-tab {
  height: 28px;
  z-index: 1;
  background-color: #0000;
  border-bottom: 2px solid #0000;
  align-items: center;
  padding: 0 10px;
  transition: all 1s cubic-bezier(.075, .82, .165, 1);
  display: flex;
}

.foui-tabs-tab-selected {
  border-bottom: 2px solid var(--primary);
  font-weight: bold;
}

.foui-tabs-tab-text {
  text-align: center;
  flex-grow: 1;
}

.foui-tabs-tab-text:before {
  content: attr(title);
  height: 0;
  visibility: hidden;
  font-weight: bold;
  display: block;
  overflow: hidden;
}

.foui-tabs-content-box {
  height: 100%;
  display: flex;
}

.foui-table-sort-ico {
  padding-inline-start: 3px;
}

.foui-text {
  background-color: var(--text-back);
  font: var(--font-gen);
  min-height: 10px;
  padding-left: var(--text-pad-side);
  padding-right: var(--text-pad-side);
  width: 100%;
  border: 0;
  display: inline-block;
  color: var(--text-color) !important;
}

.foui-text.bordered {
  box-shadow: var(--text-border);
  border-radius: var(--text-radius);
}

.foui-text.bordered:enabled:hover {
  box-shadow: var(--hover-border);
}

.foui-design-mode .foui-text.bordered:hover {
  box-shadow: none;
}

.foui-text.bordered:enabled:focus {
  box-shadow: var(--focus-border);
}

.foui-text.bordered.inset-focus:focus:enabled {
  box-shadow: var(--focus-inset-border);
}

.foui-text.bordered.no-focus:enabled:focus, .foui-design-mode .foui-text.bordered:focus {
  box-shadow: none;
}

.foui-text-nosize {
  resize: none;
}

.foui-toolbar {
  background: var(--button-back);
  box-shadow: var(--toolbar-border);
  cursor: default;
  border: 0;
  flex-flow: row;
  justify-content: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.foui-toolbar-progress {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.foui-toolbar-button {
  color: var(--text-main);
  cursor: default;
  background-color: #0000;
  border: 0;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  display: flex;
}

.foui-toolbar-button.foui-selected {
  background-color: var(--toolbar-button-selected);
}

.foui-toolbar-button:hover:enabled, .foui-toolbar-button:focus:enabled {
  background: var(--button-back);
  filter: var(--toolbar-button-hover);
}

.foui-toolbar-button:disabled {
  opacity: .6;
}

.foui-toolbar-button:active:enabled {
  filter: var(--toolbar-button-active);
}

.dark .foui-toolbar-button:not([disabled]):hover {
  background-color: var(--toolbar-button-hover);
}

.dark .foui-toolbar-button:not([disabled]):active {
  background-color: var(--toolbar-button-active);
}

.foui-toolbar-separator {
  border-left: var(--toolbar-separator);
  height: 60%;
  margin: auto 3px;
  position: relative;
}

.dark .foui-toolbar-separator {
  border-color: #fff;
}

.foui-toolbar-spacer {
  flex-grow: 1;
}

.foui-toolbar-gap {
  flex-basis: var(--toolbar-gap);
}

.foui-toolbar-label {
  color: var(--text-main);
  align-self: center;
}

.foui-toolbar-text {
  width: auto;
  margin: 3px 0;
}

.foui-toolbar-select {
  width: auto;
  height: auto;
  margin: 3px 0;
}

.foui-touch-coder {
  width: 100%;
  height: 100%;
  border: 0;
  flex-direction: row;
  display: flex;
}

.foui-touch-comment {
  color: #008200;
  font-size: small;
}

.foui-touch-coder-calls {
  height: 100%;
  background-color: #0000;
  flex-basis: 300px;
  overflow-y: auto;
}

.foui-touch-coder-call-item {
  padding: 5px 10px;
}

.foui-touch-coder-call-item:hover {
  background-color: var(--back-hover-faint);
}

.foui-touch-coder-call-item:active {
  background-color: var(--primary);
  color: var(--primary-text);
}

.foui-touch-coder-call-name {
  font-weight: bold;
}

.foui-touch-coder-call-desc {
  font-size: smaller;
}

.foui-touch-coder-code {
  border-left: 1px solid var(--border);
  height: 100%;
  background-color: #0000;
  flex-grow: 1;
  position: relative;
  overflow: auto;
}

.foui-touch-coder-code-linebox {
  border-top: 1px solid var(--border);
  display: flex;
}

.foui-touch-coder-code-wrap {
  flex-grow: 1;
}

.foui-touch-coder-code-line {
  padding: 6px;
  display: flex;
}

.foui-touch-coder-code-tab {
  width: 30px;
}

.foui-touch-coder-code-o {
  border: 1px solid #0000;
  border-radius: 2px;
  margin-inline-end: 5px;
  padding: 2px 5px;
  position: relative;
}

.foui-touch-coder-code-o.lineno {
  background-color: var(--dull);
  border: 0;
  border-right: 1px solid var(--border);
  color: var(--coder-lineno-col);
  font-size: var(--coder-lineno-font-sz);
  font-variant-numeric: slashed-zero tabular-nums;
  text-align: end;
  width: 40px;
  min-width: 40px;
  padding: 9px 4px 0;
}

.foui-touch-coder-code-o.call {
  cursor: pointer;
  min-width: 50px;
  background-color: #565656;
  border-color: #565656;
}

.foui-touch-coder-code-o.call:after {
  content: "";
  background-color: var(--coder-arrow-col);
  width: var(--coder-arrow-small-w);
  height: var(--coder-arrow-small-h);
  clip-path: var(--coder-arrow);
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.foui-touch-coder-code-o.call.SET {
  background-color: #005779;
  border-color: #0084b8;
}

.foui-touch-coder-code-o.call.IF, .foui-touch-coder-code-o.call.ELSE {
  background-color: #680082;
  border-color: #80a;
}

.foui-touch-coder-code-o.call.ALERT {
  background-color: #5a5a00;
  border-color: olive;
}

.foui-touch-coder-code-o.call.LOG {
  background-color: #7d3715;
  border-color: sienna;
}

.foui-touch-coder-code-o.variable {
  background-color: var(--text-back);
  border-color: var(--border);
  color: var(--text-input);
}

.foui-touch-coder-code-o.variable:focus {
  border-color: var(--primary);
}

.foui-touch-coder-code-o.operand {
  cursor: pointer;
  background-color: #565656;
  border-color: #565656;
  font-weight: bold;
}

.foui-touch-coder-code-o.operand:after {
  content: "";
  background-color: var(--coder-arrow-col);
  width: var(--coder-arrow-small-w);
  height: var(--coder-arrow-small-h);
  clip-path: var(--coder-arrow);
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.foui-touch-coder-code-o.text {
  border-color: var(--dull);
  opacity: .7;
  background-color: #0000;
  padding: 2px 3px;
}

.foui-touch-coder-code-o.value-text {
  border-color: var(--primary);
  background-color: #0000;
}

.foui-touch-coder-code-input {
  color: var(--text-input);
  width: 100%;
  background-color: #0000;
  border: 0;
}

.foui-touch-coder-code-input::placeholder {
  font-style: italic;
}

.foui-touch-coder-splitter, .foui-touch-coder-popup {
  position: absolute;
}

.foui-touch-coder-popup-list {
  background-color: var(--popup-back);
  box-shadow: var(--popup-border-shadow);
  border: var(--popup-border);
  border-radius: var(--select-radius);
  max-height: 400px;
  position: relative;
  overflow-y: auto;
}

.foui-touch-coder-popup-arrow {
  background-color: var(--border);
  box-shadow: var(--select-list-border-shadow);
  width: 10px;
  height: 6px;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  margin-left: 5px;
  position: relative;
}

.foui-touch-coder-popup-item {
  color: var(--popup-color-item);
  cursor: default;
  min-height: 16px;
  padding: var(--select-pad-row) var(--select-pad-side);
  width: 100%;
}

.foui-touch-coder-popup-item:hover {
  background-color: hsla(var(--primary-h), 53%, 30%, 50%);
}

.foui-touch-coder-popup-item:active {
  background-color: var(--primary);
  color: var(--primary-text);
}

.foui-tree {
  background-color: var(--text-back);
  font: var(--font-gen);
  width: 100%;
  border: 0;
  display: inline-block;
  overflow: auto;
  color: var(--text-color) !important;
}

.foui-tree.bordered {
  box-shadow: var(--text-border);
  border-radius: var(--text-radius);
}

.foui-design-mode .foui-tree.bordered:hover {
  box-shadow: none;
}

.foui-tree.bordered:focus {
  box-shadow: var(--focus-border);
}

.foui-tree.bordered.inset-focus:focus {
  box-shadow: var(--focus-inset-border);
}

.foui-tree.bordered.no-focus:focus, .foui-design-mode .foui-tree.bordered:focus {
  box-shadow: none;
}

.foui-tree-child-box {
  background: radial-gradient(var(--dull-text) 33%, transparent 33%), transparent;
  background-repeat: repeat-y;
  background-size: 4px 4px;
  overflow: hidden;
}

.foui-tree-child-box-nolines {
  background: none;
  overflow: hidden;
}

.foui-tree.animate .foui-tree-child-box {
  transition: all .2s ease-in-out;
}

.foui-tree-child-box .foui-hidden {
  max-height: 0;
}

.foui-tree-icon {
  text-align: center;
  min-width: 20px;
  height: 20px;
  flex-direction: row;
  flex-basis: 20px;
  justify-content: center;
  align-self: center;
  display: flex;
  position: relative;
}

.foui-tree-icon > svg {
  align-self: center;
}

.foui-tree-item {
  width: 100%;
  height: 22px;
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 0 5px;
  display: flex;
}

.foui-tree .foui-tree-item:hover {
  color: #000;
  background-color: #cccccc87;
}

.foui-tree-item.foui-selected {
  background-color: var(--dull-back);
  color: var(--back-main);
}

.foui-tree:focus .foui-tree-item:hover {
  background-color: hsla(var(--primary-h), 53%, 30%, 50%);
  color: var(--primary-text);
}

.foui-tree:focus .foui-tree-item.foui-selected, .foui-tree:focus .foui-tree-item.foui-selected:hover {
  background-color: var(--primary);
  color: var(--primary-text);
}

.foui-tree-item.foui-selected .foui-tree-icon {
  filter: invert();
}

.foui-tree:focus .foui-tree-item.foui-selected .foui-tree-icon {
  filter: brightness(0) invert();
}

.foui-tree-item.foui-selected .foui-tree-icon > span {
  color: var(--primary-text) !important;
}

.foui-tree-item label {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  align-self: center;
  margin: 0 0 0 5px;
  line-height: 20px;
  overflow: hidden;
}

.foui-tree-item-tag {
  cursor: default;
  white-space: nowrap;
  border-radius: 2px;
  flex-shrink: 1;
  align-self: center;
  margin: 2px;
  padding: 0 4px;
  font-size: 70%;
  font-weight: bold;
  line-height: 17px;
}

.foui-tree-plusmin {
  min-width: 20px;
  height: 20px;
  flex-basis: 20px;
  align-self: center;
  overflow: hidden;
}

.foui-tree-closed {
  --ggs: .5;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  transform: scale(var(--ggs, 1) );
  border: 2px solid;
  border-radius: 4px;
  display: block;
  position: relative;
  top: -2px;
  left: -2px;
}

.foui-tree-closed:after, .foui-tree-closed:before {
  content: "";
  box-sizing: border-box;
  width: 14px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 9px;
  left: 3px;
}

.foui-tree-closed:after {
  width: 2px;
  height: 14px;
  top: 3px;
  left: 9px;
}

.foui-tree-open {
  --ggs: .5;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  transform: scale(var(--ggs, 1) );
  border: 2px solid;
  border-radius: 4px;
  display: block;
  position: relative;
  top: -2px;
  left: -2px;
}

.foui-tree-open:after, .foui-tree-open:before {
  content: "";
  box-sizing: border-box;
  width: 14px;
  height: 2px;
  background: currentColor;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 9px;
  left: 3px;
}

.foui-tree-folder, .foui-tree-file, .foui-tree-server, .foui-tree-database {
  fill: var(--text-color);
  fill-opacity: 1;
  stroke: none;
  stroke-width: 0;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1;
}

.foui-tree-danger {
  fill: var(--danger);
}

.foui-view-designer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.foui-view-designer-header {
  display: grid;
}

.foui-view-designer-body {
  height: 100%;
  padding: 5px 0 0;
}

.foui-view-designer-view {
  background-color: var(--back-main);
  height: 100%;
  padding: 20px;
  overflow: auto;
}

.foui-preview-box {
  background-color: var(--back-gen);
  border: 2px solid var(--border2);
  display: inline-block;
  box-shadow: 3px 3px 12px #333, -3px -3px 12px #333;
}

.foui-view-designer-selected {
  outline: 1px dashed var(--primary);
}

.foui-designer .foui-ctrl > * {
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.foui-view-designer-sizer {
  border: 1px dashed var(--primary);
  display: inline-block;
  position: absolute;
}

.foui-view-designer-sizer.for-row {
  cursor: ns-resize;
}

.foui-view-designer-sizer.for-col {
  cursor: ew-resize;
}

.foui-view-designer-tag.for-row {
  background-color: var(--primary);
  width: 20px;
  height: 15px;
  text-align: center;
  border-radius: 0 3px 3px 0;
  position: absolute;
  right: -29px;
}

.foui-view-designer-tag.for-row:before {
  content: "";
  width: 0;
  height: 0;
  border-right: 5px solid var(--primary);
  filter: brightness(80%);
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  margin-top: 2px;
  position: absolute;
  left: -6px;
  display: inline-block !important;
}

.foui-view-designer-tag.for-col {
  background-color: var(--primary);
  width: 15px;
  height: 20px;
  text-align: center;
  border-radius: 0 0 3px 3px;
  padding-top: 2px;
  position: absolute;
  bottom: -29px;
}

.foui-view-designer-tag.for-col:before {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 5px solid var(--primary);
  filter: brightness(80%);
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  margin-left: -5px;
  position: absolute;
  top: -6px;
  left: 50%;
  display: inline-block !important;
}

.sz-info-val {
  font-size: smaller;
  line-height: 15px;
  position: absolute;
}

.foui-view-designer-tag.for-col .sz-info-val {
  top: 26px;
  left: -50%;
}

.foui-view-designer-tag.for-row .sz-info-val {
  top: 0;
  left: 26px;
}

.tartan {
  background-color: gray;
  background-image: repeating-linear-gradient(#0000, #0000 30px, #0006 30px 32px, #0000 32px 37px, #0006 37px 39px, #0000 39px 69px, #0006 69px 99px, #fff3 99px 101px, #0006 101px 106px, #fff3 106px 108px, #0006 108px 138px, #0000 138px), repeating-linear-gradient(270deg, #0000, #0000 30px, #0006 30px 32px, #0000 32px 37px, #0006 37px 39px, #0000 39px 69px, #0006 69px 99px, #fff3 99px 101px, #0006 101px 106px, #fff3 106px 108px, #0006 108px 138px, #0000 138px), repeating-linear-gradient(125deg, #0000, #0000 2px, #0003 2px 3px, #0000 3px 5px, #0003 5px);
}

.carbon {
  background-color: #131313;
  background-image: linear-gradient(27deg, #151515 5px, #0000 5px), linear-gradient(207deg, #151515 5px, #0000 5px), linear-gradient(27deg, #222 5px, #0000 5px), linear-gradient(207deg, #222 5px, #0000 5px), linear-gradient(90deg, #1b1b1b 10px, #0000 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25% 50%, #0000 50% 75%, #242424 75%, #242424);
  background-position: 0 5px, 10px 0, 0 10px, 10px 5px, 0 0, 0 0;
  background-repeat: repeat, repeat, repeat, repeat, repeat, repeat;
  background-size: 20px 20px;
  background-attachment: scroll, scroll, scroll, scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box, padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box, border-box, border-box, border-box;
}

.net {
  background-color: #282828;
  background-image: radial-gradient(#000 40%, #0000 0), radial-gradient(#000 40%, #0000 0), radial-gradient(#ffffff1a 40%, #0000 20%), radial-gradient(#ffffff1a 40%, #0000 20%);
  background-position: 0 0, 20px 20px, 0 1px, 20px 21px;
  background-repeat: repeat, repeat, repeat, repeat;
  background-size: 40px 40px;
  background-attachment: scroll, scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box, border-box;
}

.diagonal-lines-light {
  background-image: repeating-linear-gradient(-45deg, #535353, #535353 .25px, #0000 0 50%);
  background-size: 12px 12px;
}

.diagonal-lines-regular {
  background-image: repeating-linear-gradient(-45deg, #535353 0 1px, #0000 0 50%);
  background-size: 12px 12px;
}

.diagonal-lines-bold {
  background-image: repeating-linear-gradient(-45deg, #535353 0 2px, #0000 0 50%);
  background-size: 12px 12px;
}

.diagonal-lines-display {
  background-image: repeating-linear-gradient(-45deg, #535353 0 8px, #0000 0 50%);
  background-size: 24px 24px;
}

.bgpattern-tartan {
  background-color: #a02f2b;
  background-image: repeating-linear-gradient(#0000, #0000 50px, #0006 50px 53px, #0000 53px 63px, #0006 63px 66px, #0000 66px 116px, #00000080 116px 166px, #fff3 166px 169px, #00000080 169px 179px, #fff3 179px 182px, #00000080 182px 232px, #0000 232px), repeating-linear-gradient(270deg, #0000, #0000 50px, #0006 50px 53px, #0000 53px 63px, #0006 63px 66px, #0000 66px 116px, #00000080 116px 166px, #fff3 166px 169px, #00000080 169px 179px, #fff3 179px 182px, #00000080 182px 232px, #0000 232px), repeating-linear-gradient(125deg, #0000, #0000 2px, #0003 2px 3px, #0000 3px 5px, #0003 5px);
}

.bgpattern-carbon-fibre {
  background-color: #282828;
  background-image: radial-gradient(#000 15%, #0000 16%), radial-gradient(#000 15%, #0000 16%), radial-gradient(#ffffff1a 15%, #0000 20%), radial-gradient(#ffffff1a 15%, #0000 20%);
  background-position: 0 0, 8px 8px, 0 1px, 8px 9px;
  background-size: 16px 16px;
}

.bgpattern-argyle {
  background-color: #6d695c;
  background-image: repeating-linear-gradient(120deg, #ffffff1a, #ffffff1a 1px, #0000 1px 60px), repeating-linear-gradient(60deg, #ffffff1a, #ffffff1a 1px, #0000 1px 60px), linear-gradient(60deg, #0000001a 25%, #0000 25% 75%, #0000001a 75%, #0000001a), linear-gradient(120deg, #0000001a 25%, #0000 25% 75%, #0000001a 75%, #0000001a);
  background-size: 70px 120px;
}

.bgpattern-japanese-cube {
  background-color: #556;
  background-image: linear-gradient(30deg, #445 12%, #0000 12.5% 87%, #445 87.5%, #445), linear-gradient(150deg, #445 12%, #0000 12.5% 87%, #445 87.5%, #445), linear-gradient(30deg, #445 12%, #0000 12.5% 87%, #445 87.5%, #445), linear-gradient(150deg, #445 12%, #0000 12.5% 87%, #445 87.5%, #445), linear-gradient(60deg, #99a 25%, #0000 25.5% 75%, #99a 75%, #99a), linear-gradient(60deg, #99a 25%, #0000 25.5% 75%, #99a 75%, #99a);
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
  background-size: 80px 140px;
}

.bgpattern-lined-paper {
  background-color: #fff;
  background-image: linear-gradient(90deg, #0000 79px, #abced4 79px 81px, #0000 81px), linear-gradient(#eee .1em, #0000 .1em);
  background-size: 100% 1.2em;
}

.bgpattern-blueprint-grid {
  background-color: #269;
  background-image: linear-gradient(#fff 2px, #0000 2px), linear-gradient(90deg, #fff 2px, #0000 2px), linear-gradient(#ffffff4d 1px, #0000 1px), linear-gradient(90deg, #ffffff4d 1px, #0000 1px);
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
}

.bgpattern-diagonal-stripes {
  background-color: gray;
  background-image: repeating-linear-gradient(45deg, #0000, #0000 35px, #ffffff80 35px 70px);
}

.bgpattern-brady-bunch {
  background-color: #c8d3a7;
  background-image: radial-gradient(closest-side, #0000 0 75%, #b6cc66 76% 85%, #edffdb 86% 94%, #fff 95% 103%, #d9e6a7 104% 112%, #798b3c 113% 121%, #fff 122% 130%, #e0ead7 131% 140%), radial-gradient(closest-side, #0000 0 75%, #b6cc66 76% 85%, #edffdb 86% 94%, #fff 95% 103%, #d9e6a7 104% 112%, #798b3c 113% 121%, #fff 122% 130%, #e0ead7 131% 140%);
  background-position: 0 0, 55px 55px;
  background-size: 110px 110px;
}

.bgpattern-seigaiha {
  background-color: silver;
  background-image: radial-gradient(circle at 100% 150%, #333 24%, #393939 25% 28%, #333 29% 36%, #393939 36% 40%, #0000 40%, #0000), radial-gradient(circle at 0 150%, #333 24%, #393939 25% 28%, #333 29% 36%, #393939 36% 40%, #0000 40%, #0000), radial-gradient(circle at 50% 100%, #393939 10%, #333 11% 23%, #393939 24% 30%, #333 31% 43%, #393939 44% 50%, #333 51% 63%, #393939 64% 71%, #0000 71%, #0000), radial-gradient(circle at 100%, #393939 5%, #333 6% 15%, #393939 16% 20%, #333 21% 30%, #393939 31% 35%, #333 36% 45%, #393939 46% 49%, #0000 50%, #0000), radial-gradient(circle at 0, #393939 5%, #333 6% 15%, #393939 16% 20%, #333 21% 30%, #393939 31% 35%, #333 36% 45%, #393939 46% 49%, #0000 50%, #0000);
  background-size: 100px 50px;
}

.foui-view {
  height: 100%;
  transition: opacity .2s linear;
  display: grid;
  position: relative;
}

.foui-focus {
  box-shadow: var(--focus-border) !important;
}

.foui-on-top {
  z-index: 50;
}

.foui-stretch {
  width: 100%;
  height: 100%;
}

.foui-stretch-x {
  width: 100%;
}

.foui-stretch-y {
  height: 100%;
}

.foui-flex {
  display: flex !important;
}

.foui-flex-grow {
  flex-grow: 1 !important;
}

.foui-flex-shrink {
  flex-shrink: 1 !important;
}

.foui-flex-wrap {
  flex-wrap: wrap !important;
}

.foui-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.foui-flex-wrap-none {
  flex-wrap: nowrap !important;
}

.foui-flex-row {
  flex-direction: row !important;
}

.foui-flex-column {
  flex-direction: column !important;
}

.foui-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.foui-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.foui-flex-align-self-auto {
  align-self: auto !important;
}

.foui-flex-align-self-start {
  align-self: start !important;
}

.foui-flex-align-self-center {
  align-self: center !important;
}

.foui-flex-align-self-end {
  align-self: end !important;
}

.foui-flex-align-items-start {
  align-items: start !important;
}

.foui-flex-align-items-center {
  align-items: center !important;
}

.foui-flex-align-items-end {
  align-items: end !important;
}

.foui-flex-justify-content-start {
  justify-content: start !important;
}

.foui-flex-justify-content-center {
  justify-content: center !important;
}

.foui-flex-justify-content-end {
  justify-content: end !important;
}

.foui-block {
  display: block !important;
}

.foui-inline-block {
  display: inline-block !important;
}

.foui-float-left {
  float: left !important;
}

.foui-float-right {
  float: right !important;
}

[class*="foui-float-"] {
  max-width: 100%;
}

.foui-overflow-hidden {
  overflow: hidden !important;
}

.foui-overflow-visible {
  overflow: visible !important;
}

.foui-overflow-auto {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.foui-overflow-auto > :last-child {
  margin-bottom: 0;
}

.foui-absolute {
  position: absolute;
}

.foui-hidden {
  display: none !important;
}

.foui-no-vis {
  visibility: hidden !important;
}

.foui-control-panel {
  display: flex;
}

.foui-control-ico, .foui-control-text {
  display: flex;
  vertical-align: middle !important;
  align-self: center !important;
}

.foui-text-bold {
  font-weight: bold !important;
}

.foui-text-medium {
  font-weight: 500 !important;
}

.foui-text-normal {
  font-weight: 300 !important;
}

.foui-text-thin {
  font-weight: 100 !important;
}

.foui-text-italic {
  font-style: italic !important;
}

.foui-text-normal {
  font-style: normal !important;
}

.foui-text-underline {
  text-decoration: underline !important;
}

.foui-text-nowrap {
  white-space: nowrap;
}

.foui-text-start {
  text-align: start !important;
}

.foui-text-end {
  text-align: end !important;
}

.foui-text-center {
  text-align: center !important;
}

.foui-text-justify {
  text-align: justify !important;
}

.foui-text-start input {
  text-align: start !important;
}

.foui-text-end input {
  text-align: end !important;
}

.foui-text-center input {
  text-align: center !important;
}

.foui-text-justify input {
  text-align: justify !important;
}

.foui-text-top {
  vertical-align: top !important;
  align-self: start !important;
}

.foui-text-middle {
  vertical-align: middle !important;
  align-self: center !important;
}

.foui-text-bottom {
  vertical-align: bottom !important;
  align-self: end !important;
}

.foui-text-baseline {
  vertical-align: baseline !important;
}

.foui-content-start {
  justify-content: start !important;
}

.foui-content-center {
  justify-content: center !important;
}

.foui-content-end {
  justify-content: end !important;
}

.foui-pa-0 {
  padding: 0 !important;
}

.foui-pl-0 {
  padding-left: 0 !important;
}

.foui-pt-0 {
  padding-top: 0 !important;
}

.foui-pr-0 {
  padding-right: 0 !important;
}

.foui-pb-0 {
  padding-bottom: 0 !important;
}

.foui-pa-1 {
  padding: 1px !important;
}

.foui-pl-1 {
  padding-left: 1px !important;
}

.foui-pt-1 {
  padding-top: 1px !important;
}

.foui-pr-1 {
  padding-right: 1px !important;
}

.foui-pb-1 {
  padding-bottom: 1px !important;
}

.foui-pa-2 {
  padding: 2px !important;
}

.foui-pl-2 {
  padding-left: 2px !important;
}

.foui-pt-2 {
  padding-top: 2px !important;
}

.foui-pr-2 {
  padding-right: 2px !important;
}

.foui-pb-2 {
  padding-bottom: 2px !important;
}

.foui-pa-3 {
  padding: 3px !important;
}

.foui-pl-3 {
  padding-left: 3px !important;
}

.foui-pt-3 {
  padding-top: 3px !important;
}

.foui-pr-3 {
  padding-right: 3px !important;
}

.foui-pb-3 {
  padding-bottom: 3px !important;
}

.foui-pa-4 {
  padding: 4px !important;
}

.foui-pl-4 {
  padding-left: 4px !important;
}

.foui-pt-4 {
  padding-top: 4px !important;
}

.foui-pr-4 {
  padding-right: 4px !important;
}

.foui-pb-4 {
  padding-bottom: 4px !important;
}

.foui-pa-5 {
  padding: 5px !important;
}

.foui-pl-5 {
  padding-left: 5px !important;
}

.foui-pt-5 {
  padding-top: 5px !important;
}

.foui-pr-5 {
  padding-right: 5px !important;
}

.foui-pb-5 {
  padding-bottom: 5px !important;
}

.foui-pa-10 {
  padding: 10px !important;
}

.foui-pl-10 {
  padding-left: 10px !important;
}

.foui-pt-10 {
  padding-top: 10px !important;
}

.foui-pr-10 {
  padding-right: 10px !important;
}

.foui-pb-10 {
  padding-bottom: 10px !important;
}

.foui-pa-15 {
  padding: 15px !important;
}

.foui-pl-15 {
  padding-left: 15px !important;
}

.foui-pt-15 {
  padding-top: 15px !important;
}

.foui-pr-15 {
  padding-right: 15px !important;
}

.foui-pb-15 {
  padding-bottom: 15px !important;
}

.foui-pa-20 {
  padding: 20px !important;
}

.foui-pl-20 {
  padding-left: 20px !important;
}

.foui-pt-20 {
  padding-top: 20px !important;
}

.foui-pr-20 {
  padding-right: 20px !important;
}

.foui-pb-20 {
  padding-bottom: 20px !important;
}

.foui-pa-30 {
  padding: 30px !important;
}

.foui-pl-30 {
  padding-left: 30px !important;
}

.foui-pt-30 {
  padding-top: 30px !important;
}

.foui-pr-30 {
  padding-right: 30px !important;
}

.foui-pb-30 {
  padding-bottom: 30px !important;
}

.foui-pa-40 {
  padding: 40px !important;
}

.foui-pl-40 {
  padding-left: 40px !important;
}

.foui-pt-40 {
  padding-top: 40px !important;
}

.foui-pr-40 {
  padding-right: 40px !important;
}

.foui-pb-40 {
  padding-bottom: 40px !important;
}

.foui-ma-0 {
  margin: 0 !important;
}

.foui-ml-0 {
  margin-left: 0 !important;
}

.foui-mt-0 {
  margin-top: 0 !important;
}

.foui-mr-0 {
  margin-right: 0 !important;
}

.foui-mb-0 {
  margin-bottom: 0 !important;
}

.foui-ma-0 p {
  margin: 0 !important;
}

.foui-ma-1 {
  margin: 1px !important;
}

.foui-ml-1 {
  margin-left: 1px !important;
}

.foui-mt-1 {
  margin-top: 1px !important;
}

.foui-mr-1 {
  margin-right: 1px !important;
}

.foui-mb-1 {
  margin-bottom: 1px !important;
}

.foui-ma-2 {
  margin: 2px !important;
}

.foui-ml-2 {
  margin-left: 2px !important;
}

.foui-mt-2 {
  margin-top: 2px !important;
}

.foui-mr-2 {
  margin-right: 2px !important;
}

.foui-mb-2 {
  margin-bottom: 2px !important;
}

.foui-ma-3 {
  margin: 3px !important;
}

.foui-ml-3 {
  margin-left: 3px !important;
}

.foui-mt-3 {
  margin-top: 3px !important;
}

.foui-mr-3 {
  margin-right: 3px !important;
}

.foui-mb-3 {
  margin-bottom: 3px !important;
}

.foui-ma-4 {
  margin: 4px !important;
}

.foui-ml-4 {
  margin-left: 4px !important;
}

.foui-mt-4 {
  margin-top: 4px !important;
}

.foui-mr-4 {
  margin-right: 4px !important;
}

.foui-mb-4 {
  margin-bottom: 4px !important;
}

.foui-ma-5 {
  margin: 5px !important;
}

.foui-ml-5 {
  margin-left: 5px !important;
}

.foui-mt-5 {
  margin-top: 5px !important;
}

.foui-mr-5 {
  margin-right: 5px !important;
}

.foui-mb-5 {
  margin-bottom: 5px !important;
}

.foui-ma-10 {
  margin: 10px !important;
}

.foui-ml-10 {
  margin-left: 10px !important;
}

.foui-mt-10 {
  margin-top: 10px !important;
}

.foui-mr-10 {
  margin-right: 10px !important;
}

.foui-mb-10 {
  margin-bottom: 10px !important;
}

.foui-ma-15 {
  margin: 15px !important;
}

.foui-ml-15 {
  margin-left: 15px !important;
}

.foui-mt-15 {
  margin-top: 15px !important;
}

.foui-mr-15 {
  margin-right: 15px !important;
}

.foui-mb-15 {
  margin-bottom: 15px !important;
}

.foui-o-0 {
  opacity: 0;
}

.foui-o-1 {
  opacity: .1;
}

.foui-o-2 {
  opacity: .2;
}

.foui-o-3 {
  opacity: .3;
}

.foui-o-4 {
  opacity: .4;
}

.foui-o-5 {
  opacity: .5;
}

.foui-o-6 {
  opacity: .6;
}

.foui-o-7 {
  opacity: .7;
}

.foui-o-8 {
  opacity: .8;
}

.foui-o-9 {
  opacity: .9;
}

.foui-o-10 {
  opacity: 1;
}

.foui-h1 {
  font-size: 200%;
}

.foui-h2 {
  font-size: 170%;
}

.foui-h3 {
  font-size: 145%;
}

.foui-h4 {
  font-size: 120%;
}

.foui-h5 {
  font-size: 100%;
}

.foui-h6 {
  font-size: 80%;
}

.foui-hero1 {
  font-size: 350%;
}

.foui-hero2 {
  font-size: 300%;
}

.foui-hero3 {
  font-size: 250%;
}

.foui-b {
  border: 1px solid var(--border) !important;
}

.foui-bl {
  border-left: 1px solid var(--border) !important;
}

.foui-bt {
  border-top: 1px solid var(--border) !important;
}

.foui-br {
  border-right: 1px solid var(--border) !important;
}

.foui-bb {
  border-bottom: 1px solid var(--border) !important;
}

.foui-bno {
  border: 0 !important;
}

.foui-b-2 {
  border: 2px solid var(--border) !important;
}

.foui-b-3 {
  border: 3px solid var(--border) !important;
}

.foui-b-4 {
  border: 4px solid var(--border) !important;
}

.foui-b-5 {
  border: 5px solid var(--border) !important;
}

.foui-b-6 {
  border: 6px solid var(--border) !important;
}

.foui-b-7 {
  border: 7px solid var(--border) !important;
}

.foui-b-8 {
  border: 8px solid var(--border) !important;
}

.foui-b-9 {
  border: 9px solid var(--border) !important;
}

.foui-b-10 {
  border: 10px solid var(--border) !important;
}

.foui-b-rad-0 {
  border-radius: 0;
}

.foui-b-rad-1 {
  border-radius: 1px;
}

.foui-b-rad-2 {
  border-radius: 2px;
}

.foui-b-rad-3 {
  border-radius: 3px;
}

.foui-b-rad-4 {
  border-radius: 4px;
}

.foui-b-rad-5 {
  border-radius: 5px;
}

.foui-b-rad-6 {
  border-radius: 6px;
}

.foui-b-rad-7 {
  border-radius: 7px;
}

.foui-b-rad-8 {
  border-radius: 8px;
}

.foui-b-rad-9 {
  border-radius: 9px;
}

.foui-b-rad-10 {
  border-radius: 10px;
}

.foui-b-rad-0p {
  border-radius: 0%;
}

.foui-b-rad-1p {
  border-radius: 10%;
}

.foui-b-rad-2p {
  border-radius: 20%;
}

.foui-b-rad-3p {
  border-radius: 30%;
}

.foui-b-rad-4p {
  border-radius: 40%;
}

.foui-b-rad-5p {
  border-radius: 50%;
}

.foui-b-rad-6p {
  border-radius: 60%;
}

.foui-b-rad-7p {
  border-radius: 70%;
}

.foui-b-rad-8p {
  border-radius: 80%;
}

.foui-b-rad-9p {
  border-radius: 90%;
}

.foui-b-rad-10p {
  border-radius: 100%;
}

.foui-cursor-pointer {
  cursor: pointer !important;
}

.foui-text-truncate {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

th.foui-text-truncate, td.foui-text-truncate {
  max-width: 0;
}

.foui-text-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

th.foui-text-break, td.foui-text-break {
  word-break: break-all;
}

.foui-line-limit-1, .foui-line-limit-2, .foui-line-limit-3, .foui-line-limit-4, .foui-line-limit-5 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.foui-line-limit-2 {
  -webkit-line-clamp: 2;
}

.foui-line-limit-3 {
  -webkit-line-clamp: 3;
}

.foui-line-limit-4 {
  -webkit-line-clamp: 4;
}

.foui-line-limit-5 {
  -webkit-line-clamp: 5;
}

.foui-box-shadow-tiny {
  box-shadow: var(--global-tiny-box-shadow);
}

.foui-box-shadow-small {
  box-shadow: var(--global-small-box-shadow);
}

.foui-box-shadow-medium {
  box-shadow: var(--global-medium-box-shadow);
}

.foui-box-shadow-large {
  box-shadow: var(--global-large-box-shadow);
}

.foui-box-shadow-xlarge {
  box-shadow: var(--global-xlarge-box-shadow);
}

[class*="foui-box-shadow-hover"] {
  transition: box-shadow var(--box-shadow-duration) ease-in-out;
}

.foui-box-shadow-hover-tiny:hover {
  box-shadow: var(--global-tiny-box-shadow);
}

.foui-box-shadow-hover-small:hover {
  box-shadow: var(--global-small-box-shadow);
}

.foui-box-shadow-hover-medium:hover {
  box-shadow: var(--global-medium-box-shadow);
}

.foui-box-shadow-hover-large:hover {
  box-shadow: var(--global-large-box-shadow);
}

.foui-box-shadow-hover-xlarge:hover {
  box-shadow: var(--global-xlarge-box-shadow);
}

.foui-inner-shadow-small {
  box-shadow: inset 0 2px 7px #0000001a;
}

.foui-light-shadow {
  box-shadow: 0 5px 15px #00000014;
}

.foui-shadow-0 {
  box-shadow: var(--shadow-0);
}

.foui-shadow-1 {
  box-shadow: var(--shadow-1);
}

.foui-shadow-2 {
  box-shadow: var(--shadow-2);
}

.foui-shadow-3 {
  box-shadow: var(--shadow-3);
}

.foui-shadow-4 {
  box-shadow: var(--shadow-4);
}

.foui-shadow-5 {
  box-shadow: var(--shadow-5);
}

.f-xs {
  font-size: 60% !important;
  font-weight: 300 !important;
}

.f-s {
  font-size: 80% !important;
  font-weight: 300 !important;
}

.f-m {
  font-size: 90% !important;
  font-weight: 300 !important;
}

.f-n {
  font-size: 100% !important;
  font-weight: 300 !important;
}

.f-l {
  font-size: 120% !important;
  font-weight: 300 !important;
}

.f-xl {
  font-size: 140% !important;
  font-weight: 300 !important;
}

.f-xxl {
  font-size: 160% !important;
  font-weight: 300 !important;
}

.f-xxxl {
  font-size: 180% !important;
  font-weight: 300 !important;
}

.f-t {
  font-weight: 100 !important;
}

.f-bm {
  font-weight: 500 !important;
}

.f-b {
  font-weight: 700 !important;
}

.f-i {
  font-style: italic !important;
}

.f-strike {
  text-decoration: line-through !important;
}

.foui-trans-01 {
  transition: all .1s linear;
}

.foui-trans-02 {
  transition: all .2s linear;
}

.foui-trans-03 {
  transition: all .3s linear;
}

.foui-trans-04 {
  transition: all .4s linear;
}

.foui-trans-05 {
  transition: all .5s linear;
}

.foui-back1 {
  background-color: #f7f6f8;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23dededf' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.foui-primary.bordered, .foui-secondary.bordered, .foui-accent.bordered, .foui-accent2.bordered, .foui-dull.bordered, .foui-success.bordered, .foui-warning.bordered, .foui-danger.bordered, .foui-info.bordered {
  border: var(--border);
  color: var(--text-main);
}

.foui-primary {
  background-color: var(--primary);
}

.foui-secondary {
  background-color: var(--secondary);
}

.foui-accent {
  background-color: var(--accent);
}

.foui-accent2 {
  background-color: var(--accent2);
}

.foui-dull {
  background-color: var(--dull);
}

.foui-success {
  background-color: var(--success);
}

.foui-warning {
  background-color: var(--warning);
}

.foui-danger {
  background-color: var(--danger);
}

.foui-info {
  background-color: var(--info);
}

.foui-control {
  background-color: var(--control);
}

.foui-control2 {
  background-color: var(--control-light);
}

.foui-window {
  background-color: var(--theme-window);
}

.foui-primary-light {
  background-color: var(--primary-light);
}

.foui-secondary-light {
  background-color: var(--secondary-light);
}

.foui-accent-light {
  background-color: var(--accent-light);
}

.foui-accent2-light {
  background-color: var(--accent2-light);
}

.foui-dull-bright {
  background-color: var(--dull-bright);
}

.foui-dull-light {
  background-color: var(--dull-light);
}

.foui-success-light {
  background-color: var(--success-light);
}

.foui-warning-light {
  background-color: var(--warning-light);
}

.foui-danger-light {
  background-color: var(--danger-light);
}

.foui-info-light {
  background-color: var(--info-light);
}

.foui-control-light {
  background-color: var(--control-light);
}

.foui-primary-dark {
  background-color: var(--primary-dark);
}

.foui-secondary-dark {
  background-color: var(--secondary-dark);
}

.foui-accent-dark {
  background-color: var(--accent-dark);
}

.foui-accent2-dark {
  background-color: var(--accent2-dark);
}

.foui-dull-dark {
  background-color: var(--dull-dark);
}

.foui-success-dark {
  background-color: var(--success-dark);
}

.foui-warning-dark {
  background-color: var(--warning-dark);
}

.foui-danger-dark {
  background-color: var(--danger-dark);
}

.foui-info-dark {
  background-color: var(--info-dark);
}

.foui-control-dark {
  background-color: var(--control-dark);
}

.foui-primary-text {
  color: var(--primary-text);
}

.foui-secondary-text {
  color: var(--secondary-text);
}

.foui-accent-text {
  color: var(--accent-text);
}

.foui-accent2-text {
  color: var(--accent2-text);
}

.foui-dull-text {
  color: var(--dull-text);
}

.foui-success-text {
  color: var(--success-text);
}

.foui-warning-text {
  color: var(--warning-text);
}

.foui-danger-text {
  color: var(--danger-text);
}

.foui-info-text {
  color: var(--info-text);
}

.foui-window-text {
  color: var(--text-main);
}

.foui-text-primary {
  color: var(--primary) !important;
  fill: var(--primary) !important;
}

.foui-text-secondary {
  color: var(--secondary) !important;
  fill: var(--secondary) !important;
}

.foui-text-accent {
  color: var(--accent) !important;
  fill: var(--accent) !important;
}

.foui-text-accent2 {
  color: var(--accent2) !important;
  fill: var(--accent2) !important;
}

.foui-text-dull {
  color: var(--text-dull) !important;
  fill: var(--text-dull) !important;
}

.foui-text-success {
  color: var(--success) !important;
  fill: var(--success) !important;
}

.foui-text-warning {
  color: var(--warning) !important;
  fill: var(--warning) !important;
}

.foui-text-danger {
  color: var(--danger) !important;
  fill: var(--danger) !important;
}

.foui-text-info {
  color: var(--info) !important;
  fill: var(--info) !important;
}

.foui-text-main {
  color: var(--text-main) !important;
  fill: var(--text-main) !important;
}

.foui-text-input {
  color: var(--text-input) !important;
  fill: var(--text-input) !important;
}

.dark {
  color: var(--primary-text) !important;
  fill: var(--primary-text) !important;
}

.foui-border-primary {
  border-color: var(--primary);
}

.foui-border-secondary {
  border-color: var(--secondary);
}

.foui-border-accent {
  border-color: var(--accent);
}

.foui-border-accent2 {
  border-color: var(--accent2);
}

.foui-border-dull {
  border-color: var(--dull);
}

.foui-border-success {
  border-color: var(--success);
}

.foui-border-warning {
  border-color: var(--warning);
}

.foui-border-danger {
  border-color: var(--danger);
}

.foui-border-info {
  border-color: var(--info);
}

.foui-border-main {
  color: var(--border);
}

.foui-col-text-input-stroke {
  stroke: var(--text-input);
}

.foui-fill-primary {
  fill: var(--primary) !important;
}

.foui-fill-secondary {
  fill: var(--secondary) !important;
}

.foui-fill-accent {
  fill: var(--accent) !important;
}

.foui-fill-accent2 {
  fill: var(--accent2) !important;
}

.foui-fill-dull {
  fill: var(--dull) !important;
}

.foui-fill-success {
  fill: var(--success) !important;
}

.foui-fill-warning {
  fill: var(--warning) !important;
}

.foui-fill-danger {
  fill: var(--danger) !important;
}

.foui-fill-info {
  fill: var(--info) !important;
}

.foui-fill-main, .foui-fill-normal {
  fill: var(--text-main) !important;
}

.foui-fill-control {
  fill: var(--control) !important;
}

.foui-fill-control2 {
  fill: var(--control-light) !important;
}

.foui-stroke-primary {
  stroke: var(--primary) !important;
}

.foui-stroke-secondary {
  stroke: var(--secondary) !important;
}

.foui-stroke-accent {
  stroke: var(--accent) !important;
}

.foui-stroke-accent2 {
  stroke: var(--accent2) !important;
}

.foui-stroke-dull {
  stroke: var(--dull) !important;
}

.foui-stroke-dull-dark {
  stroke: var(--dull-dark) !important;
}

.foui-stroke-success {
  stroke: var(--success) !important;
}

.foui-stroke-warning {
  stroke: var(--warning) !important;
}

.foui-stroke-danger {
  stroke: var(--danger) !important;
}

.foui-stroke-info {
  stroke: var(--info) !important;
}

.foui-stroke-main, .foui-stroke-normal {
  stroke: var(--text-main) !important;
}

.foui-stroke-control {
  stroke: var(--control) !important;
}

.foui-stroke-control2 {
  stroke: var(--control-light) !important;
}

.foui-cico-primary {
  fill: var(--primary) !important;
}

.foui-cico-secondary {
  fill: var(--secondary) !important;
}

.foui-cico-accent {
  fill: var(--accent) !important;
}

.foui-cico-accent2 {
  fill: var(--accent2) !important;
}

.foui-cico-dull {
  fill: var(--text-dull) !important;
}

.foui-cico-success {
  fill: var(--success) !important;
}

.foui-cico-warning {
  fill: var(--warning) !important;
}

.foui-cico-danger {
  fill: var(--danger) !important;
}

.foui-cico-info {
  fill: var(--info) !important;
}

.foui-cico-main, .foui-cico-normal {
  fill: var(--text-main) !important;
}

.foui-control-dark .foui-cico-dull {
  fill: var(--text-dull-opposite) !important;
}

.foui-control-dark .foui-cico-normal, .foui-control-dark .foui-cico-main {
  fill: var(--text-opposite) !important;
}

.foui-cico1 {
  fill: var(--cico1) !important;
}

.foui-cico2 {
  fill: var(--cico2) !important;
}

.foui-cico3 {
  fill: var(--cico3) !important;
}

.foui-cico4 {
  fill: var(--cico4) !important;
}

.foui-back-halftone {
  background-image: radial-gradient( circle at 1px 1px, var(--dull) 1px, transparent 0 );
  background-repeat: round;
  background-size: 10px 10px;
}

.foui-back-mathbook {
  background-size: 20px 20px;
  background-image: linear-gradient(90deg, var(--dull) 1px, transparent 0px), linear-gradient(var(--dull) 1px, transparent 0px);
}

.fob-dialog-title {
  padding: 4px 0 0 5px;
  font-size: 160%;
}



