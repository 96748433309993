.foui-tabs {
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: column;
    color: var(--text-color) !important;
    font: var(--font-gen);
    padding-left: var(--text-pad-side);
    padding-right: var(--text-pad-side);
    /* outline: 1px solid var(--input-outline); */
    width: 100%;
}
.foui-tabs.bordered {
    box-shadow: var(--text-border);
    border-radius: var(--text-radius);
}
/* .foui-tabs.bordered:hover {
    box-shadow: var(--text-border-hover);
} */
.foui-tabs.bordered:enabled:hover {
    box-shadow: var(--hover-border);
}
.foui-tabs.bordered:enabled:focus {
    box-shadow: var(--focus-border);
}
/* Tabs bar */
.foui-tabs-tab-box {
    display: flex;
    flex-direction: row;
    position: relative;
}
.foui-tabs-tab-box::before {
    content: "";
    background-color: var(--border);
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
}
.foui-tabs-tab {
    background-color: transparent;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    /* padding: 10px; */
    height: 28px;
    padding: 0 10px;
    z-index: 1;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
.foui-tabs-tab-selected {
    border-bottom: 2px solid var(--primary);
    font-weight: bold;
}
.foui-tabs-tab-text {
    flex-grow: 1;
    text-align: center;
}
/* Trick to bold text without changing the size of the container. This class' elem is hidden but pushes the size. */
.foui-tabs-tab-text::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
/* Contents */
.foui-tabs-content-box {
    display: flex;
    height: 100%;
}
/* .foui-tabs-content {
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
} */
.foui-table-sort-ico {
    padding-inline-start: 3px;
}
