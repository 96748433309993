.foui-panel {
    /*background-color: var(--text-back);*/
    border: 0;
    overflow: auto;
}
.foui-panel.bordered {
    box-shadow: var(--text-border);
    border-radius: var(--text-radius);
}
.foui-panel.tinted-background {
    background-color: var(--back-tinted);
}
