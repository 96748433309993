.foui-focus { box-shadow: var(--focus-border) !important; }
.foui-on-top {
    z-index: 50;
}

.foui-stretch { width: 100%; height: 100%; }
.foui-stretch-x { width: 100%; }
.foui-stretch-y { height: 100%; }

.foui-flex { display: flex !important; }
.foui-flex-grow { flex-grow: 1 !important; }
.foui-flex-shrink { flex-shrink: 1 !important; }
.foui-flex-wrap { flex-wrap: wrap !important; }
.foui-flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.foui-flex-wrap-none { flex-wrap: nowrap !important; }
.foui-flex-row { flex-direction: row !important; }
.foui-flex-column { flex-direction: column !important; }
.foui-flex-row-reverse { flex-direction: row-reverse !important; }
.foui-flex-column-reverse { flex-direction: column-reverse !important; }
.foui-flex-align-self-auto { align-self: auto !important; }
.foui-flex-align-self-start { align-self: start !important; }
.foui-flex-align-self-center { align-self: center !important; }
.foui-flex-align-self-end { align-self: end !important; }
.foui-flex-align-items-start { align-items: start !important; }
.foui-flex-align-items-center { align-items: center !important; }
.foui-flex-align-items-end { align-items: end !important; }
.foui-flex-justify-content-start { justify-content: start !important; }
.foui-flex-justify-content-center { justify-content: center !important; }
.foui-flex-justify-content-end { justify-content: end !important; }

.foui-block { display: block !important; }
.foui-inline-block { display: inline-block !important; }
.foui-float-left { float: left !important; }
.foui-float-right { float: right !important; }
[class*='foui-float-'] { max-width: 100%; }
.foui-overflow-hidden { overflow: hidden !important; }
.foui-overflow-visible { overflow: visible !important; }
/* Firefox ignores `padding-bottom` for the scrollable overflow https://bugzilla.mozilla.org/show_bug.cgi?id=748518 */
.foui-overflow-auto {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.foui-overflow-auto > :last-child { margin-bottom: 0; }

.foui-absolute { position: absolute; }

.foui-hidden { display: none !important; }
.foui-no-vis { visibility: hidden !important; }

.foui-control-panel {
    display: flex;
}

.foui-control-ico {
    display: flex;
    vertical-align: middle !important;
    align-self: center !important;
}
.foui-control-text {
    display: flex;
    vertical-align: middle !important;
    align-self: center !important;
}

.foui-text-bold { font-weight: bold !important; }
.foui-text-medium { font-weight: 500 !important; }
.foui-text-normal { font-weight: 300 !important; }
.foui-text-thin { font-weight: 100 !important; }
.foui-text-italic { font-style: italic !important; }
.foui-text-normal { font-style: normal !important; }
.foui-text-underline { text-decoration: underline !important; }
.foui-text-nowrap { white-space: nowrap; }

.foui-text-start { text-align: start !important; }
.foui-text-end { text-align: end !important; }
.foui-text-center { text-align: center !important; }
.foui-text-justify { text-align: justify !important; }
.foui-text-start input { text-align: start !important; }
.foui-text-end input { text-align: end !important; }
.foui-text-center input { text-align: center !important; }
.foui-text-justify input { text-align: justify !important; }

.foui-text-top { vertical-align: top !important; align-self: start !important; }
.foui-text-middle { vertical-align: middle !important; align-self: center !important; }
.foui-text-bottom { vertical-align: bottom !important; align-self: end !important; }
.foui-text-baseline { vertical-align: baseline !important; }

.foui-content-start { justify-content: start !important; }
.foui-content-center { justify-content: center !important; }
.foui-content-end { justify-content: end !important; }

/* Padding and margin. */
/* @while $i <= 30 { */
.foui-pa-0 { padding: 0px !important; }
.foui-pl-0 { padding-left: 0px !important; }
.foui-pt-0 { padding-top: 0px !important; }
.foui-pr-0 { padding-right: 0px !important; }
.foui-pb-0 { padding-bottom: 0px !important; }

.foui-pa-1 { padding: 1px !important; }
.foui-pl-1 { padding-left: 1px !important; }
.foui-pt-1 { padding-top: 1px !important; }
.foui-pr-1 { padding-right: 1px !important; }
.foui-pb-1 { padding-bottom: 1px !important; }

.foui-pa-2 { padding: 2px !important; }
.foui-pl-2 { padding-left: 2px !important; }
.foui-pt-2 { padding-top: 2px !important; }
.foui-pr-2 { padding-right: 2px !important; }
.foui-pb-2 { padding-bottom: 2px !important; }

.foui-pa-3 { padding: 3px !important; }
.foui-pl-3 { padding-left: 3px !important; }
.foui-pt-3 { padding-top: 3px !important; }
.foui-pr-3 { padding-right: 3px !important; }
.foui-pb-3 { padding-bottom: 3px !important; }

.foui-pa-4 { padding: 4px !important; }
.foui-pl-4 { padding-left: 4px !important; }
.foui-pt-4 { padding-top: 4px !important; }
.foui-pr-4 { padding-right: 4px !important; }
.foui-pb-4 { padding-bottom: 4px !important; }

.foui-pa-5 { padding: 5px !important; }
.foui-pl-5 { padding-left: 5px !important; }
.foui-pt-5 { padding-top: 5px !important; }
.foui-pr-5 { padding-right: 5px !important; }
.foui-pb-5 { padding-bottom: 5px !important; }

.foui-pa-10 { padding: 10px !important; }
.foui-pl-10 { padding-left: 10px !important; }
.foui-pt-10 { padding-top: 10px !important; }
.foui-pr-10 { padding-right: 10px !important; }
.foui-pb-10 { padding-bottom: 10px !important; }

.foui-pa-15 { padding: 15px !important; }
.foui-pl-15 { padding-left: 15px !important; }
.foui-pt-15 { padding-top: 15px !important; }
.foui-pr-15 { padding-right: 15px !important; }
.foui-pb-15 { padding-bottom: 15px !important; }

.foui-pa-20 { padding: 20px !important; }
.foui-pl-20 { padding-left: 20px !important; }
.foui-pt-20 { padding-top: 20px !important; }
.foui-pr-20 { padding-right: 20px !important; }
.foui-pb-20 { padding-bottom: 20px !important; }

.foui-pa-30 { padding: 30px !important; }
.foui-pl-30 { padding-left: 30px !important; }
.foui-pt-30 { padding-top: 30px !important; }
.foui-pr-30 { padding-right: 30px !important; }
.foui-pb-30 { padding-bottom: 30px !important; }

.foui-pa-40 { padding: 40px !important; }
.foui-pl-40 { padding-left: 40px !important; }
.foui-pt-40 { padding-top: 40px !important; }
.foui-pr-40 { padding-right: 40px !important; }
.foui-pb-40 { padding-bottom: 40px !important; }

.foui-ma-0 { margin: 0px !important; }
.foui-ml-0 { margin-left: 0px !important; }
.foui-mt-0 { margin-top: 0px !important; }
.foui-mr-0 { margin-right: 0px !important; }
.foui-mb-0 { margin-bottom: 0px !important; }
.foui-ma-0 p { margin: 0 !important; }

.foui-ma-1 { margin: 1px !important; }
.foui-ml-1 { margin-left: 1px !important; }
.foui-mt-1 { margin-top: 1px !important; }
.foui-mr-1 { margin-right: 1px !important; }
.foui-mb-1 { margin-bottom: 1px !important; }

.foui-ma-2 { margin: 2px !important; }
.foui-ml-2 { margin-left: 2px !important; }
.foui-mt-2 { margin-top: 2px !important; }
.foui-mr-2 { margin-right: 2px !important; }
.foui-mb-2 { margin-bottom: 2px !important; }

.foui-ma-3 { margin: 3px !important; }
.foui-ml-3 { margin-left: 3px !important; }
.foui-mt-3 { margin-top: 3px !important; }
.foui-mr-3 { margin-right: 3px !important; }
.foui-mb-3 { margin-bottom: 3px !important; }

.foui-ma-4 { margin: 4px !important; }
.foui-ml-4 { margin-left: 4px !important; }
.foui-mt-4 { margin-top: 4px !important; }
.foui-mr-4 { margin-right: 4px !important; }
.foui-mb-4 { margin-bottom: 4px !important; }

.foui-ma-5 { margin: 5px !important; }
.foui-ml-5 { margin-left: 5px !important; }
.foui-mt-5 { margin-top: 5px !important; }
.foui-mr-5 { margin-right: 5px !important; }
.foui-mb-5 { margin-bottom: 5px !important; }

.foui-ma-10 { margin: 10px !important; }
.foui-ml-10 { margin-left: 10px !important; }
.foui-mt-10 { margin-top: 10px !important; }
.foui-mr-10 { margin-right: 10px !important; }
.foui-mb-10 { margin-bottom: 10px !important; }

.foui-ma-15 { margin: 15px !important; }
.foui-ml-15 { margin-left: 15px !important; }
.foui-mt-15 { margin-top: 15px !important; }
.foui-mr-15 { margin-right: 15px !important; }
.foui-mb-15 { margin-bottom: 15px !important; }

/* Opacity. */
.foui-o-0 { opacity: 0; }
.foui-o-1 { opacity: 0.1; }
.foui-o-2 { opacity: 0.2; }
.foui-o-3 { opacity: 0.3; }
.foui-o-4 { opacity: 0.4; }
.foui-o-5 { opacity: 0.5; }
.foui-o-6 { opacity: 0.6; }
.foui-o-7 { opacity: 0.7; }
.foui-o-8 { opacity: 0.8; }
.foui-o-9 { opacity: 0.9; }
.foui-o-10 { opacity: 1; }

/* Headers. */
.foui-h1 { font-size: 200%; }
.foui-h2 { font-size: 170%; }
.foui-h3 { font-size: 145%; }
.foui-h4 { font-size: 120%; }
.foui-h5 { font-size: 100%; }
.foui-h6 { font-size: 80%; }
.foui-hero1 { font-size: 350%; }
.foui-hero2 { font-size: 300%; }
.foui-hero3 { font-size: 250%; }

/* Borders. */
.foui-b { border: 1px solid var(--border) !important; }
.foui-bl { border-left: 1px solid var(--border) !important; }
.foui-bt { border-top: 1px solid var(--border) !important; }
.foui-br { border-right: 1px solid var(--border) !important; }
.foui-bb { border-bottom: 1px solid var(--border) !important; }
.foui-bno { border: 0 !important; }
.foui-b-2 { border: 2px solid var(--border) !important; }
.foui-b-3 { border: 3px solid var(--border) !important; }
.foui-b-4 { border: 4px solid var(--border) !important; }
.foui-b-5 { border: 5px solid var(--border) !important; }
.foui-b-6 { border: 6px solid var(--border) !important; }
.foui-b-7 { border: 7px solid var(--border) !important; }
.foui-b-8 { border: 8px solid var(--border) !important; }
.foui-b-9 { border: 9px solid var(--border) !important; }
.foui-b-10 { border: 10px solid var(--border) !important; }

/* Radius. */
.foui-b-rad-0 { border-radius: 0px; }
.foui-b-rad-1 { border-radius: 1px; }
.foui-b-rad-2 { border-radius: 2px; }
.foui-b-rad-3 { border-radius: 3px; }
.foui-b-rad-4 { border-radius: 4px; }
.foui-b-rad-5 { border-radius: 5px; }
.foui-b-rad-6 { border-radius: 6px; }
.foui-b-rad-7 { border-radius: 7px; }
.foui-b-rad-8 { border-radius: 8px; }
.foui-b-rad-9 { border-radius: 9px; }
.foui-b-rad-10 { border-radius: 10px; }

.foui-b-rad-0p { border-radius: 0%; }
.foui-b-rad-1p { border-radius: 10%; }
.foui-b-rad-2p { border-radius: 20%; }
.foui-b-rad-3p { border-radius: 30%; }
.foui-b-rad-4p { border-radius: 40%; }
.foui-b-rad-5p { border-radius: 50%; }
.foui-b-rad-6p { border-radius: 60%; }
.foui-b-rad-7p { border-radius: 70%; }
.foui-b-rad-8p { border-radius: 80%; }
.foui-b-rad-9p { border-radius: 90%; }
.foui-b-rad-10p { border-radius: 100%; }

/* Pointers */
.foui-cursor-pointer { cursor: pointer !important; }

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */

.foui-text-truncate {
    /* 1 */
    max-width: 100%;
    /* 2 */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* 3 */
th.foui-text-truncate,
td.foui-text-truncate { max-width: 0; }

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Add a hyphen where the word breaks
 * 4. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 */

.foui-text-break {
    /* 1 */
    overflow-wrap: break-word;
    /* 2 */
    word-wrap: break-word;
    /* 3 */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

/* 4 */
th.foui-text-break,
td.foui-text-break { word-break: break-all; }

/* 5 */
/*.foui-drop-cap {
    --drop-cap-lines: 3;
    font-size: calc(1em * var(--drop-cap-lines) * var(--body-line-height));
}*/

/* Limit text lines by clamping them. */
.foui-line-limit-1,
.foui-line-limit-2,
.foui-line-limit-3,
.foui-line-limit-4,
.foui-line-limit-5{
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.foui-line-limit-2 { -webkit-line-clamp: 2; }
.foui-line-limit-3 { -webkit-line-clamp: 3; }
.foui-line-limit-4 { -webkit-line-clamp: 4; }
.foui-line-limit-5 { -webkit-line-clamp: 5; }

/* Shadows. */
.foui-box-shadow-tiny { box-shadow: var(--global-tiny-box-shadow); }
.foui-box-shadow-small { box-shadow: var(--global-small-box-shadow); }
.foui-box-shadow-medium { box-shadow: var(--global-medium-box-shadow); }
.foui-box-shadow-large { box-shadow: var(--global-large-box-shadow); }
.foui-box-shadow-xlarge { box-shadow: var(--global-xlarge-box-shadow); }

/*
 * Hover
 */

[class*='foui-box-shadow-hover'] { transition: box-shadow var(--box-shadow-duration) ease-in-out; }

.foui-box-shadow-hover-tiny:hover { box-shadow: var(--global-tiny-box-shadow); }
.foui-box-shadow-hover-small:hover { box-shadow: var(--global-small-box-shadow); }
.foui-box-shadow-hover-medium:hover { box-shadow: var(--global-medium-box-shadow); }
.foui-box-shadow-hover-large:hover { box-shadow: var(--global-large-box-shadow); }
.foui-box-shadow-hover-xlarge:hover { box-shadow: var(--global-xlarge-box-shadow); }

.foui-inner-shadow-small { box-shadow: inset 0px 2px 7px rgba(0,0,0,0.1); }

.foui-light-shadow {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

/* Panel */
/*.foui-panel {
    background: var(--panel-back);
    border-radius: var(--panel-radius);
}*/
.foui-shadow-0 {
    box-shadow: var(--shadow-0);
}
.foui-shadow-1 {
    box-shadow: var(--shadow-1);
}
.foui-shadow-2 {
    box-shadow: var(--shadow-2);
}
.foui-shadow-3 {
    box-shadow: var(--shadow-3);
}
.foui-shadow-4 {
    box-shadow: var(--shadow-4);
}
.foui-shadow-5 {
    box-shadow: var(--shadow-5);
}

/* These font sizes are in relation the the elements size. */
.f-xs {
    font-size: 60% !important;
    font-weight: 300 !important;
}
.f-s {
    font-size: 80% !important;
    font-weight: 300 !important;
}
.f-m {
    font-size: 90% !important;
    font-weight: 300 !important;
}
.f-n {
    font-size: 100% !important;
    font-weight: 300 !important;
}
.f-l {
    font-size: 120% !important;
    font-weight: 300 !important;
}
.f-xl {
    font-size: 140% !important;
    font-weight: 300 !important;
}
.f-xxl {
    font-size: 160% !important;
    font-weight: 300 !important;
}
.f-xxxl {
    font-size: 180% !important;
    font-weight: 300 !important;
}
.f-t { font-weight: 100 !important; }
/* .f-bm { font-weight: 400 !important; } */
.f-bm { font-weight: 500 !important; }
.f-b { font-weight: 700 !important; }
.f-i { font-style: italic !important; }
.f-strike { text-decoration: line-through !important; }

.foui-trans-01 { transition: all 0.1s linear; }
.foui-trans-02 { transition: all 0.2s linear; }
.foui-trans-03 { transition: all 0.3s linear; }
.foui-trans-04 { transition: all 0.4s linear; }
.foui-trans-05 { transition: all 0.5s linear; }

/*https://philiprogers.com/svgpatterns/#chevrons*/
/*https://www.magicpattern.design/tools/css-backgrounds*/
/*https://heropatterns.com/*/
.foui-back1 {
    /*background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNjAiIGhlaWdodD0iMzAiPgo8ZGVmcz4KPHJlY3QgaWQ9InIiIHdpZHRoPSIzMCIgaGVpZ2h0PSIxNSIgZmlsbD0iIzNBM0EzQSIgc3Ryb2tlLXdpZHRoPSIyLjUiIHN0cm9rZT0iIzMxMzEzMSI+PC9yZWN0Pgo8ZyBpZD0icCI+Cjx1c2UgeGxpbms6aHJlZj0iI3IiPjwvdXNlPgo8dXNlIHk9IjE1IiB4bGluazpocmVmPSIjciI+PC91c2U+Cjx1c2UgeT0iMzAiIHhsaW5rOmhyZWY9IiNyIj48L3VzZT4KPHVzZSB5PSI0NSIgeGxpbms6aHJlZj0iI3IiPjwvdXNlPgo8L2c+CjwvZGVmcz4KPHVzZSB4bGluazpocmVmPSIjcCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtMjUpIHNrZXdZKDQwKSI+PC91c2U+Cjx1c2UgeGxpbms6aHJlZj0iI3AiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMwIDApIHNrZXdZKC00MCkiPjwvdXNlPgo8L3N2Zz4=");*/
    background-color: #f7f6f8;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23dededf' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/* *[data-tip]:hover::after,
*[data-tip]:focus::after {
    content: attr(data-tip);
    position: absolute;
    left: 0;
    top: 24px;
    min-width: 200px;
    border: 1px #aaaaaa solid;
    border-radius: 10px;
    background-color: #ffffcc;
    padding: 12px;
    color: #000000;
    font-size: 12pt;
    z-index: 10;
} */
