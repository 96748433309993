.foui-select {
    background-color: var(--select-back);
    border: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    position: relative;
    min-height: 10px;
    width: 100%;
}
.foui-select.bordered {
    box-shadow: var(--select-border);
    border-radius: var(--select-radius);
}
.foui-select.bordered:hover {
    box-shadow: var(--hover-border);
}
.foui-design-mode .foui-select.bordered:hover {
    box-shadow: none;
}
.foui-select.bordered:focus {
    box-shadow: var(--focus-border);
}
.foui-select.bordered.inset-focus:focus {
    box-shadow: var(--focus-inset-border);
}
.foui-select.bordered.no-focus:focus {
    box-shadow: none;
}
.foui-design-mode .foui-select.bordered:focus {
    box-shadow: none;
}

.foui-select-label {
    border: 0;
    border-radius: var(--select-radius);
    color: var(--select-color) !important;
    font: var(--font-gen);
    padding-left: var(--select-pad-side);
    padding-right: var(--select-pad-side);
}
.foui-select-filter-box {
    display: flex;
    margin: 4px 0px;
    width: 100%;
}
.foui-select-input {
    display: flex;
    flex-grow: 1;
}
.foui-select-filter-ico-box {
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 30px;
    inset-inline-end: 0;
    inset-block-start: 8px;
}
.foui-select-filter-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(0.75);
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 100%;
    /* margin-left: -4px; */
    margin-top: -3px
}
.foui-select-filter-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    height: 8px;
    background: currentColor;
    transform: rotate(-45deg);
    top: 10px;
    left: 12px
}
.foui-select-filter-sep {
    background-color: var(--select-back-filter-sep);
    min-height: 1px;
    width: 100%;
}
.foui-select-button {
    background: var(--select-button-color);
    /* background: var(--button-back);
    border: 1px solid var(--select-back);
    border-radius: var(--select-radius); */
    color: var(--button-color);
    cursor: default;
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30px;
    inset-inline-end: 0;
    position: absolute;
}
.foui-select-button-ico {
    display: inline-block;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    border-top: 2px solid var(--select-ico-color);
    border-right: 2px solid var(--select-ico-color);
    transform: rotate(135deg);
}
/*.foui-select-pop {
    background-color: var(--select-back);
    box-shadow: var(--select-list-border-shadow);
    border-radius: var(--select-radius);
    border-top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    min-height: 25px;
    !* max-height: 200px; *!
    width: 100%;
    z-index: 99;
}
.foui-select-list {
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
}
.foui-select-item {
    color: var(--select-color-item);
    cursor: default;
    min-height: 16px;
    padding: var(--select-pad-row) var(--select-pad-side);
    width: 100%;
}
.foui-select-item:hover {
    background-color: var(--back-hover-faint);
}
.foui-select-item-selected {
    background-color: var(--primary) !important;
    color: var(--primary-text) !important;
}*/

/*
.load-more {
	position: absolute;
	display: block;
	cursor: pointer;
  width: 40px; height: 40px;
  top: 50%; left: 50%;
  margin: -20px 0 0 -20px;
}
	.load-more:before,
	.load-more:after {
		position: absolute;
		display: block;
		content: '';
		width: 30px; height: 30px;
		left: 50%; top: 50%;
		margin-top: -15px;
		border-width: 2px;
		border-style: solid;
		border-color: transparent;
		border-bottom-color: #fff;
		border-right-color: #fff;
		border-radius: 0 0 5px 0;

		transform: translate(-50%, -50%) rotate(45deg);

		transition: all .4s ease-in-out;
	}
	.load-more.active:before {
		border-radius: 50%;
		animation:
			.8s rotate .4s linear forwards infinite;
	}
	.load-more.active:after {
		width: 38px; height: 38px;
		border-radius: 50%;
		animation:
			.8s rotate2 .4s linear forwards infinite;
	}
		@keyframes rotate {
			to {
				transform: translate(-50%, -50%) rotate(405deg);
			}
		}
		@keyframes rotate2 {
			to {
				transform: translate(-50%, -50%) rotate(-315deg);
			}
		}
	.load-more.disabled:before,
	.load-more.disabled:after {
    margin-top: 0;
		transform: translate(-50%, -50%) rotate(135deg) rotateY(180deg);
	}
*/
