.foui-popup {
    background-color: var(--popup-back);
    border-radius: var(--popup-radius);
    box-shadow: var(--popup-border-shadow);
    border: var(--popup-border);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    opacity: 0;
    position: absolute;
    max-height: 600px;
    min-width: 30px;
    width: auto;
    overflow: hidden;
    z-index: 10;
    transition: opacity 0.1s linear;
}
