.foui-splitter {
    /* background: var(--splitter-back);
    border: 0; */
    background: var(--splitter-back);
    /*border: 1px solid var(--border2);*/
    /* box-shadow: var(--button-border);
    border-radius: var(--button-radius);
    color: var(--button-color); */
    /*cursor: col-resize;*/
    display: inline-block;
    position: relative;
}
.foui-vertical {
    width: var(--splitter-thickness);
    height: 100%;
}
.foui-horizontal {
    width: 100%;
    height: var(--splitter-thickness);
}
.foui-splitter:hover:enabled {
    box-shadow: var(--hover-border);
    filter: brightness(105%);
}
.foui-splitter:focus:enabled {
    box-shadow: var(--focus-border);
    filter: brightness(105%);
}
.foui-splitter:disabled {
    opacity: 0.6;
}
.foui-splitter:active:enabled {
    filter: contrast(115%) brightness(103%);
}
