.foui-dialog {
    background: var(--alert-back);
    border: var(--alert-border);
    border-radius: var(--alert-radius);
    color: var(--alert-color);
    cursor: default;
    display: inline-block;
    transform-origin: center;
    transform: scale(0.5);
    overflow: hidden;
    opacity: 0;
    position: fixed;
    z-index: 999;
}
.foui-dialog-anim {
    transition: transform 0.2s ease, opacity 0.2s ease, top 0.2s ease;
}
.foui-dialog-overlay {
    background-color: var(--back-cover);
    backdrop-filter: blur(0px);
    color: var(--back-cover-text);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease;
    opacity: 0;
    position: fixed;
    z-index: 950;
}
.foui-dialog-title {
    background: var(--alert-back-title);
    font-size: var(--dialog-font-size-title);
    font-weight: normal;
    height: var(--dialog-title-height);
    padding-inline-start: var(--dialog-title-start-padding);
    display: flex;
}
.foui-dialog-title-icon {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: var(--dialog-title-padding);
    /* flex-basis: var(--dialog-title-height); */
}
.foui-dialog-title-text {
    align-self: center;
    flex-grow: 1;
}
.foui-dialog-title-close {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: var(--dialog-title-height);
}
.foui-dialog-title-close .foui-cico-normal {
    fill: var(--text-main);
}
.foui-dialog-title-close:hover {
    background-color: crimson;
}
.foui-dialog-title-close:hover .foui-cico-normal {
    fill: #FFFFFF !important;
}
.foui-dialog-holder {
    display: flex;
}
.foui-dialog-step {
    display: flex;
    flex-grow: 1;
}
.foui-dialog-spacer {
    flex-grow: 1;
}
.foui-dialog-actionbar {
    background: var(--alert-back-actions);
    min-height: var(--alert-action-height);
    display: flex;
    /* justify-content: flex-end; */
    padding: var(--alert-action-padding);
}
/* .foui-dialog-actionbar .foui-button {
    min-height: var(--alert-action-button-height);
} */
.foui-dialog-actionbar button {
    margin-inline-end: 5px;
}
.foui-dialog-actionbar button:last-child {
    margin-inline-end: 0;
}
.foui-dialog-busy {
    align-items: center;
    background: var(--alert-back-actions);
    display: flex;
    position: absolute;
    height: var(--alert-action-height);
    left: var(--alert-action-padding);
    right: var(--alert-action-padding);
    bottom: 0;
    z-index: 1;
}