.foui-spacer {
    display: inline-block;
    position: relative;
}
.foui-vertical {
    width: 1px;
    height: 100%;
}
.foui-horizontal {
    width: 100%;
    height: 1px;
}
