.foui-primary.bordered,
.foui-secondary.bordered,
.foui-accent.bordered,
.foui-accent2.bordered,
.foui-dull.bordered,
.foui-success.bordered,
.foui-warning.bordered,
.foui-danger.bordered,
.foui-info.bordered {
    border: var(--border);
    color: var(--text-main);
}

/* Background */
.foui-primary { background-color: var(--primary); }
.foui-secondary { background-color: var(--secondary); }
.foui-accent { background-color: var(--accent); }
.foui-accent2 { background-color: var(--accent2); }
.foui-dull { background-color: var(--dull); }
.foui-success { background-color: var(--success); }
.foui-warning { background-color: var(--warning); }
.foui-danger { background-color: var(--danger); }
.foui-info { background-color: var(--info); }
.foui-control { background-color: var(--control); }
.foui-control2 { background-color: var(--control-light); }
.foui-window { background-color: var(--theme-window); }
.foui-primary-light { background-color: var(--primary-light); }
.foui-secondary-light { background-color: var(--secondary-light); }
.foui-accent-light { background-color: var(--accent-light); }
.foui-accent2-light { background-color: var(--accent2-light); }
.foui-dull-bright { background-color: var(--dull-bright); }
.foui-dull-light { background-color: var(--dull-light); }
.foui-success-light { background-color: var(--success-light); }
.foui-warning-light { background-color: var(--warning-light); }
.foui-danger-light { background-color: var(--danger-light); }
.foui-info-light { background-color: var(--info-light); }
.foui-control-light { background-color: var(--control-light); }
.foui-primary-dark { background-color: var(--primary-dark); }
.foui-secondary-dark { background-color: var(--secondary-dark); }
.foui-accent-dark { background-color: var(--accent-dark); }
.foui-accent2-dark { background-color: var(--accent2-dark); }
.foui-dull-dark { background-color: var(--dull-dark); }
.foui-success-dark { background-color: var(--success-dark); }
.foui-warning-dark { background-color: var(--warning-dark); }
.foui-danger-dark { background-color: var(--danger-dark); }
.foui-info-dark { background-color: var(--info-dark); }
.foui-control-dark { background-color: var(--control-dark); }
/* Text for the previous backgrounds */
.foui-primary-text { color: var(--primary-text); }
.foui-secondary-text { color: var(--secondary-text); }
.foui-accent-text { color: var(--accent-text); }
.foui-accent2-text { color: var(--accent2-text); }
.foui-dull-text { color: var(--dull-text); }
.foui-success-text { color: var(--success-text); }
.foui-warning-text { color: var(--warning-text); }
.foui-danger-text { color: var(--danger-text); }
.foui-info-text { color: var(--info-text); }
.foui-window-text { color: var(--text-main); }

/* Text */
.foui-text-primary { color: var(--primary) !important; fill: var(--primary) !important; }
.foui-text-secondary { color: var(--secondary) !important; fill: var(--secondary) !important; }
.foui-text-accent { color: var(--accent) !important; fill: var(--accent) !important; }
.foui-text-accent2 { color: var(--accent2) !important; fill: var(--accent2) !important; }
.foui-text-dull { color: var(--text-dull) !important; fill: var(--text-dull) !important; }
.foui-text-success { color: var(--success) !important; fill: var(--success) !important; }
.foui-text-warning { color: var(--warning) !important; fill: var(--warning) !important; }
.foui-text-danger { color: var(--danger) !important; fill: var(--danger) !important; }
.foui-text-info { color: var(--info) !important; fill: var(--info) !important; }
.foui-text-main { color: var(--text-main) !important; fill: var(--text-main) !important; }
.foui-text-input { color: var(--text-input) !important; fill: var(--text-input) !important; }
.dark { color: var(--primary-text) !important; fill: var(--primary-text) !important; }

/* Border */
.foui-border-primary { border-color: var(--primary); }
.foui-border-secondary { border-color: var(--secondary); }
.foui-border-accent { border-color: var(--accent); }
.foui-border-accent2 { border-color: var(--accent2); }
.foui-border-dull { border-color: var(--dull); }
.foui-border-success { border-color: var(--success); }
.foui-border-warning { border-color: var(--warning); }
.foui-border-danger { border-color: var(--danger); }
.foui-border-info { border-color: var(--info); }
.foui-border-main { color: var(--border); }

/* SVG Colours */
.foui-col-text-input-stroke {
    stroke: var(--text-input);
}
/* Fill */
.foui-fill-primary { fill: var(--primary) !important; }
.foui-fill-secondary { fill: var(--secondary) !important; }
.foui-fill-accent { fill: var(--accent) !important; }
.foui-fill-accent2 { fill: var(--accent2) !important; }
.foui-fill-dull {  fill: var(--dull) !important; }
.foui-fill-success { fill: var(--success) !important; }
.foui-fill-warning { fill: var(--warning) !important; }
.foui-fill-danger { fill: var(--danger) !important; }
.foui-fill-info { fill: var(--info) !important; }
.foui-fill-main {  fill: var(--text-main) !important; }
.foui-fill-normal {  fill: var(--text-main) !important; }
.foui-fill-control { fill: var(--control) !important; }
.foui-fill-control2 { fill: var(--control-light) !important; }
/* Stroke */
.foui-stroke-primary { stroke: var(--primary) !important; }
.foui-stroke-secondary { stroke: var(--secondary) !important; }
.foui-stroke-accent { stroke: var(--accent) !important; }
.foui-stroke-accent2 { stroke: var(--accent2) !important; }
.foui-stroke-dull {  stroke: var(--dull) !important; }
.foui-stroke-dull-dark {  stroke: var(--dull-dark) !important; }
.foui-stroke-success { stroke: var(--success) !important; }
.foui-stroke-warning { stroke: var(--warning) !important; }
.foui-stroke-danger { stroke: var(--danger) !important; }
.foui-stroke-info { stroke: var(--info) !important; }
.foui-stroke-main {  stroke: var(--text-main) !important; }
.foui-stroke-normal {  stroke: var(--text-main) !important; }
.foui-stroke-control { stroke: var(--control) !important; }
.foui-stroke-control2 { stroke: var(--control-light) !important; }

/* SVG Icons. The element should only have one of the properties not set to `none` */
.foui-cico-primary { fill: var(--primary) !important; }
.foui-cico-secondary { fill: var(--secondary) !important; }
.foui-cico-accent { fill: var(--accent) !important; }
.foui-cico-accent2 { fill: var(--accent2) !important; }
.foui-cico-dull {  fill: var(--text-dull) !important; }
.foui-cico-success { fill: var(--success) !important; }
.foui-cico-warning { fill: var(--warning) !important; }
.foui-cico-danger { fill: var(--danger) !important; }
.foui-cico-info { fill: var(--info) !important; }
.foui-cico-main {  fill: var(--text-main) !important; }
.foui-cico-normal {  fill: var(--text-main) !important; }
.foui-control-dark .foui-cico-dull {  fill: var(--text-dull-opposite) !important; }
.foui-control-dark .foui-cico-normal {  fill: var(--text-opposite) !important; }
.foui-control-dark .foui-cico-main {  fill: var(--text-opposite) !important; }
/* Customisation */
.foui-cico1 { fill: var(--cico1) !important; }
.foui-cico2 { fill: var(--cico2) !important; }
.foui-cico3 { fill: var(--cico3) !important; }
.foui-cico4 { fill: var(--cico4) !important; }

/*https://css-irl.info/css-halftone-patterns/*/
.foui-back-halftone {
    background-image: radial-gradient(
        circle at 1px 1px,
        var(--dull) 1px,
        transparent 0
    );
    background-size: 10px 10px;
    background-repeat: round;
}
.foui-back-mathbook {
    background-size: 20px 20px;
    background-image: linear-gradient(90deg, var(--dull) 1px, transparent 0px), linear-gradient(var(--dull) 1px, transparent 0px);
}
