.foui-table {
    background-color: transparent;
    border: 0;
    color: var(--text-color) !important;
    font: var(--font-gen);
    font-variant-numeric: slashed-zero;
    position: relative;
    border-collapse: collapse;
    /* border-radius: 2px; */
    overflow: auto;
}
.foui-table-table {
    border-spacing: 0;
    width: 100%;
}
.foui-table.foui-table-borders-all {
    border: 1px solid var(--border);
}
/* .foui-table.foui-table-borders-horz {
    border-bottom: 1px solid var(--border);
} */
.foui-table.foui-table-borders-vert {
    border-left: 1px solid var(--border);
}
.foui-table-zebra tr:nth-child(odd) {
    background-color: var(--table-zebra);
}
.foui-table tr:hover {
    /* background-color: var(--back-gen);
    filter: brightness(var(--button-hover-bright)); */
    background-color: var(--back-hover-faint);
}
.foui-table.foui-table-sticky-asis thead {
    position: sticky;
    top: 0;
    z-index: 2;
}
.foui-table.foui-table-sticky-asis thead tr,
.foui-table.foui-table-sticky-top thead tr {
    position: sticky;
}
.foui-table.foui-table-sticky-top thead tr {
    top: 0;
}
.foui-table-header,
.foui-table th {
    background-color: var(--control-light);
    font-weight: normal;
    padding: 6px 5px;
    text-align: left;
    /* box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.8); */
}
.foui-table.foui-table-head-borders .foui-table-header,
.foui-table.foui-table-head-borders th {
    border-right: 1px solid var(--dull);
    border-top: 1px solid var(--dull);
}
.foui-table.foui-table-head-borders tr:last-child th {
    border-bottom: 1px solid var(--dull);
}
.foui-table td {
    max-width: 0;
    overflow: hidden;
    padding: 6px 5px;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
}
.foui-table th[contenteditable="true"] {
    content:"Edit Me!";
    white-space: nowrap;
}
/*[contenteditable="true"]:hover::before,
[contenteditable="true"]:focus::before{
    content:"Edit Me!";
}*/
.foui-table-counter {
    max-width: fit-content !important;
    min-width: 32px;
    width: 1%;
    position: sticky;
    left: 0;
    z-index: 1;
}
.foui-table.foui-table-borders-all td {
    border-right: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
}
.foui-table.foui-table-borders-horz td {
    border-bottom: 1px solid var(--border);
}
.foui-table.foui-table-borders-vert td {
    border-right: 1px solid var(--border);
}
.foui-table-progress {
    height: 4px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 100%;
}
/* .foui-table-filter-cell {
    padding: 0 !important;
} */
.foui-table-filter {
    background-color: var(--back-input) !important;
    background: var(--table-ico-filter);
    background-position: right 4px center;
    background-repeat: no-repeat;
    background-size: 10px;
    /* display: flex; */
    padding-inline-end: 16px !important;
}
.foui-table-filter:empty::before {
    content: "Filter";
    color: var(--text-dull);
}
/* .foui-table-filter-edit {
    background-color: rgba(249, 166, 42, 0.2) !important;
    background-color: var(--back-input);
    flex-grow: 1;
    padding: 6px 5px;
} */
/* .foui-table-filter-ico {
    align-self: center;
    flex-basis: 16px;
    margin-top: 2px;
    padding: 0 3px;
} */
.foui-table-filter-cell.nofilter {
    background-color: var(--back-disabled) !important;
}
/* .foui-table-filter-edit:focus {
    background-color: #84745E !important;
}
.foui-table-filter-ico:focus {
    background-color: #84745E !important;
}
.foui-table-sort-ico {
    padding: 2px 6px 0;
} */
.foui-table-sort-ico {
    width: 12px;
    height: 12px;
    display: inline-table;
}
/* td { text-align: justify; }
td:after { content: ""; display: inline-block; width: 100%; } */
.foui-table-accent-edit {
    /* background-color: var(--back-input); */
    flex-grow: 1;
    padding: 6px 5px;
    /* url('data:image/svg+xml;utf8,<svg width="10px" height="10px" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><g id="Air-Dark" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Pencil-Mini" transform="translate(-0.501081, 0.276004)"><g id="foui-cico-dull" transform="translate(5.112532, 5.112532) rotate(315.000000) translate(-5.112532, -5.112532) translate(-0.557029, 3.551881)"></g><path d="M10.7831737,3.55587716 L10.6404914,6.61240434 L3.19256914,6.67717859 L2.99126117,6.63677001 L-0.555948428,5.11653733 L2.99126117,3.59630464 L3.18822082,3.55587716 L10.7831737,3.55587716 Z M3.50705157,4.55487716 L3.28834709,4.55587716 L1.98134709,5.11587716 L3.28834709,5.67587716 L3.50505157,5.67287716 L3.49923889,5.65963155 C3.82929887,5.51293823 3.95230812,5.3489259 3.95230812,5.11653733 C3.95230812,4.91319733 3.85812917,4.76220789 3.61347002,4.62936465 L3.49923889,4.5734431 L3.50705157,4.55487716 Z M7.87305157,4.55487716 L4.84768201,4.55572026 C4.91661669,4.72710243 4.95230812,4.91453723 4.95230812,5.11653733 C4.95230812,5.31241621 4.91874703,5.49459916 4.85385715,5.66173001 L7.87305157,5.63487716 L7.87305157,4.55487716 Z M9.73534709,4.55587716 L8.87305157,4.55487716 L8.87305157,5.62587716 L9.68534709,5.61987716 L9.73534709,4.55587716 Z" id="foui-cico-dull" fill="#FFFFFF" fill-rule="nonzero" transform="translate(5.113613, 5.116528) rotate(315.000000) translate(-5.113613, -5.116528) "></path></g></g></svg>') */
    /* <svg width="10px" height="10px" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" stroke="black" stroke-width="0" fill="red"/></svg> */
    /* <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" stroke="black" stroke-width="0" fill="red"/></svg> */
    /* <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><path style="paint-order: fill; fill: rgb(204, 204, 204);" d="M 1 2 L 5 8 L 9 2 Z"/></svg> */
    /* <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><path style="fill: none; stroke: rgb(204, 204, 204); stroke-linejoin: round; stroke-linecap: round;" d="M 1 2 L 5 8 L 9 2"/></svg> */
    /* <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><path stroke="rgb(204, 204, 204)" stroke-width="2" fill="none" stroke-linejoin="round" stroke-linecap="round" d="M 1 3 L 5 7 L 9 3"/></svg> */
    /* <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><path style="fill: none; stroke-width: 2; stroke: rgb(204, 204, 204); stroke-linejoin: round; stroke-linecap: round;" d="M 1 3 L 5 7 L 9 3"/></svg> */
    /* <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><path style="stroke-linejoin: round; fill: none; stroke: rgb(204, 204, 204);" d="M 1 9 L 3 5 L 7 1.005 L 9 3 L 5 7 L 1 9 Z"/><path style="stroke: rgb(204, 204, 204); fill: none;" d="M 4 4 C 4 4 6 4 6 6"/></svg> */
}
.foui-table-edit-pencil {
    background-color: var(--back-input) !important;
    background: var(--table-ico-edit);
    background-position: right 4px center;
    background-repeat: no-repeat;
    background-size: 10px;
    padding-inline-end: 16px !important;
    white-space: nowrap;
}
.foui-table-edit-select {
    background-color: var(--back-input) !important;
    background: var(--table-ico-select);
    background-position: right 4px center;
    background-repeat: no-repeat;
    background-size: 10px;
    padding-inline-end: 16px !important;
    white-space: nowrap;
}
.foui-table-check-on,
.foui-table-check-off {
    padding-inline-start: 25px !important;
}
.foui-table-check-on::before,
.foui-table-check-off::before {
    content: "";
    background: var(--table-ico-check-off);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px;
    cursor: pointer;
    width: 22px;
    margin-inline-start: -25px !important;
    position: absolute;
    top: 0;
    bottom: 0;
}
.foui-table-check-on::before {
    background: var(--table-ico-check-on);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px;
}
