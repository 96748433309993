.foui-property-form {
    background-color: var(--back-tinted);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
}
.foui-property-form.bordered {
    box-shadow: var(--text-border);
    border-radius: var(--text-radius);
}
/*.foui-property-holder {
    position: relative;
    flex-grow: 1;
    overflow: auto;
}*/
.foui-property-value-form {
    /*border-collapse: collapse;*/
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
}
.foui-property-form-row {
    display: flex;
    flex-direction: row;
}
.foui-property-form-row.pf-label {
    align-items: center;
    height: 20px;
}
.foui-property-form-row.pf-input {
    align-items: center;
    height: 28px;
    margin-bottom: 10px;
}
