.foui-view {
    display: grid;
    height: 100%;
    position: relative;
    transition: opacity 0.2s linear;
    /* flex-grow: 1; */
}
/* .foui-designer .foui-layout-grid:hover {
    outline: 1px dashed var(--primary-nofocus);
    outline-offset: -1px;
}
.foui-designer .foui-layout-grid:hover::after {
    opacity: 1;
} */
