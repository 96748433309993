.foui-alert {
    background: var(--alert-back);
    border: var(--alert-border);
    border-radius: var(--alert-radius);
    color: var(--alert-color);
    cursor: default;
    display: inline-block;
    transform-origin: center;
    transform: scale(0.5);
    overflow: hidden;
    opacity: 0;
    position: fixed;
    z-index: 999;
}
.foui-alert-anim {
    transition: transform 0.2s ease, opacity 0.2s ease, top 0.2s ease;
}
.foui-alert-overlay {
    /* background-color: rgba(255, 255, 255, 0.1); */
    backdrop-filter: blur(0px);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease;
    opacity: 0;
    position: fixed;
    z-index: 950;
}
.foui-alert-title {
    background: var(--alert-back-title);
    font-size: var(--dialog-font-size-title);
    font-weight: normal;
    height: var(--alert-title-height);
    padding: var(--alert-title-padding);
    display: flex;
}
.foui-alert-title-text {
    align-self: center;
    flex-grow: 1;
}
.foui-alert-holder {
    display: flex;
}
.foui-alert-icon {
    padding: 10px;
    width: 50px;
}
.foui-alert-message {
    padding: 12px;
}
.foui-alert-actionbar {
    background: var(--alert-back-actions);
    min-height: var(--alert-action-height);
    display: flex;
    justify-content: flex-end;
    padding: var(--alert-action-padding);
}
/* .foui-alert-actionbar .foui-button {
    min-height: var(--alert-action-button-height);
} */
.foui-alert-ico-back {
    fill: var(--text-back);
    fill-opacity: 1;
    stroke: none;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.foui-alert-ico-ico {
    fill: var(--text-input);
    fill-opacity: 1;
    stroke: none;
    stroke-width: 0;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.foui-alert-icon.primary .foui-alert-ico-back { fill: var(--primary); }
.foui-alert-icon.primary .foui-alert-ico-ico { fill: var(--primary-text); }
.foui-alert-icon.secondary .foui-alert-ico-back { fill: var(--secondary); }
.foui-alert-icon.secondary .foui-alert-ico-ico { fill: var(--secondary-text); }
.foui-alert-icon.accent .foui-alert-ico-back { fill: var(--accent); }
.foui-alert-icon.accent .foui-alert-ico-ico { fill: var(--accent-text); }
.foui-alert-icon.dull .foui-alert-ico-back { fill: var(--dull); }
.foui-alert-icon.dull .foui-alert-ico-ico { fill: var(--dull-text); }
.foui-alert-icon.success .foui-alert-ico-back { fill: var(--success); }
.foui-alert-icon.success .foui-alert-ico-ico { fill: var(--success-text); }
.foui-alert-icon.warning .foui-alert-ico-back { fill: var(--warning); }
.foui-alert-icon.warning .foui-alert-ico-ico { fill: var(--warning-text); }
.foui-alert-icon.error .foui-alert-ico-back { fill: var(--danger); }
.foui-alert-icon.error .foui-alert-ico-ico { fill: var(--danger-text); }
.foui-alert-icon.info .foui-alert-ico-back { fill: var(--info); }
.foui-alert-icon.info .foui-alert-ico-ico { fill: var(--info-text); }
.foui-alert-icon.help .foui-alert-ico-back { fill: var(--info); }
.foui-alert-icon.help .foui-alert-ico-ico { fill: var(--info-text); }
