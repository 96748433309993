.foui-property-grid {
    background-color: var(--back-tinted);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
}
.foui-property-grid.bordered {
    box-shadow: var(--text-border);
    border-radius: var(--text-radius);
}
.foui-property-holder {
    position: relative;
    /* border-collapse: collapse; */
    /* background-color: var(--grid-back); */
    flex-grow: 1;
    overflow: auto;
}
.foui-property-table {
    border-collapse: collapse;
    width: 100%;
}
.foui-property-grid tr {
    border-bottom: 1px solid var(--border2);
}
.foui-property-grid tr:hover {
    background-color: var(--back-hover-faint);
}
.foui-property-grid th {
    background: var(--button-back);
    border-left: 1px solid var(--border2);
    color: var(--button-color);
    cursor: default;
    font-weight: bold;
    padding: var(--property-grid-header-padding);
    position: sticky;
    height: 22px;
    top: 0;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    z-index: 1;
    /* box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4); */
}
.foui-property-grid.auto-height th {
    height: auto;
    vertical-align: top;
    white-space: inherit;
}
.foui-property-table td {
    /* background-color: var(--property-grid-input-back); */
    background-color: var(--property-grid-prop-cell);
    border-left: 1px solid var(--border2);
    /*color: var(--text-color) !important;*/
    font: var(--font-gen);
    max-width: 0;
    /* overflow: hidden; */
    /* padding: var(--property-grid-header-padding); */
    height: 25px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
}
.foui-property-table.auto-height td {
    height: auto;
    position: relative;
    text-overflow: inherit;
    vertical-align: top;
    white-space: inherit;
}
.foui-property-grid th:nth-child(1) {
    border-left: 0;
}
.foui-property-grid td:nth-child(1) {
    /*background: transparent;*/
    border-left: 0;
    cursor: default;
    padding-inline-start: 5px;
}
.foui-property-grid tr.foui-selected td:nth-child(1) {
    background-color: var(--primary);
    color: var(--primary-text) !important;
}
.foui-property-grid .foui-changed {
    font-weight: bold;
    font-style: italic;
}
.foui-property-note {
    background-color: var(--property-grid-note-back);
    /* border-top: 1px solid $--border-color-general; */
    flex-basis: 70px;
    max-height: 70px;
    padding: 4px 5px;
    overflow: hidden;
    flex-shrink: 1;
}
.foui-property-note-title {
    font-weight: bold;
}
/* Inline controls. */
.foui-text-property {
    background-color: transparent;
    border: 0;
    padding: 0 3px;
}
.foui-select-property {
    background-color: transparent !important;
    border: 0 !important;
    outline: none !important;
    padding: 0 3px;
    width: 100%;
}
