.foui-slider {
    cursor: default;
    align-items: center;
    display: flex;
    position: relative;
}
.foui-slider-track {
    background: var(--slider-track);
    border-radius: var(--slider-radius);
    height: var(--slider-track-height);
    display: flex;
    align-items: center;
}
.foui-slider-value {
    background: var(--primary);
    border-radius: var(--slider-radius);
    height: var(--slider-track-height);
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
    /* transition: all 0.2s ease; */
}
.foui-slider-dot {
    background-color: var(--dull-dark);
    /* border: 0px solid var(--slider-track); */
    border-radius: var(--slider-tag-radius);
    display: inline-block;
    position: absolute;
    width: var(--slider-dot-size);
    height: var(--slider-dot-size);
    margin-left: calc((var(--slider-dot-size) / 2) * -1);
    z-index: 1;
}
.foui-slider-dot.foui-slider-dot-label::before {
    content: attr(data-tip);
    position: absolute;
    /* font-size: 14px; */
    top: 9px;
    transform: translateX(-50%);
    z-index: 1;
}
.foui-slider:hover .foui-slider-dot {
    background-color: var(--primary);
}
.foui-slider-tag {
    background-color: var(--dull-dark);
    border: 2px solid var(--primary-back);
    border-radius: var(--slider-tag-radius);
    display: inline-block;
    position: absolute;
    width: var(--slider-tag-size);
    height: var(--slider-tag-size);
    margin-left: calc((var(--slider-tag-size) / 2) * -1);
    z-index: 2;
    transition: all 0.2s ease;
}
.foui-slider-tag-start {
    background-color: var(--dull-dark);
    border: 2px solid var(--primary-back);
    border-radius: var(--slider-tag-radius);
    display: inline-block;
    position: absolute;
    width: var(--slider-tag-size);
    height: var(--slider-tag-size);
    margin-left: calc((var(--slider-tag-size) / 2) * -1);
    z-index: 2;
    transition: all 0.2s ease;
}
.foui-slider-tag:hover,
.foui-slider-tag-start:hover {
    width: var(--slider-tag-size-focus);
    height: var(--slider-tag-size-focus);
    margin-left: calc((var(--slider-tag-size-focus) / 2) * -1);
}
