.foui-check {
    cursor: default;
    align-items: center;
    display: flex;
}
.foui-check-noview {
    display: inline-block;
    position: absolute;
    opacity: 0.001;
}
.foui-check:hover .foui-check-label-ico {
    box-shadow: var(--hover-border);
}
.foui-check-noview:focus ~ .foui-check-label-ico {
    box-shadow: var(--focus-border);
}
.foui-check-label {
    color: var(--check-color);
    cursor: default;
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: var(--check-padding);
}
.foui-check-label-ico {
    background-color: var(--text-back);
    box-shadow: var(--check-border);
    border-radius: var(--check-radius);
    display: flex;
    flex-direction: row;
    align-self: center;
    height: var(--check-box-size);
    width: var(--check-box-size);
}
/* .foui-ico-check::after {
    color: var(--check-color-check);
    content: "";
    display: block;
    box-sizing: border-box;
    position: relative;
    opacity: 0;
    left: var(--check-mark-left);
    top: var(--check-mark-top);
    width: var(--check-mark-width);
    height: var(--check-mark-height);
    border-width: 0 var(--check-mark-thick) var(--check-mark-thick) 0;
    border-style: solid;
    transition: all 0.2s ease;
    transform-origin: bottom left;
    transform: rotate(45deg);
}
.foui-check-noview:checked ~ .foui-ico-check::after {
    opacity: 1;
} */
/* This is the class name inside the SVG */
.foui-check-ico {
    fill: none;
    stroke-width: 2;
    stroke: var(--text-input);
    /* transition: all 0.2s ease; */
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
    transform-origin: center;
    /* transform: scale(0.7); */
    opacity: 0;
}
.foui-check-noview:checked ~ label svg g .foui-check-ico {
    /* transform: scale(1); */
    opacity: 1;
}
