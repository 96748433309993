.foui-header-line-title {
    background-color: var(--back-main);
    align-self: center;
    text-align: start;
    flex-direction: row;
}
.foui-header-line-title-label {
    color: var(--text-dull);
    display: flex;
    flex-shrink: 1;
    align-self: center;
    margin-inline-start: 5px;
    margin-inline-end: 5px;
}
.foui-header-line-title-pattern {
    flex-grow: 1;
    height: 8px;
    align-self: center;
}
.foui-header-line-title-actions {
    display: flex;
    flex-shrink: 1;
    height: 100%;
    align-self: center;
}
.foui-header-line-title-pattern {
    /* background:
        radial-gradient(rgba(255,255,255,0.2) 15%, transparent 16%),
        radial-gradient(rgba(255,255,255,0.2) 15%, transparent 16%),
        radial-gradient(rgba(255,255,255,0.2) 15%, transparent 16%),
        transparent; */
    background:
        radial-gradient(var(--header-line-title-bar-color) 15%, transparent 16%),
        radial-gradient(var(--header-line-title-bar-color) 15%, transparent 16%),
        radial-gradient(var(--header-line-title-bar-color) 15%, transparent 16%),
        transparent;
    background-position: 0 0, 2px 2px, 0 4px;
    background-size: 4px 4px;
    background-repeat: repeat-x;
}
