.foui-button {
    background: var(--button-back);
    border: 0;
    box-shadow: var(--button-border);
    border-radius: var(--button-radius);
    color: var(--button-color);
    cursor: default;
    display: flex;
    padding: var(--button-padding);
    text-align: center;
    justify-content: center;
    align-items: center;
}
.foui-button:hover:enabled {
    box-shadow: var(--hover-border);
    filter: brightness(105%);
}
.foui-design-mode .foui-button:hover:enabled {
    box-shadow: none;
    filter: none;
}
.foui-button:focus:enabled {
    box-shadow: var(--focus-border);
    filter: brightness(105%);
}
.foui-button.inset-focus:focus:enabled {
    box-shadow: var(--focus-inset-border);
}
.foui-button.no-focus:focus:enabled {
    box-shadow: none;
}
.foui-design-mode .foui-button:focus:enabled {
    box-shadow: none;
    filter: none;
}
.foui-button.bordered {
    background: transparent;
}
.foui-button:disabled {
    opacity: 0.6;
}
/* Standard. */
.foui-button.primary { color: var(--primary-text); background: var(--primary-back); }
.foui-button.secondary { color: var(--secondary-text); background: var(--secondary-back); }
.foui-button.accent { color: var(--accent-text); background: var(--accent-back); }
.foui-button.dull { color: var(--dull-text); background: var(--dull-back); }
.foui-button.success { color: var(--success-text); background: var(--success-back); }
.foui-button.warning { color: var(--warning-text); background: var(--warning-back); }
.foui-button.danger { color: var(--danger-text); background: var(--danger-back); }
.foui-button.info { color: var(--info-text); background: var(--info-back); }
.foui-button.transparent { color: var(--info-text); background: transparent; }

/* Bordered */
.foui-button.primary.bordered { color: var(--primary-light); background: transparent; box-shadow: var(--primary-bordered); }
.foui-button.secondary.bordered { color: var(--secondary-light); background: transparent; box-shadow: var(--secondary-bordered); }
.foui-button.accent.bordered { color: var(--accent-light); background: transparent; box-shadow: var(--accent-bordered); }
.foui-button.dull.bordered { color: var(--dull-light); background: transparent; box-shadow: var(--dull-bordered); }
.foui-button.success.bordered { color: var(--success-light); background: transparent; box-shadow: var(--success-bordered); }
.foui-button.warning.bordered { color: var(--warning-light); background: transparent; box-shadow: var(--warning-bordered); }
.foui-button.danger.bordered { color: var(--danger-light); background: transparent; box-shadow: var(--danger-bordered); }
.foui-button.info.bordered { color: var(--info-light); background: transparent; box-shadow: var(--info-bordered); }

/* Coloured Focus */
.foui-button.primary:focus:enabled { filter: var(--button-focus-filter); }
.foui-button.secondary:focus:enabled { filter: var(--button-focus-filter); }
.foui-button.accent:focus:enabled { filter: var(--button-focus-filter); }
.foui-button.dull:focus:enabled { filter: var(--button-focus-filter); }
.foui-button.success:focus:enabled { filter: var(--button-focus-filter); }
.foui-button.warning:focus:enabled { filter: var(--button-focus-filter); }
.foui-button.danger:focus:enabled { filter: var(--button-focus-filter); }
.foui-button.info:focus:enabled { filter: var(--button-focus-filter); }

.foui-button:active:enabled,
.foui-button.primary:active:enabled,
.foui-button.secondary:active:enabled,
.foui-button.accent:active:enabled,
.foui-button.dull:active:enabled,
.foui-button.success:active:enabled,
.foui-button.warning:active:enabled,
.foui-button.danger:active:enabled,
.foui-button.info:active:enabled {
    filter: contrast(115%) brightness(103%);
}

.foui-ctrl.foui-selected .foui-button,
.foui-ctrl.selected .foui-button {
    filter: contrast(115%) brightness(96%);
}
