.foui-text {
    background-color: var(--text-back);
    border: 0;
    display: inline-block;
    color: var(--text-color) !important;
    font: var(--font-gen);
    min-height: 10px;
    padding-left: var(--text-pad-side);
    padding-right: var(--text-pad-side);
    /* outline: 1px solid var(--input-outline); */
    width: 100%;
}
.foui-text.bordered {
    box-shadow: var(--text-border);
    border-radius: var(--text-radius);
}
.foui-text.bordered:enabled:hover {
    box-shadow: var(--hover-border);
}
.foui-design-mode .foui-text.bordered:hover {
    box-shadow: none;
}
.foui-text.bordered:enabled:focus {
    box-shadow: var(--focus-border);
}
.foui-text.bordered.inset-focus:focus:enabled {
    box-shadow: var(--focus-inset-border);
}
.foui-text.bordered.no-focus:enabled:focus {
    box-shadow: none;
}
.foui-design-mode .foui-text.bordered:focus {
    box-shadow: none;
}
.foui-text-nosize {
    resize: none;
}
