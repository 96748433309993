/* @import url("https://fonts.googleapis.com/css?family=Source+Code+Pro|Source+Sans+Pro|Source+Serif+Pro:300,300i,400,400i,700,700i&display=swap"); */
/* https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,300i,400,400i,700,700i&display=swap */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap'); */
/* @import '../../assets/fonts/source.css'; */
@import '../../assets/fonts/inter/inter.css';
/* @import '../../assets/fonts/source-code-pro/source-code-pro.css';
@import '../../assets/fonts/source-sans/source-sans-3.css';
@import '../../assets/fonts/source-serif/source-serif-display.css'; */

/* Variables for shared values */
@import './util/variables-dark.css';
/*@import './util/variables-light.css';*/

/* Defaults */
@import './util/boot.css';

/* Controls */
@import "./control/alert.css";
@import "./control/button.css";
@import "./control/calendar.css";
@import "./control/check.css";
@import "./control/dialog.css";
@import "./control/file-drop.css";
@import "./control/header-line.css";
@import "./control/info-block.css";
@import "./control/label.css";
@import "./control/layout-grid.css";
@import "./control/line-horizontal.css";
@import "./control/list.css";
@import "./control/option.css"; /* radio */
@import "./control/panel.css";
@import "./control/progress-bar.css";
@import "./control/popup.css";
@import "./control/popup-menu.css";
@import "./control/progress.css";
@import "./control/property-form.css";
@import "./control/property-grid.css";
@import "./control/repeater.css";
@import "./control/scrollbar.css";
@import "./control/slider.css";
@import "./control/select.css";
@import "./control/spacer.css";
@import "./control/splitter.css";
@import "./control/switch.css";
@import "./control/table.css";
@import "./control/tabs.css";
@import "./control/text.css";
@import "./control/toolbar.css";
@import "./control/touch-coder.css";
@import "./control/tree.css";
@import "./control/view-designer.css";
@import "./control/view.css";

/* Color and Util last for overwrite preference */
@import './util/util.css';
@import './util/color.css';

/* Custom user/app specific styles */
@import './custom.css';

/* * {
    direction: rtl;
} */
