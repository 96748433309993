html * {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
html, body {
    height: 100%; overflow: hidden;
}
body {
    background-color: var(--back-gen);
    color: var(--text-gen);
    font: var(--font-gen);
    font-variant-numeric: slashed-zero;
    /* font-variant-numeric: normal;
    font-variant-numeric: ordinal;
    font-variant-numeric: slashed-zero;
    font-variant-numeric: lining-nums;
    font-variant-numeric: oldstyle-nums;
    font-variant-numeric: proportional-nums;
    font-variant-numeric: tabular-nums;
    font-variant-numeric: diagonal-fractions;
    font-variant-numeric: stacked-fractions;
    font-variant-numeric: oldstyle-nums stacked-fractions; */
    margin: 0;
    padding: 0;
}
*:focus {
    outline: 0;
}
*.unselectable {
    -khtml-user-select: none;
      -moz-user-select: none;
           user-select: none;
   -webkit-user-select: none;
 -webkit-touch-callout: none;
}
*.unclickable {
    pointer-events: none;
    touch-action: none;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
    font-variant-numeric: slashed-zero;
}
.foui-ctrl {
    display: flex;
    position: relative;
}
.foui-ctrl-design-cover {
    /*background-color: rgba(255, 255, 0, 0.1);*/
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
}
/* Prevent any interaction within a control when in design mode. */
/*.foui-design-mode .foui-ctrl > * {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    pointer-events: none;
    touch-action: none;
}*/
.foui-design-mode .foui-ctrl:hover {
    outline: 1px dashed var(--primary-nofocus);
    outline-offset: -1px;
}
.foui-design-mode .foui-ctrl.foui-design-select {
    outline: 1px solid var(--primary);
    outline-offset: -1px;
}
.foui-design-mode .foui-ctrl.foui-selected:hover {
    outline: 1px dashed var(--primary);
}

/* System wide scrollbars. */
/* Works on Firefox */
* {
    scrollbar-width: var(--scroller-width);
    scrollbar-color: var(--scroller-tag) var(--scroller-track);
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: var(--scroller-width);
    height: var(--scroller-width);
}
*::-webkit-scrollbar-track {
    background: var(--scroller-track);
}
*::-webkit-scrollbar-thumb {
    background-color: var(--scroller-tag);
    border-radius: var(--scroller-width);
    /* border: 1px solid var(--scroller-track); */
}

.foui-app {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
