:root {
    /* Main colours */
    /*--primary-col: 160; Excel
    --primary-col: 212; Mac Blue
    --primary-col: 299; Mac Purple
    --primary-col: 332; Mac Pink
    --primary-col: 358; Mac Peach
    --primary-col: 28; Mac Orange
    --primary-col: 47; Mac Yellow
    --primary-col: 106; Mac Green
    --primary-col: 0; Mac Red*/
    /*--primary-col: 106;*/ /* 160 46 49 147 - #247448 */
    /*--primary2-col: 46;*/ /* 46 49 147  #358559 */
    /*--primary-nofocus-col: 0;*/ /* #358559 */
    /*--secondary-col: 0;*/ /* #222 */
    /*--accent-col: 212;*/ /* 46 49 147 */
    /*--accent2-col: 212;*/ /* 46 49 147 */
    /*--dull-col: 0;*/ /* #444 */
    /*--success-col: 138;*/ /* #108b35 4CAF50 */
    /*--warning-col: 29;*/ /* #b66112 FAA05A */
    /*--danger-col: 0;*/ /* #b62525 F44336 */
    /*--info-col: 207;*/ /* #1869ac 39F */
    /*--control-col: 240;*/
    /*--back-col: 0;*/
    /*--front-col: 0;*/

    --opa-full: 100%;

    /*--primary-h: 165;
    --primary-s: 62%;
    --primary-l: 44%;*/
    /*--primary-h: 106;
    --primary-s: 55%;
    --primary-l: 40%;*/
    --primary-h: 174;
    --primary-s: 64%;
    --primary-l: 40%;
    /*--primary-h: 47;
    --primary-s: 100%;
    --primary-l: 40%;*/
    /*--primary-h: 106;
    --primary-s: 46%;
    --primary-l: 40%;*/
    --primary: hsla(var(--primary-h), var(--primary-s), var(--primary-l), var(--opa-full));
    --primary-light: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 10%), var(--opa-full));
    --primary-heavy: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 10%), var(--opa-full));

    --primary-nofocus-h: 0;
    --primary-nofocus: hsla(var(--primary-nofocus-h), 0%, calc(var(--primary-l) + 15%), var(--opa-full));

    --primary2-h: 165;
    --primary2-s: 91%;
    --primary2-l: 49%;
    --primary2: hsla(var(--primary2-h), var(--primary2-s), var(--primary2-l), var(--opa-full));
    --primary2-light: hsla(var(--primary2-h), var(--primary2-s), calc(var(--primary2-l) + 10%), var(--opa-full));
    --primary2-heavy: hsla(var(--primary2-h), var(--primary2-s), calc(var(--primary2-l) - 10%), var(--opa-full));

    --secondary-h: 0;
    --secondary-s: 0%;
    --secondary-l: 13%;
    --secondary: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), var(--opa-full));
    --secondary-light: hsla(var(--secondary-h), var(--secondary-s), 20%, var(--opa-full));
    --secondary-heavy: hsla(var(--secondary-h), var(--secondary-s), 7%, var(--opa-full));

    --accent-h: 212;
    --accent-s: 66%;
    --accent-l: 72%;
    --accent: hsla(var(--accent-h), var(--accent-s), var(--accent-l), var(--opa-full));
    --accent-light: hsla(var(--accent-h), var(--accent-s), calc(var(--accent-l) + 10%), var(--opa-full));
    --accent-heavy: hsla(var(--accent-h), var(--accent-s), calc(var(--accent-l) - 10%), var(--opa-full));

    --accent2-h: 212;
    --accent2-s: 66%;
    --accent2-l: 72%;
    --accent2: hsla(var(--accent2-h), var(--accent2-s), var(--accent2-l), 15%);
    --accent2-light: hsla(var(--accent2-h), var(--accent2-s), calc(var(--accent2-l) + 10%), 15%);
    --accent2-heavy: hsla(var(--accent2-h), var(--accent2-s), calc(var(--accent2-l) - 10%), 15%);

    --dull-h: 0;
    --dull-s: 0%;
    --dull-l: 27%;
    --dull: hsla(var(--dull-h), var(--dull-s), var(--dull-l), var(--opa-full));
    --dull-feather: hsla(var(--dull-h), var(--dull-s), 52%, var(--opa-full));
    --dull-light: hsla(var(--dull-h), var(--dull-s), 32%, var(--opa-full));
    --dull-heavy: hsla(var(--dull-h), var(--dull-s), 25%, var(--opa-full));

    --success-h: 138;
    --success-s: 79%;
    --success-l: 30%;
    --success: hsla(var(--success-h), var(--success-s), var(--success-l), var(--opa-full));
    --success-light: hsla(var(--success-h), var(--success-s), calc(var(--success-l) + 10%), var(--opa-full));
    --success-heavy: hsla(var(--success-h), var(--success-s), calc(var(--success-l) - 10%), var(--opa-full));

    --warning-h: 29;
    --warning-s: 100%;
    --warning-l: 45%;
    --warning: hsla(var(--warning-h), var(--warning-s), var(--warning-l), var(--opa-full));
    --warning-light: hsla(var(--warning-h), var(--warning-s), calc(var(--warning-l) + 10%), var(--opa-full));
    --warning-heavy: hsla(var(--warning-h), var(--warning-s), 27%, var(--opa-full));

    --danger-h: 0;
    --danger-s: 66%;
    --danger-l: 43%;
    --danger: hsla(var(--danger-h), var(--danger-s), var(--danger-l), var(--opa-full));
    --danger-light: hsla(var(--danger-h), var(--danger-s), 55%, var(--opa-full));
    --danger-heavy: hsla(var(--danger-h), var(--danger-s), 30%, var(--opa-full));

    --info-h: 207;
    --info-s: 76%;
    --info-l: 38%;
    --info: hsla(var(--info-h), var(--info-s), var(--info-l), var(--opa-full));
    --info-light: hsla(var(--info-h), var(--danger-s), 50%, var(--opa-full));
    --info-heavy: hsla(var(--info-h), var(--info-s), calc(var(--info-l) - 10%), var(--opa-full));

    --control-h: 240;
    --control-s: 1%;
    --control-l: 30%;
    --control: hsla(var(--control-h), var(--control-s), var(--control-l), var(--opa-full));
    --control-light: hsla(var(--control-h), var(--control-s), 32%, var(--opa-full));
    --control-heavy: hsla(var(--control-h), var(--control-s), 20%, var(--opa-full));

    --back-h: 0;

    --front-h: 0;

    /* --primary-light: hsla(var(--primary-col), 91%, 59%, 100%);
    --primary: hsla(var(--primary-col), 91%, 49%, 100%); 100%, 46%, 100%
    --primary-dark: hsla(var(--primary-col), 91%, 39%, 100%); */
    /*--primary-light: hsla(var(--primary-col), 55%, 50%, 100%);*/
    /*--primary: hsla(var(--primary-col), 55%, 40%, 100%);*/ /* 100%, 46%, 100% */
    /*--primary-dark: hsla(var(--primary-col), 55%, 30%, 100%);
    --primary2-light: hsla(var(--primary2-col), 91%, 59%, 100%);
    --primary2: hsla(var(--primary2-col), 91%, 49%, 100%);
    --primary2-dark: hsla(var(--primary2-col), 91%, 39%, 100%);
    --primary-nofocus: hsla(var(--primary-nofocus-col), 0%, 55%, 100%);
    --secondary-light: hsla(var(--secondary-col), 0%, 20%, 100%);
    --secondary: hsla(var(--secondary-col), 0%, 13%, 100%);
    --secondary-dark: hsla(var(--secondary-col), 0%, 7%, 100%);*/
    /* --accent-light: hsla(var(--accent-col), 100%, 59%, 100%);
    --accent: hsla(var(--accent-col), 100%, 49%, 100%);
    --accent-dark: hsla(var(--accent-col), 100%, 39%, 100%); */
    /*--accent-light: hsla(var(--accent-col), 66%, 82%, 100%);
    --accent: hsla(var(--accent-col), 66%, 72%, 100%);
    --accent-dark: hsla(var(--accent-col), 66%, 62%, 100%);*/
    /* --accent2-light: hsla(var(--accent2-col), 66%, 59%, 15%);
    --accent2: hsla(var(--accent2-col), 56%, 49%, 15%);
    --accent2-dark: hsla(var(--accent2-col), 46%, 39%, 15%); */
    /*--accent2-light: hsla(var(--accent2-col), 66%, 82%, 15%);
    --accent2: hsla(var(--accent2-col), 66%, 72%, 15%);
    --accent2-dark: hsla(var(--accent2-col), 66%, 62%, 15%);
    --dull-bright: hsla(var(--dull-col), 0%, 52%, 100%);
    --dull-light: hsla(var(--dull-col), 0%, 32%, 100%);
    --dull: hsla(var(--dull-col), 0%, 27%, 100%);
    --dull-dark: hsla(var(--dull-col), 0%, 25%, 100%);
    --success-light: hsla(var(--success-col), 79%, 40%, 100%);
    --success: hsla(var(--success-col), 79%, 30%, 100%);
    --success-dark: hsla(var(--success-col), 79%, 22%, 100%);
    --warning-light: hsla(var(--warning-col), 100%, 55%, 100%);
    --warning: hsla(var(--warning-col), 100%, 45%, 100%);
    --warning-dark: hsla(var(--warning-col), 100%, 27%, 100%);
    --danger-light: hsla(var(--danger-col), 66%, 55%, 100%);
    --danger: hsla(var(--danger-col), 66%, 43%, 100%);
    --danger-dark: hsla(var(--danger-col), 66%, 30%, 100%);
    --info-light: hsla(var(--info-col), 76%, 50%, 100%);
    --info: hsla(var(--info-col), 76%, 38%, 100%);
    --info-dark: hsla(var(--info-col), 76%, 28%, 100%);
    --control-light: hsla(var(--control-col), 1%, 32%, 100%);
    --control: hsla(var(--control-col), 1%, 30%, 100%);
    --control-dark: hsla(var(--control-col), 1%, 20%, 100%);*/

    --primary-text: rgba(255, 255, 255, 1);
    --primary2-text: rgba(255, 255, 255, 0.9);
    --secondary-text: rgba(255, 255, 255, 0.8);
    --accent-text: rgba(255, 255, 255, 1);
    --accent2-text: rgba(255, 255, 255, 1);
    --dull-text: rgba(255, 255, 255, 0.6);
    --success-text: rgba(255, 255, 255, 0.8);
    --warning-text: rgba(255, 255, 255, 0.8);
    --danger-text: rgba(255, 255, 255, 0.8);
    --info-text: rgba(255, 255, 255, 0.8);

    --primary-back: var(--primary);
    --secondary-back: var(--secondary);
    --accent-back: var(--accent);
    --accent2-back: var(--accent2);
    --dull-back: var(--dull);
    --success-back: var(--success);
    --warning-back: var(--warning);
    --danger-back: var(--danger);
    --info-back: var(--info);

    /* Text */
    --text-main: hsl(var(--back-h), 0%, 77%);
    --text-opposite: #222;
    --text-disabled: #AAA;
    --text-dull: #AAA;
    --text-dull-opposite: #777;
    --text-input: #FFF;

    /* SVG custom icon colours */
    --cico1: var(--dull);
    --cico2: var(--text-main);
    --cico3: var(--primary);
    --cico4: var(--secondary);

    /* Backgrounds */
    --theme-input: #404040;
    --theme-window: #424242;
    --back-main: hsl(var(--back-h), 0%, 23%); /* Default background colour */
    --back-opposite: #222; /* Default text colour for default background */
    --back-disabled: #555; /* Default disabled background colour */
    --back-input: #3F3F3F;
    --back-actionbar: #353535;
    --back-titlebar: #303030;
    --back-hover-faint: hsla(var(--primary-col), 53%, 30%, 50%);
    --back-cover: rgba(35, 35, 35, 0.1);
    --back-cover-text: #FFFFFF;
    --back-tinted: #3F3F3F;

    --back-gen: var(--back-main);
    --text-gen: var(--text-main);

    --border: #555;
    --border2: #4C4C4C;
    --border-hover: #666;
    --control-back: var(--control);
    --standard-bordered: 0 0 0 1px var(--border2), 0 0 0 2px var(--border); /* Shadow */
    --primary-bordered: 0 0 0 1px var(--primary), 0 0 0 2px var(--primary-light);
    --secondary-bordered: 0 0 0 1px var(--secondary), 0 0 0 2px var(--secondary-light);
    --accent-bordered: 0 0 0 1px var(--accent), 0 0 0 2px var(--accent-light);
    --dull-bordered: 0 0 0 1px var(--dull), 0 0 0 2px var(--dull-light);
    --success-bordered: 0 0 0 1px var(--success), 0 0 0 2px var(--primary-light);
    --warning-bordered: 0 0 0 1px var(--warning), 0 0 0 2px var(--warning-light);
    --danger-bordered: 0 0 0 1px var(--danger), 0 0 0 2px var(--danger-light);
    --info-bordered: 0 0 0 1px var(--info), 0 0 0 2px var(--info-light);

    /* Font */
    --font-stack: "Inter var", "Source Sans Pro", Roboto, sans-serif;
    --font-size: 9pt;
    --font-system:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    --font-gen: var(--font-size) var(--font-stack);

    --box-shadow-duration: 0.1s;
    --global-tiny-box-shadow:  0 1px 3px rgba(0, 0, 0, 0.5);
    --global-small-box-shadow:  0 2px 8px rgba(0, 0, 0, 0.5);
    --global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    --global-large-box-shadow:  0 14px 25px rgba(0, 0, 0, 0.5);
    --global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.5);
    --shadow-0:  0 0 0 transparent;
    --shadow-1:  0 2px 3px rgba(0, 0, 0, 0.2);
    --shadow-2:  0 3px 5px rgba(0, 0, 0, 0.2);
    --shadow-3:  0 5px 8px rgba(0, 0, 0, 0.2);
    --shadow-4:  0 8px 11px rgba(0, 0, 0, 0.2);
    --shadow-5:  0 11px 16px rgba(0, 0, 0, 0.2);

    /* Layout */
    --grid-box-padding: 0px;

    /* Lines */
    --focus-border: 0 0 0 1px rgba(50, 50, 50, 1),
                    0 0 0 3px var(--primary);
    --focus-inset-border: 0 0 0 1px var(--primary) inset;
    --hover-border: 0 0 0 1px rgba(50, 50, 50, 1),
                    0 0 0 3px var(--border);


    /* Alert */
    --alert-action-height: 44px;
    --alert-action-padding: 8px;
    --alert-back: var(--back-gen);
    --alert-back-actions: var(--back-actionbar);
    --alert-back-title: var(--back-titlebar);
    --alert-border: 1px solid var(--border2);
    --alert-color: var(--text-input);
    --alert-font-size-title: 120%;
    --alert-radius: 4px;
    --alert-title-height: 40px;
    --alert-title-padding: 6px 12px;

    /* Button */
    --button-back: var(--control-back);
    --button-border: 0; /* 1px solid #CACACA; */
    --button-color: var(--text-main);
    --button-radius: 2px;
    --button-padding: 2px 6px;
    --button-focus-filter: brightness(130%);

    /* Check */
    --check-box-size: 17px;
    --check-back: var(--back-input);
    --check-border: 0 0 0 1px var(--border); /* Shadow */
    --check-color: var(--text-input);
    --check-color-check: var(--text-input);
    --check-radius: 0;
    --check-padding: 0 8px;
    --check-mark-left: 3px;
    --check-mark-top: -1px;
    --check-mark-width: 6px;
    --check-mark-height: 10px;
    --check-mark-thick: 2px;

    /* Dialog */
    --dialog-font-size-title: 120%;
    --dialog-title-height: 40px;
    --dialog-title-start-padding: 14px;
    --dialog-title-padding: 8px;

    /* Grid */
    --grid-back: var(--dull);

    /* Header Line */
    --header-line-title-bar-color: var(--dull-feather);

    /* Option / Radio */
    --option-box-size: 16px;
    --option-back: var(--back-input);
    --option-border: 0 0 0 1px var(--border); /* Shadow */
    --option-color: var(--text-input);
    --option-color-check: var(--text-input);
    --option-radius: 50%;
    --option-padding: 0 8px;
    --option-pos: 25%;
    --option-size: 50%;

    /* Progress Bar */
    --progress-bar-radius: 2px;
    --progress-bar-track: var(--dull-light);
    --progress-bar-track-height: 8px;

    /* Panel */
    --panel-back: var(--back-tinted);
    --panel-radius: 2px;

    /* Popup Menu */
    --popup-back: var(--back-input);
    --popup-back-filter: var(--back-input);
    --popup-back-filter-sep: var(--border2);
    --popup-back-header: var(--dull);
    --popup-border: 1px solid var(--border);
    --popup-radius: 2px;
    --popup-color: var(--text-input);
    --popup-color-header: var(--text-dull);
    --popup-color-item: var(--text-input);
    --popup-font-size-header: inherit;
    --popup-pad-header: 6px;
    --popup-pad-row: 4px;
    --popup-pad-side: 5px;
    --popup-border-shadow: var(--global-medium-box-shadow);

    /* Property Grid */
    --property-grid-header-padding: 3px 5px;
    --property-grid-prop-cell: rgba(255, 255, 255, 0.015);
    --property-grid-note-back: var(--border);

    /* Select */
    --select-back: var(--back-input);
    --select-back-filter: var(--back-input);
    --select-back-filter-sep: var(--border);
    --select-border: 0 0 0 1px var(--border); /* Shadow */
    --select-border-hover: 0 0 0 1px var(--border-hover); /* Shadow */
    --select-button-color: transparent;
    --select-ico-color: var(--text-input);
    --select-color: var(--text-input);
    --select-color-item: var(--text-input);
    --select-radius: 1px;
    --select-pad-row: 4px;
    --select-pad-side: 5px;
    --select-list-border-shadow: var(--global-medium-box-shadow);

    /* Scrollbar */
    --scroller-width: 12px;
    --scroller-tag: #777;
    --scroller-track: transparent;

    /* Slider */
    --slider-dot-size: 4px;
    --slider-radius: 10px;
    --slider-tag-size: 10px;
    --slider-tag-size-focus: 16px;
    --slider-tag-radius: 10px;
    --slider-track: var(--dull);
    --slider-track-height: 2px;

    /* Splitter */
    --splitter-back: var(--dull-heavy);
    --splitter-border-size: 0;
    --splitter-padding: 3px;
    --splitter-thickness: 1px;

    /* Switch */
    --switch-tag-back: var(--text-disabled);
    --switch-tag-back-on: var(--back-input);
    --switch-tag-height: 18px;
    --switch-tag-offset: 1px;
    --switch-tag-radius: 10px;
    --switch-tag-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
    --switch-tag-width: 18px;
    --switch-track-back: var(--back-input);
    --switch-track-back-on: var(--text-main);
    --switch-track-border: var(--text-border);
    --switch-track-height: 20px;
    --switch-track-radius: 10px;
    --switch-track-width: 36px;

    /* Text */
    --text-back: var(--back-input);
    --text-border: 0 0 0 1px var(--border); /* Shadow */
    --text-border-hover: 0 0 0 1px var(--border-hover); /* Shadow */
    --text-color: var(--text-input);
    --text-radius: 1px;
    --text-pad-side: 5px;

    /* Table */
    --table-ico-check-off: url('data-url:../../../svg/LineMonoDark/control/table-ico-check-off.svg');
    --table-ico-check-on: url('data-url:../../../svg/LineMonoDark/control/table-ico-check-on.svg');
    --table-ico-edit: url('data-url:../../../svg/LineMonoDark/control/table-ico-edit.svg');
    --table-ico-filter: url('data-url:../../../svg/LineMonoDark/control/table-ico-filter.svg');
    --table-ico-select: url('data-url:../../../svg/LineMonoDark/control/table-ico-select.svg');
    --table-zebra: rgba(255, 255, 255, 0.025);

    /* Toolbar */
    --toolbar-border: inherit; /* Shadow */
    --toolbar-gap: 20px;
    /* --toolbar-separator: 2px dotted hsla(var(--primary), 100%, 100%, 100%); */
    --toolbar-separator: 2px dotted var(--border);
    --toolbar-button-hover: brightness(115%);
    --toolbar-button-active: brightness(110%);
    --toolbar-button-selected: var(--dull-heavy);

    /* Touch Coder */
    --coder-lineno-font-sz: 9pt;
    --coder-lineno-col: var(--text-dull);
    --coder-arrow: polygon(100% 0, 0 100%, 100% 100%);
    /* --coder-arrow: polygon(0 0, 100% 0, 50% 100%); */
    --coder-arrow-small-w: 4px;
    --coder-arrow-small-h: 4px;
    /* --coder-arrow-small-w: 6px;
    --coder-arrow-small-h: 3px; */
    --coder-arrow-col: var(--primary-heavy);
}
