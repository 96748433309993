.foui-progress-bar {
    cursor: default;
    align-items: center;
    display: flex;
    position: relative;
}
.foui-progress-bar-track {
    background: var(--progress-bar-track);
    border-radius: var(--progress-bar-radius);
    height: var(--progress-bar-track-height);
    display: flex;
    align-items: center;
}
.foui-progress-bar-value {
    background: var(--primary);
    border-radius: var(--progress-bar-radius);
    height: var(--progress-bar-track-height);
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
    /* transition: all 0.2s ease; */
}
.foui-indeterminate {
    animation: loadbar 2s linear infinite;
    /* width: 50% !important; */
}
@keyframes loadbar {
    0%,to { left: 0; right: 100% }
    25% { left: 0; right: 50% }
    50% { left: 50%; right: 0 }
    75% { left: 100%; right: 0 }
    100% { left: 100%; right: 0 }
}
