.foui-label {
    cursor: default;
    display: flex;
    align-self: center;
    text-align: start;
    flex-direction: row;
}
.foui-label.vertical {
    flex-direction: column;
}
.foui-label-ico {
    align-self: center;
}
.foui-label-text {
    align-self: center;
}
