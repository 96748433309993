.foui-tree {
    background-color: var(--text-back);
    border: 0;
    display: inline-block;
    color: var(--text-color) !important;
    font: var(--font-gen);
    /* padding-left: var(--text-pad-side);
    padding-right: var(--text-pad-side); */
    overflow: auto;
    width: 100%;
}
.foui-tree.bordered {
    box-shadow: var(--text-border);
    border-radius: var(--text-radius);
}
/* .foui-tree.bordered:hover {
    box-shadow: var(--text-border-hover);
} */
.foui-design-mode .foui-tree.bordered:hover {
    box-shadow: none;
}
.foui-tree.bordered:focus {
    box-shadow: var(--focus-border);
}
.foui-tree.bordered.inset-focus:focus {
    box-shadow: var(--focus-inset-border);
}
.foui-tree.bordered.no-focus:focus {
    box-shadow: none;
}
.foui-design-mode .foui-tree.bordered:focus {
    box-shadow: none;
}
.foui-tree-child-box {
    overflow: hidden;
    background:
        radial-gradient(var(--dull-text) 33%, transparent 33%),
        transparent;
    background-size: 4px 4px;
    background-repeat: repeat-y;
}
.foui-tree-child-box-nolines {
    overflow: hidden;
    background: transparent;
}
.foui-tree.animate .foui-tree-child-box {
    transition: all .2s ease-in-out;
}
.foui-tree-child-box .foui-hidden {
    max-height: 0;
}
.foui-tree-icon {
    /* padding-top: 3px; */
    text-align: center;
    align-self: center;
    flex-basis: 20px;
    min-width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.foui-tree-icon > svg {
    align-self: center;
}
.foui-tree-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 22px;
    padding: 0 0 0 5px;
    white-space: nowrap;
}
.foui-tree .foui-tree-item:hover {
    background-color: hsla(255, 0%, 80%, 53%);
    color: #000;
}
.foui-tree-item.foui-selected {
    background-color: var(--dull-back);
    color: var(--back-main);
}
.foui-tree:focus .foui-tree-item:hover {
    background-color: hsla(var(--primary-h), 53%, 30%, 50%);
    color: var(--primary-text);
}
.foui-tree:focus .foui-tree-item.foui-selected,
.foui-tree:focus .foui-tree-item.foui-selected:hover {
    background-color: var(--primary);
    color: var(--primary-text);
}
/*.foui-tree-item:hover {
    background-color: var(--back-hover-faint);
}*/
/*.foui-tree-item.foui-selected {
    background-color: var(--primary);
    color: var(--primary-text);
}*/
.foui-tree-item.foui-selected .foui-tree-icon {
    filter: invert(100%);
    /*mix-blend-mode: color-dodge;*/
}
.foui-tree:focus .foui-tree-item.foui-selected .foui-tree-icon {
    /*filter: invert(100%);*/
    filter: brightness(0) invert(1);
    /*mix-blend-mode: color-dodge;*/
}
.foui-tree-item.foui-selected .foui-tree-icon > span {
    color: var(--primary-text) !important;
}
.foui-tree-item label {
    flex-grow: 1;
    align-self: center;
    line-height: 20px;
    margin: 0 0 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.foui-tree-item-tag {
    cursor: default;
    border-radius: 2px;
    flex-shrink: 1;
    align-self: center;
    font-size: 70%;
    font-weight: bold;
    line-height: 17px;
    margin: 2px;
    padding: 0 4px;
    white-space: nowrap;
}
.foui-tree-plusmin {
    align-self: center;
    flex-basis: 20px;
    min-width: 20px;
    height: 20px;
    overflow: hidden;
}
.foui-tree-closed {
    --ggs: 0.5;
 }
.foui-tree-closed {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    border: 2px solid;
    transform: scale(var(--ggs,1));
    border-radius: 4px;
    left: -2px;
    top: -2px;
}
.foui-tree-closed::after,
.foui-tree-closed::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 14px;
    height: 2px;
    background: currentColor;
    border-radius: 5px;
    top: 9px;
    left: 3px;
}
.foui-tree-closed::after {
    width: 2px;
    height: 14px;
    top: 3px;
    left: 9px;
}
.foui-tree-open {
    --ggs: 0.5;
}
.foui-tree-open {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    border: 2px solid;
    transform: scale(var(--ggs,1));
    border-radius: 4px;
    left: -2px;
    top: -2px;
}
.foui-tree-open::after,
.foui-tree-open::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 14px;
    height: 2px;
    background: currentColor;
    border-radius: 5px;
    top: 9px;
    left: 3px;
}
.foui-tree-folder {
    fill: var(--text-color);
    /* fill: #d1b410; */
    fill-opacity: 1;
    stroke: none;
    stroke-width: 0;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-opacity: 1;
}
.foui-tree-file {
    fill: var(--text-color);
    /* fill: #d1b410; */
    fill-opacity: 1;
    stroke: none;
    stroke-width: 0;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-opacity: 1;
}
.foui-tree-server {
    fill: var(--text-color);
    /* fill: #d1b410; */
    fill-opacity: 1;
    stroke: none;
    stroke-width: 0;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-opacity: 1;
}
.foui-tree-database {
    fill: var(--text-color);
    /* fill: #d1b410; */
    fill-opacity: 1;
    stroke: none;
    stroke-width: 0;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-opacity: 1;
}
.foui-tree-danger {
    fill: var(--danger);
}
