.foui-scrollbar {
    background: var(--scroller-track);
    cursor: default;
    /* align-items: center; */
    display: flex;
}
.foui-scrollbar-tag {
    background-color: var(--scroller-tag);
    border-radius: var(--scroller-width);
    display: inline-block;
    position: relative;
}
.foui-scrollbar-anim {
    transition: top 0.2s ease, left 0.2s ease;
}
