.foui-switch {
    cursor: default;
    align-items: center;
    display: flex;
}
.foui-switch-noview {
    display: inline-block;
    position: absolute;
    opacity: 0.001;
}
.foui-switch:hover .foui-switch-label-ico {
    box-shadow: var(--hover-border);
}
.foui-switch-track {
    background-color: var(--switch-track-back);
    border-radius: var(--switch-track-radius);
    border: 0;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: var(--switch-track-width);
    height: var(--switch-track-height);
    position: relative;
    box-shadow: var(--switch-track-border);
}
.foui-switch-noview:focus ~ .foui-switch-track {
    box-shadow: var(--focus-border);
}
.foui-switch-tag {
    background-color: var(--switch-tag-back);
    border-radius: var(--switch-tag-radius);
    border: 0;
    display: flex;
    flex-direction: row;
    align-self: center;
    width: var(--switch-tag-width);
    height: var(--switch-tag-height);
    position: absolute;
    margin: 0 1px 0 1px;
    left: 0px;
    transition: var(--switch-tag-transition);
}
.foui-switch-noview:checked ~ .foui-switch-track {
    /*background-color: var(--primary);*/
    background-color: var(--switch-track-back-on);
    /*flex-direction: row-reverse;*/
}
.foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag {
    background-color: var(--switch-tag-back-on);
    left: calc(100% - var(--switch-tag-width) - (2 * var(--switch-tag-offset)));
}

.foui-switch.primary .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--primary-back); }
.foui-switch.secondary .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--secondary-back); }
.foui-switch.accent .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--accent-back); }
.foui-switch.dull .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--dull-back); }
.foui-switch.success .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--success-back); }
.foui-switch.warning .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--warning-back); }
.foui-switch.danger .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--danger-back); }
.foui-switch.info .foui-switch-noview:checked ~ .foui-switch-track { background-color: var(--info-back); }
.foui-switch.transparent .foui-switch-noview:checked ~ .foui-switch-track { background-color: transparent; }

.foui-switch.primary .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--primary-text); }
.foui-switch.secondary .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--secondary-text); }
.foui-switch.accent .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--accent-text); }
.foui-switch.dull .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--dull-text); }
.foui-switch.success .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--success-text); }
.foui-switch.warning .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--warning-text); }
.foui-switch.danger .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--danger-text); }
.foui-switch.info .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--info-text); }
.foui-switch.transparent .foui-switch-noview:checked ~ .foui-switch-track > .foui-switch-tag { background-color: var(--info-text); }
