.foui-option {
    cursor: default;
    align-items: center;
    display: flex;
}
.foui-option-noview {
    display: inline-block;
    position: absolute;
    opacity: 0.001;
}
.foui-option:hover .foui-option-label-ico {
    box-shadow: var(--hover-border);
}
.foui-option-noview:focus ~ .foui-option-label-ico {
    box-shadow: var(--focus-border);
}
.foui-option-label {
    color: var(--option-color);
    cursor: default;
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: var(--check-padding);
}
.foui-option-label-ico {
    background-color: var(--text-back);
    box-shadow: var(--option-border);
    border-radius: var(--option-radius);
    display: flex;
    flex-direction: row;
    align-self: center;
    height: var(--option-box-size);
    width: var(--option-box-size);
}
/* .foui-ico-option::after {
    background-color: var(--option-color-check);
    border-radius: 50%;
    content: "";
    display: block;
    box-sizing: border-box;
    position: relative;
    opacity: 0;
    inset-inline-start: var(--option-pos);
    inset-block-start: var(--option-pos);
    width: var(--option-size);
    height: var(--option-size);
}
.foui-option-noview:checked ~ .foui-ico-option::after {
    opacity: 1;
}
 */
/* This is the class name inside the SVG */
 .foui-option-ico {
    /* fill: none;
    stroke-width: 2;
    stroke: var(--text-input); */
    fill: var(--text-input);
    stroke: none;
    transition: all 0.2s ease;
    transform-origin: center;
    /* transform: scale(0.7); */
    opacity: 0;
}
.foui-option-noview:checked ~ label svg g .foui-option-ico {
    /* transform: scale(1); */
    opacity: 1;
}
