.foui-info-block {
    cursor: default;
    /* align-items: center; */
    display: flex;
}
.foui-info-block-hover:hover {
    background-color: var(--dull-back);
    filter: brightness(1.1);
}
.foui-info-block-side {
    display: flex;
    align-items: flex-start;
}
.foui-info-block-ico {
    width: 100%;
}
.foui-info-block-ico svg {
    width: 100%;
    height: 100%;
}
.foui-info-block-main {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* margin-inline-start: 10px; */
}
.foui-info-block-title {
    font-weight: 500;
    margin: 2px 0 2px 0;
}
.foui-info-block-text {
    margin: 2px 0;
    /* opacity: 0.8; */
}
.foui-info-block-tags {
    display: flex;
}
.foui-info-block-tag-item {
    background-color: var(--dull-back);
    border-radius: 2px;
    color: var(--dull-text);
    display: inline-flex;
    margin: 6px 6px 0 0;
    padding: 3px 8px;
}
/* Normal */
.foui-info-block.foui-primary-text .foui-cico-normal {
    fill: var(--primary-text) !important;
}
.foui-info-block.foui-secondary-text .foui-cico-normal {
    fill: var(--secondary-text) !important;
}
.foui-info-block.foui-accent-text .foui-cico-normal {
    fill: var(--accent-text) !important;
}
.foui-info-block.foui-accent2-text .foui-cico-normal {
    fill: var(--accent2-text) !important;
}
.foui-info-block.foui-dull-text .foui-cico-normal {
    fill: var(--dull-text) !important;
}
.foui-info-block.foui-success-text .foui-cico-normal {
    fill: var(--success-text) !important;
}
.foui-info-block.foui-warning-text .foui-cico-normal {
    fill: var(--warning-text) !important;
}
.foui-info-block.foui-danger-text .foui-cico-normal {
    fill: var(--danger-text) !important;
}
.foui-info-block.foui-info-text .foui-cico-normal {
    fill: var(--info-text) !important;
}
/* Dull */
.foui-info-block.foui-primary-text .foui-cico-dull {
    fill: var(--primary-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-secondary-text .foui-cico-dull {
    fill: var(--secondary-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-accent-text .foui-cico-dull {
    fill: var(--accent-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-accent2-text .foui-cico-dull {
    fill: var(--accent2-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-dull-text .foui-cico-dull {
    fill: var(--dull-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-success-text .foui-cico-dull {
    fill: var(--success-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-warning-text .foui-cico-dull {
    fill: var(--warning-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-danger-text .foui-cico-dull {
    fill: var(--danger-text) !important;
    opacity: 0.6;
}
.foui-info-block.foui-info-text .foui-cico-dull {
    fill: var(--info-text) !important;
    opacity: 0.6;
}
