.foui-line-horz {
    display: flex;
}
.foui-line-horz-bar {
    background-color: var(--border);
    display: flex;
    align-self: center;
    position: relative;
    width: 100%;
}
