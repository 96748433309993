.foui-progress {
    width: 100%;
    overflow-x: hidden;
    z-index: 10;
}
.foui-progress-line {
    position: absolute;
    opacity: 0.4;
    background: var(--primary);
    width: 150%;
    height: 4px;
}
.foui-progress-solidline {
    position: absolute;
    background: var(--primary);
    width: 100%;
    height: 4px;
    transition: width 0.25s ease;
}
.foui-progress-subline {
    position: absolute;
    background: var(--primary);
    height: 4px;
}
.foui-progress-inc {
    animation: anim-increase 2s infinite;
}
.foui-progress-dec {
    animation: anim-decrease 2s 0.5s infinite;
}
@keyframes anim-increase {
    from { left: -5%; width: 5%; }
    to { left: 130%; width: 100%; }
}
@keyframes anim-decrease {
    from { left: -80%; width: 80%; }
    to { left: 110%; width: 10%; }
}
.foui-progress-over-top,
.foui-progress-over-bottom {
    height: 4px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 100%;
}
.foui-progress-over-bottom {
    bottom: 0px;
}
/* Circle */
/* .foui-spinner {
    transform: scale(var(--ggs,1))
} */
.foui-spinner,
.foui-spinner::after,
.foui-spinner::before {
    box-sizing: border-box;
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
}
.foui-spinner::after,
.foui-spinner::before {
    content: "";
    position: absolute;
    border-radius: 100px;
}
.foui-spinner::before {
    animation: spinner 1s cubic-bezier(.6,0,.4,1) infinite;
    border: 3px solid transparent;
    border-top-color: hsl(var(--primary-h), 100%, 85%);
    z-index: 1;
}
.foui-spinner::after {
    border: 3px solid;
    border-color: var(--primary);
    /* opacity: .2; */
}
.foui-spinner.blender {
    filter: drop-shadow(0px 0px 1px var(--primary));
}
@keyframes spinner {
    0% { transform: rotate(0deg) }
    to { transform: rotate(359deg) }
}
.foui-spinner-back {
    background: repeating-linear-gradient(
        -45deg,
        hsla(var(--primary-h), 91%, 49%, 0.8),
        hsla(var(--primary-h), 91%, 49%, 0.8) 10px,
        hsla(var(--primary-h), 91%, 49%, 0.5) 10px,
        hsla(var(--primary-h), 91%, 49%, 0.5) 20px
    );
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}
