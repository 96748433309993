.foui-toolbar {
    background: var(--button-back);
    box-shadow: var(--toolbar-border);
    border: 0;
    cursor: default;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: stretch;
    /*height: 100%;*/
    position: relative;
}
.foui-toolbar-progress {
    height: 4px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 100%;
}
.foui-toolbar-button {
    background-color: transparent;
    border: 0;
    color: var(--text-main);
    cursor: default;
    /* height: 100%; */
    /* width: 40px; */
    padding: 0 6px;
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
}
.foui-toolbar-button.foui-selected {
    background-color: var(--toolbar-button-selected);
}
/* .foui-toolbar-button:not([disabled]):hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.foui-toolbar-button:not([disabled]):active {
    background-color: rgba(0, 0, 0, 0.3);
} */
.foui-toolbar-button:hover:enabled {
    background: var(--button-back);
    filter: var(--toolbar-button-hover);
}
.foui-toolbar-button:focus:enabled {
    /* box-shadow: var(--focus-border); */
    background: var(--button-back);
    filter: var(--toolbar-button-hover);
}
.foui-toolbar-button:disabled {
    opacity: 0.6;
}
.foui-toolbar-button:active:enabled {
    /* background: var(--button-back); */
    filter: var(--toolbar-button-active);
}
/* .foui-toolbar-button.foui-selected:focus {
    background-color: #424242;
} */
/* .foui-toolbar-button:focus {
    background-color: #DDD;
    border-color: #4FB3E7;
    outline: 2px solid #4FB3E7;
} */
.dark .foui-toolbar-button:not([disabled]):hover {
    background-color: var(--toolbar-button-hover);
}
.dark .foui-toolbar-button:not([disabled]):active {
    background-color: var(--toolbar-button-active);
}
/* .foui-toolbar-button .foui-toolbar-icon {
    display: flex;
} */
/* .foui-toolbar-icon {
    margin: auto;
}
.foui-toolbar-icon.small {
    font-size: 11pt;
    font-weight: bold;
} */
.foui-toolbar-separator {
    border-left: var(--toolbar-separator);
    height: 60%;
    margin: auto 3px;
    position: relative;
    /* opacity: 0.3; */
}
.dark .foui-toolbar-separator {
    border-color: #FFFFFF;
}
.foui-toolbar-spacer {
    flex-grow: 1;
}
.foui-toolbar-gap {
    flex-basis: var(--toolbar-gap);
}
.foui-toolbar-label {
    align-self: center;
    color: var(--text-main);
}
.foui-toolbar-text {
    margin: 3px 0;
    width: auto;
}
.foui-toolbar-select {
    margin: 3px 0;
    width: auto;
    height: auto;
}





/* .foui-toolbar {
    background-color: var(--select-back);
    border: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    position: relative;
    width: 100%;
}
.foui-toolbar.bordered {
    box-shadow: var(--select-border);
    border-radius: var(--select-radius);
}
.foui-toolbar.bordered:hover {
    box-shadow: var(--hover-border);
}
.foui-toolbar-label {
    border: 0;
    border-radius: var(--select-radius);
    color: var(--select-color) !important;
    font: var(--font-gen);
    padding-left: var(--select-pad-side);
    padding-right: var(--select-pad-side);
}
.foui-toolbar-filter-box {
    display: flex;
    width: 100%;
}
.foui-toolbar-input {
    display: flex;
    flex-grow: 1;
}
.foui-toolbar-filter-ico-box {
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 30px;
    inset-inline-end: 0;
    inset-block-start: 8px;
}
.foui-toolbar-filter-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(0.75);
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 100%;
    margin-top: -3px
}
.foui-toolbar-filter-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    height: 8px;
    background: currentColor;
    transform: rotate(-45deg);
    top: 10px;
    left: 12px
}
.foui-toolbar-filter-sep {
    background-color: var(--select-back-filter-sep);
    min-height: 1px;
    width: 100%;
}
.foui-toolbar-button {
    background: var(--select-button-color);
    color: var(--button-color);
    cursor: default;
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30px;
    inset-inline-end: 0;
    position: absolute;
}
.foui-toolbar-button-ico {
    display: inline-block;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    border-top: 2px solid var(--select-ico-color);
    border-right: 2px solid var(--select-ico-color);
    transform: rotate(135deg);
}
.foui-toolbar-pop {
    background-color: var(--select-back);
    box-shadow: var(--select-list-border-shadow);
    border-radius: var(--select-radius);
    border-top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    min-height: 25px;
    width: 100%;
    z-index: 99;
}
.foui-toolbar-list {
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
}
.foui-toolbar-item {
    color: var(--select-color-item);
    cursor: default;
    min-height: 16px;
    padding: var(--select-pad-row) var(--select-pad-side);
    width: 100%;
}
.foui-toolbar-item:hover {
    background-color: hsla(var(--primary-h), 53%, 30%, 50%);
}
.foui-toolbar-item-selected {
    background-color: var(--primary);
    color: var(--primary-text);
} */
