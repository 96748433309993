.foui-touch-coder {
    border: 0;
    display: flex;
    flex-direction: row;
    /* font: 11pt "Source Code Pro"; */
    width: 100%;
    height: 100%;
}
/* .foui-touch-coder-code {
    cursor: text;
    display: inline-block;
    color: transparent !important;
    padding: 0;
    width: 100%;
    height: 100%;
} */
/* .foui-touch-text {
    color: #000000;
    margin-bottom: 5px;
}
.foui-touch-key,
.foui-touch-value {
    background-color: #DDE4EC;
    border: 1px solid #DDE4EC;
    border-radius: 15px;
    color: #7F98A5; 86A0AD
    display: inline-block;
    padding: 3px 10px;
}
.foui-touch-value {
    color: #374044;
    font-weight: bold;
}
.foui-touch-key:hover,
.foui-touch-value:hover {
    border: 1px solid #BDC4CC;
} */

.foui-touch-comment {
    /* background-color: #EAFFED; */
    /* background-color: #008200;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    color: #008200;
    /* color: rgba(255, 255, 255, 0.8); */
    font-size: small;
    /* padding: 1px 5px; */
}
/* .foui-touch-text {
    color: #000000;
    margin-bottom: 5px;
}
.foui-touch-action {
    background-color: #DDE4EC;
    border: 1px solid #DDE4EC;
    border-radius: 5px;
    color: #6F8895;
    display: inline-block;
    padding: 0 5px;
}
.foui-touch-action:hover {
    border: 1px solid #BDC4CC;
}
.foui-touch-key,
.foui-touch-value,
.foui-touch-more {
    display: inline-block;
    padding: 2px 0;
}
.foui-touch-value {
    color: #374044;
    font-weight: bold;
}
.foui-touch-more {
    background-color: #DDE4EC;
    color: #374044;
    font-weight: bold;
    margin-inline-start: 5px;
}
.foui-touch-more:hover {
    filter: brightness(105%);
}
.foui-touch-more:active {
    filter: brightness(95%);
}

.foui-touch-pop {
    background: #DDE4EC;
    color: #374044;
    display: inline-block;
    position: absolute;
    z-index: 99;
} */

.foui-touch-coder-calls {
    /* background-color: var(--text-back); */
    background-color: transparent;
    flex-basis: 300px;
    height: 100%;
    overflow-y: auto;
}
.foui-touch-coder-call-item {
    padding: 5px 10px;
}
.foui-touch-coder-call-item:hover {
    background-color: var(--back-hover-faint);
}
.foui-touch-coder-call-item:active {
    background-color: var(--primary);
    color: var(--primary-text);
}
.foui-touch-coder-call-name {
    font-weight: bold;
}
.foui-touch-coder-call-desc {
    font-size: smaller;
}
.foui-touch-coder-code {
    /* background-color: blueviolet; */
    background-color: transparent;
    /* border-top: 1px solid var(--border); */
    border-left: 1px solid var(--border);
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    position: relative;
}
.foui-touch-coder-code-linebox {
    border-top: 1px solid var(--border);
    display: flex;
}
.foui-touch-coder-code-wrap {
    flex-grow: 1;
}
.foui-touch-coder-code-line {
    display: flex;
    padding: 6px;
}
.foui-touch-coder-code-tab {
    width: 30px;
}
.foui-touch-coder-code-o {
    border: 1px solid transparent;
    border-radius: 2px;
    margin-inline-end: 5px;
    padding: 2px 5px;
    position: relative;
}
.foui-touch-coder-code-o.lineno {
    background-color: var(--dull);
    border: 0;
    border-right: 1px solid var(--border);
    color: var(--coder-lineno-col);
    font-size: var(--coder-lineno-font-sz);
    font-variant-numeric: slashed-zero tabular-nums;
    padding: 9px 4px 0;
    text-align: end;
    width: 40px;
    min-width: 40px;
}
.foui-touch-coder-code-o.call {
    background-color: #565656;
    border-color: #565656;
    cursor: pointer;
    min-width: 50px;
}
.foui-touch-coder-code-o.call::after {
    content: "";
    background-color: var(--coder-arrow-col);
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: var(--coder-arrow-small-w);
    height: var(--coder-arrow-small-h);
    clip-path: var(--coder-arrow);
}
/* .foui-touch-coder-code-o.call.SET,
.foui-touch-coder-code-o.call.IF,
.foui-touch-coder-code-o.call.ELSE {
    background-color: teal;
    border-color: teal;
}
.foui-touch-coder-code-o.call.ALERT {
    background-color: olive;
    border-color: olive;
}
.foui-touch-coder-code-o.call.LOG {
    background-color: sienna;
    border-color: sienna;
} */
.foui-touch-coder-code-o.call.SET {
    background-color: rgb(0, 87, 121);
    /* background-color: transparent; */
    border-color: rgb(0, 132, 184);
    /* color: rgb(0, 183, 255); */
}
.foui-touch-coder-code-o.call.IF,
.foui-touch-coder-code-o.call.ELSE {
    background-color: rgb(104, 0, 130);
    /* background-color: transparent; */
    border-color: rgb(136, 0, 170);
    /* color: rgb(224, 98, 255); */
}
.foui-touch-coder-code-o.call.ALERT {
    background-color: #5A5A00;
    /* background-color: transparent; */
    border-color: olive;
    /* color: #BDBD00; */
}
.foui-touch-coder-code-o.call.LOG {
    background-color: #7d3715;
    /* background-color: transparent; */
    border-color: sienna;
    /* color: #D6784B; */
}
.foui-touch-coder-code-o.variable {
    background-color: var(--text-back);
    border-color: var(--border);
    color: var(--text-input);
}
.foui-touch-coder-code-o.variable:focus {
    /* background-color: var(--primary); */
    border-color: var(--primary);
}
.foui-touch-coder-code-o.operand {
    background-color: #565656;
    border-color: #565656;
    cursor: pointer;
    font-weight: bold;
}
.foui-touch-coder-code-o.operand::after {
    content: "";
    background-color: var(--coder-arrow-col);
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: var(--coder-arrow-small-w);
    height: var(--coder-arrow-small-h);
    clip-path: var(--coder-arrow);
}
.foui-touch-coder-code-o.text {
    background-color: transparent;
    border-color: var(--dull);
    opacity: 0.7;
    padding: 2px 3px;
}
.foui-touch-coder-code-o.value-text {
    background-color: transparent;
    border-color: var(--primary);
}
.foui-touch-coder-code-input {
    background-color: transparent;
    border: 0;
    color: var(--text-input);
    width: 100%;
}
.foui-touch-coder-code-input::placeholder {
    font-style: italic;
}
.foui-touch-coder-splitter {
    position: absolute;
}

.foui-touch-coder-popup {
    position: absolute;
}
.foui-touch-coder-popup-list {
    background-color: var(--popup-back);
    box-shadow: var(--popup-border-shadow);
    border: var(--popup-border);
    border-radius: var(--select-radius);
    max-height: 400px;
    overflow-y: auto;
    position: relative;
}
.foui-touch-coder-popup-arrow {
    background-color: var(--border);
    box-shadow: var(--select-list-border-shadow);
    position: relative;
    margin-left: 5px;
    width: 10px;
    height: 6px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}
/* .foui-touch-coder-popup::before {
    content: "";
    background-color: var(--border);
    position: absolute;
    top: 0;
    left: 5px;
    width: 10px;
    height: 6px;
    margin-top: -6px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
} */
.foui-touch-coder-popup-item {
    color: var(--popup-color-item);
    cursor: default;
    min-height: 16px;
    padding: var(--select-pad-row) var(--select-pad-side);
    width: 100%;
}
.foui-touch-coder-popup-item:hover {
    background-color: hsla(var(--primary-h), 53%, 30%, 50%);
}
.foui-touch-coder-popup-item:active {
    background-color: var(--primary);
    color: var(--primary-text);
}
