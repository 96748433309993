.foui-popup-menu {
    background-color: var(--popup-back);
    border-radius: var(--popup-radius);
    box-shadow: var(--popup-border-shadow);
    border: var(--popup-border);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
    opacity: 0;
    position: absolute;
    max-height: 600px;
    min-width: 30px;
    width: auto;
    overflow: hidden;
    z-index: 10;
}
.foui-popup-menu-filter-box {
    display: flex;
    width: 100%;
}
.foui-popup-menu-input {
    display: flex;
    flex-grow: 1;
}
.foui-popup-menu-filter-ico-box {
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 30px;
    inset-inline-end: 0;
    inset-block-start: 8px;
}
.foui-popup-header {
    background: var(--popup-back-header);
    border-top: 1px solid var(--popup-back-filter-sep);
    color: var(--popup-color-header);
    cursor: default;
    font-size: var(--popup-font-size-header);
    width: 100%;
    padding: var(--popup-pad-header);
}
.foui-popup-header:first-child {
    border-top: 0;
}
.foui-popup-separator {
    background-color: var(--popup-back-filter-sep);
    min-height: 1px;
    width: 100%;
    /* margin: 5px 0; */
    /* position: relative; */
}
.foui-popup-menu-filter-sep {
    background-color: var(--popup-back-filter-sep);
    min-height: 1px;
    width: 100%;
}
.foui-popup-menu-list {
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
}
.foui-popup-item {
    color: var(--popup-color-item);
    cursor: default;
    display: flex;
    min-height: 16px;
    padding: var(--popup-pad-row) var(--popup-pad-side);
    width: 100%;
}
.foui-popup-item:hover {
    background-color: var(--back-hover-faint);
    /* background-color: var(--primary);
    color: var(--primary-text); */
}
.foui-popup-item-selected {
    background-color: var(--primary);
    color: var(--primary-text);
}
.foui-popup-item-label {
    align-self: center;
    width: 100%;
}
.foui-popup-item-icon {
    display: flex;
    justify-content: center;
    padding-right: 6px;
    height: 18px;
    width: 36px;
    /*box-shadow: var(--shadow-2);*/
}
