.foui-file-drop {
    border: 3px dashed var(--dull);
    border-radius: 5px;
    cursor: default;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all linear 0.2s;
}
.foui-file-drop-over {
    border: 3px dashed var(--primary);
}
.foui-file-drop-noview {
    display: none;
}
.foui-file-drop-ico {
    margin-bottom: 10px;
}
.foui-file-drop-ico > svg {
    width: 40px;
    height: 40px;
}
.foui-file-drop-label {
    /* font-size: larger; */
}
.foui-file-drop-or {
    margin: 10px 0;
    opacity: 0.6;
}
.foui-file-drop-browse {
    padding: 8px 20px;
}
.foui-file-drop-accept {
    margin-top: 5px;
    opacity: 0.6;
}
.foui-file-drop-limit {
    margin-top: 5px;
    opacity: 0.6;
}
.foui-file-drop-name {
    color: var(--primary);
    font-size: 14pt;
    margin-bottom: 10px;
}
.foui-file-drop-error {
    background-color: var(--danger-light);
    border-radius: 2px;
    color: var(--warning-text);
    margin-top: 10px;
    padding: 2px 6px;
}
.foui-file-drop-progress {
    background-color: var(--dull-light);
    /* border-radius: 5px; */
    display: inline-block;
    position: relative;
    margin-top: 10px;
    max-width: 200px;
    height: 5px;
    width: 100%;
}
.foui-file-drop-progress div {
    height: 100%;
}

/* @keyframes loadbaralt {
    0%,to { left: 0; right: 80% }
    25%,75% { left: 0; right: 0 }
    50% { left: 80%; right: 0 }
}
.gg-loadbar-alt,
.gg-loadbar-alt::before,
.gg-loadbar-alt::after {
    display: block;
    box-sizing: border-box;
    height: 4px;
    border-radius: 4px
}
.gg-loadbar-alt {
    position: relative;
    transform: scale(var(--ggs,1));
    width: 18px
}
.gg-loadbar-alt::after,
.gg-loadbar-alt::before {
    background: currentColor;
    content: "";
    position: absolute
}
.gg-loadbar-alt::before {
    animation: loadbaralt 2s cubic-bezier(0,0,.58,1) infinite
}
.gg-loadbar-alt::after {
    width: 18px;
    opacity: .3
} */
