.foui-layout-grid {
    display: grid;
    position: relative;
    /* flex-grow: 1; */
}
/* .foui-layout-grid::after {
    content: "Select";
    background-color: var(--primary-nofocus);
    color: var(--primary-text);
    font-size: 7.5pt;
    padding: 1px 3px;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
}
.foui-layout-grid.foui-selected::after {
    background-color: var(--primary);
    color: var(--primary-text);
} */
/*.foui-designer .foui-layout-grid:hover {
    outline: 1px dashed var(--primary-nofocus);
    outline-offset: -1px;
}
.foui-designer .foui-layout-grid:hover::after {
    opacity: 1;
}*/
.foui-layout-grid-cell {
    /* background-color: #CCEEFF; */
    /* background-image: linear-gradient(135deg, #ffffff 16.67%, #e6e6e6 16.67%, #e6e6e6 50%, #ffffff 50%, #ffffff 66.67%, #e6e6e6 66.67%, #e6e6e6 100%); */
    /* background-image: linear-gradient(135deg, #f0f0f0 12.50%, #ffffff 12.50%, #ffffff 50%, #f0f0f0 50%, #f0f0f0 62.50%, #ffffff 62.50%, #ffffff 100%);
    background-size: 4.24px 4.24px;
    border: 1px dashed #EAEAEA; */
    /*display: grid;*/
    display: flex;
    position: relative;
    min-width: 100%;
    min-height: 100%;
    padding: var(--grid-box-padding); /* Borders are shadows therefore needs padding because of overflow. */
}
.show-cells .foui-layout-grid-cell {
    background-color: rgba(255, 255, 255, 0.025);
    /*box-shadow: 0 0 0 1px var(--border2) inset;;*/
}
.foui-designer .foui-layout-grid-cell.foui-empty-cell {
    min-height: 20px;
}
/* .foui-layout-grid-box-holder {
    display: grid;
} */
/* .foui-designing .foui-layout-grid-box {
    border: 1px dashed #CCC;
} */

/* .foui-layout-grid-box-sizer {
    cursor: pointer;
    display: inline-flex;
    opacity: 0;
    bottom: 0;
    right: 0;
    height: 10px;
    width: 10px;
    transition: opacity 0.2s linear;
    position: absolute;
    z-index: 10;
}
.foui-layout-grid-box-sizer svg g g path {
    fill: white !important;
}
.foui-layout-grid-box:hover .foui-layout-grid-box-sizer {
    opacity: 1;
}
.foui-layout-grid-box-sizer:hover svg g g path {
    fill: var(--primary) !important;
}
.foui-layout-grid-box-sizer-sz {
    background-color: var(--back-cover);
    color: var(--back-cover-text);
    font-size: smaller;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
} */
.foui-dragover {
    /*background-color: rgba(255, 255, 255, 0.3);*/
    outline: 1px dashed var(--primary);
}
/* Resizer controls */
.foui-grid-sz {
    position: absolute;
    z-index: 10;
}
.foui-grid-sz-col-line {
    background-color: var(--primary);
    /* border-left: 1px dashed var(--primary); */
    /* background-image: linear-gradient(var(--primary) 33%, rgba(255,255,255,0) 0%); */

    background-image: linear-gradient(var(--primary) 100%, rgba(255,255,255,0) 0%);
    background-position: center;
    background-size: 2px 100%;
    background-repeat: repeat-y;
    background-color: hsla(var(--primary-h), 53%, 40%, 30%);
    width: 100%;
    height: 100%;
    /* opacity: 0.6; */
}
.foui-grid-sz-col-tag {
    background-color: var(--primary);
    border-radius: 2px;
    color: var(--primary-text);
    cursor: ew-resize;
    font-size: 8pt;
    padding: 2px 4px 1px;
    position: absolute;
    text-align: center!important;
    white-space: nowrap;
    top: 100%;
    transform: rotate(90deg);
}
.foui-grid-sz-col-tag::after {
    border-right: 8px solid var(--primary);
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    top: 49%;
    content: " ";
    height: 0;
    left: -7px;
    position: absolute;
    width: 0;
    transform: translateY(-50%);
}

.foui-grid-sz-row-line {
    /* background-color: var(--primary); */
    /* border-top: 1px dashed var(--primary); */
    /* background-image: linear-gradient(to right, var(--primary) 33%, rgba(255,255,255,0) 0%); */

    background-image: linear-gradient(to right, var(--primary) 100%, rgba(255,255,255,0) 0%);
    background-position: center;
    background-size: 100% 2px;
    background-repeat: repeat-x;
    background-color: hsla(var(--primary-h), 53%, 40%, 30%);
    width: 100%;
    height: 100%;
    /* opacity: 0.6; */
}
.foui-grid-sz-row-tag {
    background-color: var(--primary);
    border-radius: 2px;
    color: var(--primary-text);
    cursor: ns-resize;
    font-size: 8pt;
    padding: 2px 4px 1px;
    position: absolute;
    text-align: center!important;
    white-space: nowrap;
    left: 100%;
    margin-left: 0;
}
.foui-grid-sz-row-tag::after {
    border-right: 8px solid var(--primary);
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    top: 49%;
    content: " ";
    height: 0;
    left: -7px;
    position: absolute;
    width: 0;
    transform: translateY(-50%);
}

