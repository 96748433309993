.foui-view-designer {
    /* background-color: var(--button-back); */
    /* box-shadow: var(--global-small-box-shadow); */
    /* border-radius: 5px; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    /* width: fit-content;
    height: fit-content;
    min-width: 600px;
    max-width: 100%; */
}
.foui-view-designer-header {
    display: grid;
}
.foui-view-designer-body {
    /* border-top: 1px solid var(--border2); */
    height: 100%;
    /* padding: 2px 4px 4px 4px; */
    padding: 5px 0 0 0;
}
.foui-view-designer-view {
    background-color: var(--back-main);
    /* border-top: 1px solid var(--border2); */
    height: 100%;
    overflow: auto;
    padding: 20px;
}
.foui-preview-box {
    background-color: var(--back-gen);
    border: 2px solid var(--border2);
    display: inline-block;
    /*box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;*/
    box-shadow: 3px 3px 12px #333333,
        -3px -3px 12px #333333;
}
.foui-view-designer-selected {
    outline: 1px dashed var(--primary);
}
.foui-designer .foui-ctrl > * {
    pointer-events: none;
    user-select: none;
    -webkit-touch-callout: none;
}
.foui-view-designer-sizer {
    /*background-color: var(--primary);*/
    border: 1px dashed var(--primary);
    display: inline-block;
    position: absolute;
}
.foui-view-designer-sizer.for-row {
    cursor: ns-resize;
}
.foui-view-designer-sizer.for-col {
    cursor: ew-resize;
}
.foui-view-designer-tag.for-row {
    background-color: var(--primary);
    position: absolute;
    right: -29px;
    width: 20px;
    height: 15px;
    border-radius: 0 3px 3px 0;
    text-align: center;
}
.foui-view-designer-tag.for-row:before {
    content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-right: 5px solid var(--primary);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    margin-top: 2px;
    position: absolute;
    left: -6px;
    filter: brightness(80%);
}
.foui-view-designer-tag.for-col {
    background-color: var(--primary);
    position: absolute;
    bottom: -29px;
    width: 15px;
    height: 20px;
    border-radius: 0 0 3px 3px;
    text-align: center;
    padding-top: 2px;
}
.foui-view-designer-tag.for-col:before {
    content: "";
    display: inline-block !important;
    width: 0;
    height: 0;
    border-bottom: 5px solid var(--primary);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    /*margin-top: -3px;*/
    margin-left: -5px;
    position: absolute;
    left: 50%;
    top: -6px;
    filter: brightness(80%);

}
.sz-info-val {
    position: absolute;
    font-size: smaller;
    line-height: 15px;
}
.foui-view-designer-tag.for-col .sz-info-val {
    top: 26px;
    left: -50%;
}
.foui-view-designer-tag.for-row .sz-info-val {
    top: 0;
    left: 26px;
}
/* Background patterns */
.tartan {
    /*background-clip: content-box;*/
    background-color: grey;
    background-image: repeating-linear-gradient(transparent, transparent 30px, rgba(0, 0, 0, 0.4) 30px, rgba(0, 0, 0, 0.4) 32px, transparent 32px, transparent 37px, rgba(0, 0, 0, 0.4) 37px, rgba(0, 0, 0, 0.4) 39px, transparent 39px, transparent 69px, rgba(0, 0, 0, 0.4) 69px, rgba(0, 0, 0, 0.4) 99px, rgba(255, 255, 255, 0.2) 99px, rgba(255, 255, 255, 0.2) 101px, rgba(0, 0, 0, 0.4) 101px, rgba(0, 0, 0, 0.4) 106px, rgba(255, 255, 255, 0.2) 106px, rgba(255, 255, 255, 0.2) 108px, rgba(0, 0, 0, 0.4) 108px, rgba(0, 0, 0, 0.4) 138px, transparent 138px),
        repeating-linear-gradient(270deg, transparent, transparent 30px, rgba(0, 0, 0, 0.4) 30px, rgba(0, 0, 0, 0.4) 32px, transparent 32px, transparent 37px, rgba(0, 0, 0, 0.4) 37px, rgba(0, 0, 0, 0.4) 39px, transparent 39px, transparent 69px, rgba(0, 0, 0, 0.4) 69px, rgba(0, 0, 0, 0.4) 99px, rgba(255, 255, 255, 0.2) 99px, rgba(255, 255, 255, 0.2) 101px, rgba(0, 0, 0, 0.4) 101px, rgba(0, 0, 0, 0.4) 106px, rgba(255, 255, 255, 0.2) 106px, rgba(255, 255, 255, 0.2) 108px, rgba(0, 0, 0, 0.4) 108px, rgba(0, 0, 0, 0.4) 138px, transparent 138px),
        repeating-linear-gradient(125deg, transparent, transparent 2px, rgba(0, 0, 0, 0.2) 2px, rgba(0, 0, 0, 0.2) 3px, transparent 3px, transparent 5px, rgba(0, 0, 0, 0.2) 5px);
}
.carbon {
    background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px, linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px, linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px, linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px, linear-gradient(90deg, #1b1b1b 10px, transparent 10px), linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
    background-color: #131313;
    background-size: 20px 20px;
    /*background-clip: content-box;*/
}
.net {
    background: radial-gradient(black 40%, transparent 0) 0 0, radial-gradient(black 40%, transparent 0) 20px 20px, radial-gradient(rgba(255, 255, 255, 0.1) 40%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 40%, transparent 20%) 20px 21px;
    background-color: #282828;
    background-size: 40px 40px;
    /*background-clip: content-box;*/
}
.diagonal-lines-light {
    background-image: repeating-linear-gradient(-45deg, #535353, #535353 0.25px, transparent 0, transparent 50%);
    background-size: 12px 12px
}
.diagonal-lines-regular {
    background-image: repeating-linear-gradient(-45deg, #535353 0, #535353 1px, transparent 0, transparent 50%);
    background-size: 12px 12px
}
.diagonal-lines-bold {
    background-image: repeating-linear-gradient(-45deg, #535353 0, #535353 2px, transparent 0, transparent 50%);
    background-size: 12px 12px
}
.diagonal-lines-display {
    background-image: repeating-linear-gradient(-45deg, #535353 0, #535353 8px, transparent 0, transparent 50%);
    background-size: 24px 24px
}
.bgpattern-tartan {
    background-color: #a02f2b;
    background-image: repeating-linear-gradient(transparent,transparent 50px,rgba(0,0,0,.4)50px,rgba(0,0,0,.4)53px,transparent 53px,transparent 63px,rgba(0,0,0,.4)63px,rgba(0,0,0,.4)66px,transparent 66px,transparent 116px,rgba(0,0,0,.5)116px,rgba(0,0,0,.5)166px,rgba(255,255,255,.2)166px,rgba(255,255,255,.2)169px,rgba(0,0,0,.5)169px,rgba(0,0,0,.5)179px,rgba(255,255,255,.2)179px,rgba(255,255,255,.2)182px,rgba(0,0,0,.5)182px,rgba(0,0,0,.5)232px,transparent 232px),
        repeating-linear-gradient(270deg,transparent,transparent 50px,rgba(0,0,0,.4)50px,rgba(0,0,0,.4)53px,transparent 53px,transparent 63px,rgba(0,0,0,.4)63px,rgba(0,0,0,.4)66px,transparent 66px,transparent 116px,rgba(0,0,0,.5)116px,rgba(0,0,0,.5)166px,rgba(255,255,255,.2)166px,rgba(255,255,255,.2)169px,rgba(0,0,0,.5)169px,rgba(0,0,0,.5)179px,rgba(255,255,255,.2)179px,rgba(255,255,255,.2)182px,rgba(0,0,0,.5)182px,rgba(0,0,0,.5)232px,transparent 232px),
        repeating-linear-gradient(125deg,transparent,transparent 2px,rgba(0,0,0,.2)2px,rgba(0,0,0,.2)3px,transparent 3px,transparent 5px,rgba(0,0,0,.2)5px);
}
.bgpattern-carbon-fibre {
    background-color:#282828;
    background-image: radial-gradient(black 15%,transparent 16%),
        radial-gradient(black 15%,transparent 16%),
        radial-gradient(rgba(255,255,255,.1)15%,transparent 20%),
        radial-gradient(rgba(255,255,255,.1)15%,transparent 20%);
    background-size:16px 16px;
    background-position:0 0,8px 8px,0 1px,8px 9px;
}
.bgpattern-argyle {
    background-color:#6d695c;
    background-image: repeating-linear-gradient(120deg,rgba(255,255,255,.1),rgba(255,255,255,.1)1px,transparent 1px,transparent 60px),
        repeating-linear-gradient(60deg,rgba(255,255,255,.1),rgba(255,255,255,.1)1px,transparent 1px,transparent 60px),
        linear-gradient(60deg,rgba(0,0,0,.1)25%,transparent 25%,transparent 75%,rgba(0,0,0,.1)75%,rgba(0,0,0,.1)),
        linear-gradient(120deg,rgba(0,0,0,.1)25%,transparent 25%,transparent 75%,rgba(0,0,0,.1)75%,rgba(0,0,0,.1));
    background-size:70px 120px;
}
.bgpattern-japanese-cube{background-color:#556;background-image:-webkit-linear-gradient(60deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),-webkit-linear-gradient(300deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),-webkit-linear-gradient(60deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),-webkit-linear-gradient(300deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),-webkit-linear-gradient(30deg,#99a 25%,transparent 25.5%,transparent 75%,#99a 75%,#99a),-webkit-linear-gradient(30deg,#99a 25%,transparent 25.5%,transparent 75%,#99a 75%,#99a);background-image:linear-gradient(30deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),linear-gradient(150deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),linear-gradient(30deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),linear-gradient(150deg,#445 12%,transparent 12.5%,transparent 87%,#445 87.5%,#445),linear-gradient(60deg,#99a 25%,transparent 25.5%,transparent 75%,#99a 75%,#99a),linear-gradient(60deg,#99a 25%,transparent 25.5%,transparent 75%,#99a 75%,#99a);background-size:80px 140px;background-position:0 0,0 0,40px 70px,40px 70px,0 0,40px 70px}
.bgpattern-lined-paper{background-color:#fff;background-image:-webkit-linear-gradient(0deg,transparent 79px,#abced4 79px,#abced4 81px,transparent 81px),-webkit-linear-gradient(#eee .1em,transparent .1em);background-image:linear-gradient(90deg,transparent 79px,#abced4 79px,#abced4 81px,transparent 81px),linear-gradient(#eee .1em,transparent .1em);background-size:100% 1.2em}
.bgpattern-blueprint-grid{background-color:#269;background-image:-webkit-linear-gradient(white 2px,transparent 2px),-webkit-linear-gradient(0deg,#fff 2px,transparent 2px),-webkit-linear-gradient(rgba(255,255,255,.3)1px,transparent 1px),-webkit-linear-gradient(0deg,rgba(255,255,255,.3)1px,transparent 1px);background-image:linear-gradient(white 2px,transparent 2px),linear-gradient(90deg,#fff 2px,transparent 2px),linear-gradient(rgba(255,255,255,.3)1px,transparent 1px),linear-gradient(90deg,rgba(255,255,255,.3)1px,transparent 1px);background-size:100px 100px,100px 100px,20px 20px,20px 20px;background-position:-2px -2px,-2px -2px,-1px -1px,-1px -1px}
.bgpattern-diagonal-stripes{background-color:grey;background-image:-webkit-repeating-linear-gradient(45deg,transparent,transparent 35px,rgba(255,255,255,.5)35px,rgba(255,255,255,.5)70px);background-image:repeating-linear-gradient(45deg,transparent,transparent 35px,rgba(255,255,255,.5)35px,rgba(255,255,255,.5)70px)}
.bgpattern-brady-bunch{background-color:#c8d3a7;background-image:-webkit-radial-gradient(closest-side,transparent 0,transparent 75%,#b6cc66 76%,#b6cc66 85%,#edffdb 86%,#edffdb 94%,#fff 95%,#fff 103%,#d9e6a7 104%,#d9e6a7 112%,#798b3c 113%,#798b3c 121%,#fff 122%,#fff 130%,#e0ead7 131%,#e0ead7 140%),-webkit-radial-gradient(closest-side,transparent 0,transparent 75%,#b6cc66 76%,#b6cc66 85%,#edffdb 86%,#edffdb 94%,#fff 95%,#fff 103%,#d9e6a7 104%,#d9e6a7 112%,#798b3c 113%,#798b3c 121%,#fff 122%,#fff 130%,#e0ead7 131%,#e0ead7 140%);background-image:radial-gradient(closest-side,transparent 0,transparent 75%,#b6cc66 76%,#b6cc66 85%,#edffdb 86%,#edffdb 94%,#fff 95%,#fff 103%,#d9e6a7 104%,#d9e6a7 112%,#798b3c 113%,#798b3c 121%,#fff 122%,#fff 130%,#e0ead7 131%,#e0ead7 140%),radial-gradient(closest-side,transparent 0,transparent 75%,#b6cc66 76%,#b6cc66 85%,#edffdb 86%,#edffdb 94%,#fff 95%,#fff 103%,#d9e6a7 104%,#d9e6a7 112%,#798b3c 113%,#798b3c 121%,#fff 122%,#fff 130%,#e0ead7 131%,#e0ead7 140%);background-size:110px 110px;background-position:0 0,55px 55px}
.bgpattern-seigaiha{
    background-color: silver;
    background-image: radial-gradient(circle at 100% 150%,#333333 24%,#393939 25%,#393939 28%,#333333 29%,#333333 36%,#393939 36%,#393939 40%,transparent 40%,transparent),
        radial-gradient(circle at 0 150%,#333333 24%,#393939 25%,#393939 28%,#333333 29%,#333333 36%,#393939 36%,#393939 40%,transparent 40%,transparent),
        radial-gradient(circle at 50% 100%,#393939 10%,#333333 11%,#333333 23%,#393939 24%,#393939 30%,#333333 31%,#333333 43%,#393939 44%,#393939 50%,#333333 51%,#333333 63%,#393939 64%,#393939 71%,transparent 71%,transparent),
        radial-gradient(circle at 100% 50%,#393939 5%,#333333 6%,#333333 15%,#393939 16%,#393939 20%,#333333 21%,#333333 30%,#393939 31%,#393939 35%,#333333 36%,#333333 45%,#393939 46%,#393939 49%,transparent 50%,transparent),
        radial-gradient(circle at 0 50%,#393939 5%,#333333 6%,#333333 15%,#393939 16%,#393939 20%,#333333 21%,#333333 30%,#393939 31%,#393939 35%,#333333 36%,#333333 45%,#393939 46%,#393939 49%,transparent 50%,transparent);
    background-size:100px 50px;
}
